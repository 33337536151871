import Kerupuk6 from '../Image/kerupuk-kapal/jengkol-sisir-hitam.jpeg';
import Kerupuk7 from '../Image/kerupuk-kapal/mawar-kuning.jpg';
import Kerupuk8 from '../Image/kerupuk-kapal/kecipir-warna.jpg';
import Kerupuk9 from '../Image/kerupuk-kapal/kelabang-kuning.jpg';
import Kerupuk3 from '../Image/kerupuk-kapal/kerupuk-jengkol-bulat-hitam.jpg';
import Kerupuk4 from '../Image/kerupuk-napoleon/tempe-bulat.jpg';
import Kerupuk10 from '../Image/kerupuk-kapal/kerupuk-udang-mede.jpg';
import Kerupuk11 from '../Image/kerupuk-kapal/kerupuk-udang-salju.jpg';
import Kerupuk12 from '../Image/kerupuk-kapal/mawar-kuning.jpg';
import Kerupuk13 from '../Image/kerupuk-kapal/mawar-putih.webp';
import Kerupuk14 from '../Image/kerupuk-kapal/sisir-warna.jpg';
import Kerupuk15 from '../Image/kerupuk-kapal/tersanjung-warna.jpg';
import Kerupuk16 from '../Image/kerupuk-kapal/udang-mas.jpg';
import Kerupuk17 from '../Image/kerupuk-kapal/udang-mini-orange.jpeg';
import Kerupuk18 from '../Image/kerupuk-kapal/udang-mini-putih.jpeg';
import Kerupuk1 from '../Image/kerupuk-kapal/udang-putih-kapal.jpeg';

import Kerupuk19 from '../Image/kerupuk-shinjuku/kasandra-warna.jpg';
import Kerupuk20 from '../Image/kerupuk-shinjuku/kepang-mas.jpg';
import Kerupuk21 from '../Image/kerupuk-shinjuku/kepang-warna.jpg';
import Kerupuk22 from '../Image/kerupuk-shinjuku/mawar-pink.jpg';
import Kerupuk2 from '../Image/kerupuk-shinjuku/mawar-putih.jpg';
import Kerupuk23 from '../Image/kerupuk-shinjuku/mawar-warna.jpg';
import Kerupuk24 from '../Image/kerupuk-shinjuku/pentil(manggar).jpg';
import Kerupuk25 from '../Image/kerupuk-shinjuku/rantai-jumbo-orange.jpg';
import Kerupuk26 from '../Image/kerupuk-shinjuku/rantai-jumbo-pink.jpg';
import Kerupuk27 from '../Image/kerupuk-shinjuku/rantai-jumbo-putih.jpg';
import Kerupuk28 from '../Image/kerupuk-shinjuku/rantai-lemon-warna.jpg';
import Kerupuk29 from '../Image/kerupuk-shinjuku/rantai-sedang-putih.jpg';
import kerupuk30 from '../Image/kerupuk-napoleon/kelabang-cream.jpg';
import kerupuk31 from '../Image/kerupuk-napoleon/makaroni-ayam.jpg';
import kerupuk32 from '../Image/kerupuk-napoleon/makaroni-bantet.jpg';
import kerupuk33 from '../Image/kerupuk-napoleon/tempe-bulat.jpg';
import kerupuk34 from '../Image/kerupuk-napoleon/tiga-roda-cream.jpg';
import Kerupuk35 from '../Image/kerupuk-bunga-merah/ceriping-pedas.jpg';
import Kerupuk36 from '../Image/kerupuk-bunga-merah/ikan-putih.jpg';
import Kerupuk37 from '../Image/kerupuk-bunga-merah/udang-kuning.jpg';
import Kerupuk5 from '../Image/kerupuk-bunga-merah/udang-salju-pink.jpg';
import Kerupuk38 from '../Image/kerupuk-kambing-lima/mangkok-udang.jpg';
import Kerupuk39 from '../Image/kerupuk-kambing-lima/mawar-kuning.jpg';
import Kerupuk40 from '../Image/kerupuk-kambing-lima/mawar-pink.jpg';
import Kerupuk41 from '../Image/kerupuk-kambing-lima/merah.png';
import Kerupuk42 from '../Image/kerupuk-kambing-lima/merah-putih.png';
import Kerupuk43 from '../Image/kerupuk-kambing-lima/T-Extra.jpg';
import Kerupuk46 from '../Image/depositphotos_63433499-stock-photo-white-wall-or-empty-paper.png';
import Kerupuk47 from '../Image/jumbo-warna.jpg'
import Kerupuk48 from '../Image/tersanjung-warna.jpeg'
import Kerupuk49 from '../Image/sedang-rasa.jpg'
import Kerupuk50 from '../Image/kerupuk-kapal/udang-putih-kapal.jpeg'
import Kerupuk51 from '../Image/makaroni-bantet.jpeg'
import Kerupuk52 from '../Image/mawar-kuning.jpeg'
import Kerupuk53 from '../Image/mawar-seblak.jpeg'
import Kerupuk54 from '../Image/udang-layar-putih.jpeg'
import Kerupuk55 from '../Image/udang-layar-orange.jpeg'
import Kerupuk56 from '../Image/kerupuk-kambing-lima/mawar-kuning.jpg'


// Asam Jawa
import Karung1 from '../Image/karung1.jpeg';
import Karung2 from '../Image/karung2.jpeg';
import Karung3 from '../Image/karung3.jpeg';

import Kemasan1 from '../Image/DSCF0073.jpg'
import Kemasan2 from '../Image/kemasan2.jpeg'
import Kemasan3 from '../Image/DSCF0024.jpg'
import Kemasan4 from '../Image/DSCF0062.jpg'
import Kemasan5 from '../Image/DSCF0084.jpg'
import Kemasan6 from '../Image/DSCF0047.jpg'
import Kemasan7 from '../Image/DSCF0114.jpg'
import Kemasan8 from '../Image/DSCF0052.jpg'
import Kemasan9 from '../Image/kemasan9.jpeg'


//kulit tahu
import KulitTahu1 from '../Image/kulit-tahu/1.jpeg';
import KulitTahu2 from '../Image/kulit-tahu/2.jpeg';


// tepung putri
import tepung1 from '../Image/tepung-putri-1.jpeg'
import tepung2 from '../Image/tepung-putri-2.jpg';


//lainnya
import PotatoSemprong from '../Image/Potato semprong orange.jpeg';
import StickMini from '../Image/stik mini.jpeg';
import PotatoKuning from '../Image/Semprong kuning.jpeg';
import PotatoKorea from '../Image/Potato korea.jpeg';
import Kurma from '../Image/Kurma.jpeg';
import Lala from '../Image/Lala.jpeg';
import PolengMerah from '../Image/Poleng merah.jpeg';
import PolengOrange from '../Image/poleng orange.jpeg'
import PolengHijau from '../Image/poleng hijau.jpeg';

const  dataProduk = [
    {
        id: {
            "nama": "Potato Semprong Kuning",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoKuning,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Yellow Potato Semprong",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoKuning,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بطاطا سيمبرونج صفراء",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoKuning,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "黄森普隆马铃薯",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoKuning,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Stick Mini",
            // "kategori": "biji",
            "brand": "lain",
            "image": StickMini,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Stick Mini",
            // "kategori": "biji",
            "brand": "lain",
            "image": StickMini,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "عصا ميني",
            // "kategori": "biji",
            "brand": "lain",
            "image": StickMini,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "迷你棒",
            // "kategori": "biji",
            "brand": "lain",
            "image": StickMini,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Potato Semprong Orange",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoSemprong,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Potato Semprong Orange",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoSemprong,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بطاطا سيمبرونج برتقال",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoSemprong,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "马铃薯森普橙",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoSemprong,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Potato Korea",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoKorea,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Potato Korea",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoKorea,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "كوريا البطاطس",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoKorea,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "韩国马铃薯",
            // "kategori": "biji",
            "brand": "lain",
            "image": PotatoKorea,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Kurma",
            // "kategori": "biji",
            "brand": "lain",
            "image": Kurma,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Dates",
            // "kategori": "biji",
            "brand": "lain",
            "image": Kurma,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بلح",
            // "kategori": "biji",
            "brand": "lain",
            "image": Kurma,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "枣子",
            // "kategori": "biji",
            "brand": "lain",
            "image": Kurma,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Lala Merah & Kuning",
            // "kategori": "biji",
            "brand": "lain",
            "image": Lala,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Lala Red & Yellow",
            // "kategori": "biji",
            "brand": "lain",
            "image": Lala,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "لالا الأحمر والأصفر",
            // "kategori": "biji",
            "brand": "lain",
            "image": Lala,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "拉拉红&黄",
            // "kategori": "biji",
            "brand": "lain",
            "image": Lala,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Poleng Merah",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengMerah,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Red Poleng",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengMerah,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "ريد بولينج",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengMerah,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "红波冷",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengMerah,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Poleng Orange",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengOrange,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Orange Poleng",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengOrange,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بولينج أورانج",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengOrange,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "波冷橙",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengOrange,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Poleng Hijau",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengHijau,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Poleng Green",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengHijau,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بولينج جرين",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengHijau,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "波冷绿",
            // "kategori": "biji",
            "brand": "lain",
            "image": PolengHijau,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Biji 150gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan9,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Seed Tamarind 150gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan9,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بذور التمر الهندي 150 جرام",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan9,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "罗望子籽 150gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan9,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Biji 250gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan8,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Seed Tamarind 250gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan8,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بذور التمر الهندي 250 جرام",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan8,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "罗望子籽 250gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan8,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Daging 250gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan1,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Meat Sour 250gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan1,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "لحم حامض 250 جرام",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan1,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "酸肉250克",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan1,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Daging 500gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan2,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Meat Sour 500gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan2,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "لحم حامض 500 جرام",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan2,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "酸肉500克",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan2,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Daging 100gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan3,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Meat Sour 100gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan3,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "لحم حامض 100 جرام",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan3,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "酸肉100克",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan3,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Daging 1kg",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan4,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Meat Sour 1kg",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan4,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "لحم حامض 1 كيلو",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan4,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "酸肉1kg",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan4,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Biji 500gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan5,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Seed Tamarind 500gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan5,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بذور التمر الهندي 500 جرام",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan5,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "罗望子籽 500gr",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan5,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Biji 1kg",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan6,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Seed Tamarind 1kg",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan6,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "تمر هندي بذرة 1 كيلو",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan6,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "罗望子籽 1kg",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan6,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Daging 10kg",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan7,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Meat Sour 10kg",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan7,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "لحم حامض 10 كيلو",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan7,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "酸肉10公斤",
            // "kategori": "biji",
            "brand": "kemasan",
            "image": Kemasan7,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Jawa Biji",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung1,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Tamarind Seeds",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung1,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بذور التمر الهندي",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung1,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "罗望子种子",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung1,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Jawa Biji",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung2,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Tamarind Seeds",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung2,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بذور التمر الهندي",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung2,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "罗望子种子",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung2,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Asam Jawa Biji",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung3,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Tamarind Seeds",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung3,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "بذور التمر الهندي",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung3,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "罗望子种子",
            // "kategori": "biji",
            "brand": "karung",
            "image": Karung3,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Tepung Putri",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kulit Kembang Tahu kami adalah pilihan sempurna untuk Anda yang mencari bahan makanan serbaguna, sehat, dan lezat. Dengan tekstur lembut dan kenyal, produk ini cocok untuk beragam kreasi masakan seperti lumpia, sup, atau bahkan hidangan panggang. Rasanya yang gurih alami akan menggugah selera, menjadikan setiap hidangan terasa istimewa.
            \u00a0\u00a0\u00a0\u00a0 Tidak hanya enak, Kulit Kembang Tahu kami juga kaya akan protein nabati dan rendah kalori, cocok bagi Anda yang ingin menjaga pola makan sehat. Praktis, bergizi, dan bebas bahan pengawet, produk ini siap melengkapi dapur Anda dan menghadirkan pengalaman memasak yang menyenangkan! Mari ciptakan sajian kreatif bersama Kulit Kembang Tahu yang berkualitas!
            `,
            // "kategori": "biji",
            "brand": "tepung-putri",
            "image": tepung1,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Princess Flour",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Our Tofu Flower Skin is the perfect choice for those of you who are looking for a versatile, healthy and delicious food ingredient. With a soft and chewy texture, this product is suitable for various culinary creations such as spring rolls, soups, or even grilled dishes. Its natural savory taste will arouse the taste buds, making every dish taste special.
            \u00a0\u00a0\u00a0\u00a0 Not only delicious, our Tofu Flower Skin is also rich in vegetable protein and low in calories, suitable for those of you who want to maintain a healthy diet. Practical, nutritious and free of preservatives, this product is ready to complement your kitchen and provide a pleasant cooking experience! Let's create creative dishes with quality Tofu Flower Skin!
            `,
            // "kategori": "biji",
            "brand": "tepung-putri",
            "image": tepung1,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "طحين الاميرة",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 إن قشر زهرة التوفو الخاص بنا هو الخيار الأمثل لأولئك منكم الذين يبحثون عن مكونات غذائية متعددة الاستخدامات وصحية ولذيذة. بفضل ملمسه الناعم والمطاطي، يعد هذا المنتج مناسبًا لمختلف إبداعات الطهي مثل السبرينغ رولز أو الحساء أو حتى الأطباق المشوية. سوف يثير مذاقه الطبيعي اللذيذ براعم التذوق، مما يجعل مذاق كل طبق مميزًا.
            \u00a0\u00a0\u00a0\u00a0 ليس فقط لذيذًا، بل إن قشر زهرة التوفو الخاص بنا غني أيضًا بالبروتين النباتي ومنخفض السعرات الحرارية، وهو مناسب لأولئك منكم الذين يرغبون في الحفاظ على نظام غذائي صحي. هذا المنتج عملي ومغذي وخالي من المواد الحافظة، وهو جاهز لاستكمال مطبخك وتوفير تجربة طهي ممتعة! دعونا نصنع أطباقًا مبتكرة باستخدام جلد زهرة التوفو عالي الجودة!
            `,
            // "kategori": "biji",
            "brand": "tepung-putri",
            "image": tepung1,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "公主面粉",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 对于那些正在寻找多功能、健康和美味食品原料的人来说，我们的豆腐花皮是您的完美选择。该产品质地柔软耐嚼，适合各种烹饪创作，如春卷、汤，甚至烧烤菜肴。其天然的鲜味会刺激味蕾，让每一道菜都别有风味。
            \u00a0\u00a0\u00a0\u00a0 我们的豆腐花皮不仅美味，还富含植物蛋白，热量低，适合想要保持健康饮食的您。该产品实用、营养且不含防腐剂，可补充您的厨房并提供愉快的烹饪体验！让我们用优质豆腐花皮创造创意菜肴吧！
            `,
            // "kategori": "biji",
            "brand": "tepung-putri",
            "image": tepung1,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Tepung Putri",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kulit Kembang Tahu kami adalah pilihan sempurna untuk Anda yang mencari bahan makanan serbaguna, sehat, dan lezat. Dengan tekstur lembut dan kenyal, produk ini cocok untuk beragam kreasi masakan seperti lumpia, sup, atau bahkan hidangan panggang. Rasanya yang gurih alami akan menggugah selera, menjadikan setiap hidangan terasa istimewa.
            \u00a0\u00a0\u00a0\u00a0 Tidak hanya enak, Kulit Kembang Tahu kami juga kaya akan protein nabati dan rendah kalori, cocok bagi Anda yang ingin menjaga pola makan sehat. Praktis, bergizi, dan bebas bahan pengawet, produk ini siap melengkapi dapur Anda dan menghadirkan pengalaman memasak yang menyenangkan! Mari ciptakan sajian kreatif bersama Kulit Kembang Tahu yang berkualitas!
            `,
            // "kategori": "biji",
            "brand": "tepung-putri",
            "image": tepung2,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Princess Flour",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Our Tofu Flower Skin is the perfect choice for those of you who are looking for a versatile, healthy and delicious food ingredient. With a soft and chewy texture, this product is suitable for various culinary creations such as spring rolls, soups, or even grilled dishes. Its natural savory taste will arouse the taste buds, making every dish taste special.
            \u00a0\u00a0\u00a0\u00a0 Not only delicious, our Tofu Flower Skin is also rich in vegetable protein and low in calories, suitable for those of you who want to maintain a healthy diet. Practical, nutritious and free of preservatives, this product is ready to complement your kitchen and provide a pleasant cooking experience! Let's create creative dishes with quality Tofu Flower Skin!
            `,
            // "kategori": "biji",
            "brand": "tepung-putri",
            "image": tepung2,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "طحين الاميرة",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 إن قشر زهرة التوفو الخاص بنا هو الخيار الأمثل لأولئك منكم الذين يبحثون عن مكونات غذائية متعددة الاستخدامات وصحية ولذيذة. بفضل ملمسه الناعم والمطاطي، يعد هذا المنتج مناسبًا لمختلف إبداعات الطهي مثل السبرينغ رولز أو الحساء أو حتى الأطباق المشوية. سوف يثير مذاقه الطبيعي اللذيذ براعم التذوق، مما يجعل مذاق كل طبق مميزًا.
            \u00a0\u00a0\u00a0\u00a0 ليس فقط لذيذًا، بل إن قشر زهرة التوفو الخاص بنا غني أيضًا بالبروتين النباتي ومنخفض السعرات الحرارية، وهو مناسب لأولئك منكم الذين يرغبون في الحفاظ على نظام غذائي صحي. هذا المنتج عملي ومغذي وخالي من المواد الحافظة، وهو جاهز لاستكمال مطبخك وتوفير تجربة طهي ممتعة! دعونا نصنع أطباقًا مبتكرة باستخدام جلد زهرة التوفو عالي الجودة!
            `,
            // "kategori": "biji",
            "brand": "tepung-putri",
            "image": tepung2,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "公主面粉",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 对于那些正在寻找多功能、健康和美味食品原料的人来说，我们的豆腐花皮是您的完美选择。该产品质地柔软耐嚼，适合各种烹饪创作，如春卷、汤，甚至烧烤菜肴。其天然的鲜味会刺激味蕾，让每一道菜都别有风味。
            \u00a0\u00a0\u00a0\u00a0 我们的豆腐花皮不仅美味，还富含植物蛋白，热量低，适合想要保持健康饮食的您。该产品实用、营养且不含防腐剂，可补充您的厨房并提供愉快的烹饪体验！让我们用优质豆腐花皮创造创意菜肴吧！
            `,
            // "kategori": "biji",
            "brand": "tepung-putri",
            "image": tepung2,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Kulit Kembang Tahu",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kulit Kembang Tahu kami adalah pilihan sempurna untuk Anda yang mencari bahan makanan serbaguna, sehat, dan lezat. Dengan tekstur lembut dan kenyal, produk ini cocok untuk beragam kreasi masakan seperti lumpia, sup, atau bahkan hidangan panggang. Rasanya yang gurih alami akan menggugah selera, menjadikan setiap hidangan terasa istimewa.
            \u00a0\u00a0\u00a0\u00a0 Tidak hanya enak, Kulit Kembang Tahu kami juga kaya akan protein nabati dan rendah kalori, cocok bagi Anda yang ingin menjaga pola makan sehat. Praktis, bergizi, dan bebas bahan pengawet, produk ini siap melengkapi dapur Anda dan menghadirkan pengalaman memasak yang menyenangkan! Mari ciptakan sajian kreatif bersama Kulit Kembang Tahu yang berkualitas!
            `,
            // "kategori": "biji",
            "brand": "kulit-tahu",
            "image": KulitTahu1,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Tofu Flower Skin",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Our Tofu Flower Skin is the perfect choice for those of you who are looking for a versatile, healthy and delicious food ingredient. With a soft and chewy texture, this product is suitable for various culinary creations such as spring rolls, soups, or even grilled dishes. Its natural savory taste will arouse the taste buds, making every dish taste special.
            \u00a0\u00a0\u00a0\u00a0 Not only delicious, our Tofu Flower Skin is also rich in vegetable protein and low in calories, suitable for those of you who want to maintain a healthy diet. Practical, nutritious and free of preservatives, this product is ready to complement your kitchen and provide a pleasant cooking experience! Let's create creative dishes with quality Tofu Flower Skin!
            `,
            // "kategori": "biji",
            "brand": "kulit-tahu",
            "image": KulitTahu1,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "جلد زهرة التوفو",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 إن قشر زهرة التوفو الخاص بنا هو الخيار الأمثل لأولئك منكم الذين يبحثون عن مكونات غذائية متعددة الاستخدامات وصحية ولذيذة. بفضل ملمسه الناعم والمطاطي، يعد هذا المنتج مناسبًا لمختلف إبداعات الطهي مثل السبرينغ رولز أو الحساء أو حتى الأطباق المشوية. سوف يثير مذاقه الطبيعي اللذيذ براعم التذوق، مما يجعل مذاق كل طبق مميزًا.
            \u00a0\u00a0\u00a0\u00a0 ليس فقط لذيذًا، بل إن قشر زهرة التوفو الخاص بنا غني أيضًا بالبروتين النباتي ومنخفض السعرات الحرارية، وهو مناسب لأولئك منكم الذين يرغبون في الحفاظ على نظام غذائي صحي. هذا المنتج عملي ومغذي وخالي من المواد الحافظة، وهو جاهز لاستكمال مطبخك وتوفير تجربة طهي ممتعة! دعونا نصنع أطباقًا مبتكرة باستخدام جلد زهرة التوفو عالي الجودة!
            `,
            // "kategori": "biji",
            "brand": "kulit-tahu",
            "image": KulitTahu1,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "豆腐花皮",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 对于那些正在寻找多功能、健康和美味食品原料的人来说，我们的豆腐花皮是您的完美选择。该产品质地柔软耐嚼，适合各种烹饪创作，如春卷、汤，甚至烧烤菜肴。其天然的鲜味会刺激味蕾，让每一道菜都别有风味。
            \u00a0\u00a0\u00a0\u00a0 我们的豆腐花皮不仅美味，还富含植物蛋白，热量低，适合想要保持健康饮食的您。该产品实用、营养且不含防腐剂，可补充您的厨房并提供愉快的烹饪体验！让我们用优质豆腐花皮创造创意菜肴吧！
            `,
            // "kategori": "biji",
            "brand": "kulit-tahu",
            "image": KulitTahu1,
            // "ukuran": 1000,
        },
    },
    {
        id: {
            "nama": "Kulit Kembang Tahu",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kulit Kembang Tahu kami adalah pilihan sempurna untuk Anda yang mencari bahan makanan serbaguna, sehat, dan lezat. Dengan tekstur lembut dan kenyal, produk ini cocok untuk beragam kreasi masakan seperti lumpia, sup, atau bahkan hidangan panggang. Rasanya yang gurih alami akan menggugah selera, menjadikan setiap hidangan terasa istimewa.
            \u00a0\u00a0\u00a0\u00a0 Tidak hanya enak, Kulit Kembang Tahu kami juga kaya akan protein nabati dan rendah kalori, cocok bagi Anda yang ingin menjaga pola makan sehat. Praktis, bergizi, dan bebas bahan pengawet, produk ini siap melengkapi dapur Anda dan menghadirkan pengalaman memasak yang menyenangkan! Mari ciptakan sajian kreatif bersama Kulit Kembang Tahu yang berkualitas!
            `,
            // "kategori": "biji",
            "brand": "kulit-tahu",
            "image": KulitTahu2,
            // "ukuran": 1000,
        },
        en: {
            "nama": "Tofu Flower Skin",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Our Tofu Flower Skin is the perfect choice for those of you who are looking for a versatile, healthy and delicious food ingredient. With a soft and chewy texture, this product is suitable for various culinary creations such as spring rolls, soups, or even grilled dishes. Its natural savory taste will arouse the taste buds, making every dish taste special.
            \u00a0\u00a0\u00a0\u00a0 Not only delicious, our Tofu Flower Skin is also rich in vegetable protein and low in calories, suitable for those of you who want to maintain a healthy diet. Practical, nutritious and free of preservatives, this product is ready to complement your kitchen and provide a pleasant cooking experience! Let's create creative dishes with quality Tofu Flower Skin!
            `,
            // "kategori": "biji",
            "brand": "kulit-tahu",
            "image": KulitTahu2,
            // "ukuran": 1000,
        },
        ar: {
            "nama": "جلد زهرة التوفو",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 إن قشر زهرة التوفو الخاص بنا هو الخيار الأمثل لأولئك منكم الذين يبحثون عن مكونات غذائية متعددة الاستخدامات وصحية ولذيذة. بفضل ملمسه الناعم والمطاطي، يعد هذا المنتج مناسبًا لمختلف إبداعات الطهي مثل السبرينغ رولز أو الحساء أو حتى الأطباق المشوية. سوف يثير مذاقه الطبيعي اللذيذ براعم التذوق، مما يجعل مذاق كل طبق مميزًا.
            \u00a0\u00a0\u00a0\u00a0 ليس فقط لذيذًا، بل إن قشر زهرة التوفو الخاص بنا غني أيضًا بالبروتين النباتي ومنخفض السعرات الحرارية، وهو مناسب لأولئك منكم الذين يرغبون في الحفاظ على نظام غذائي صحي. هذا المنتج عملي ومغذي وخالي من المواد الحافظة، وهو جاهز لاستكمال مطبخك وتوفير تجربة طهي ممتعة! دعونا نصنع أطباقًا مبتكرة باستخدام جلد زهرة التوفو عالي الجودة!
            `,
            // "kategori": "biji",
            "brand": "kulit-tahu",
            "image": KulitTahu2,
            // "ukuran": 1000,
        },
        zh: {
            "nama": "豆腐花皮",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 对于那些正在寻找多功能、健康和美味食品原料的人来说，我们的豆腐花皮是您的完美选择。该产品质地柔软耐嚼，适合各种烹饪创作，如春卷、汤，甚至烧烤菜肴。其天然的鲜味会刺激味蕾，让每一道菜都别有风味。
            \u00a0\u00a0\u00a0\u00a0 我们的豆腐花皮不仅美味，还富含植物蛋白，热量低，适合想要保持健康饮食的您。该产品实用、营养且不含防腐剂，可补充您的厨房并提供愉快的烹饪体验！让我们用优质豆腐花皮创造创意菜肴吧！
            `,
            // "kategori": "biji",
            "brand": "kulit-tahu",
            "image": KulitTahu2,
            // "ukuran": 1000,
        },
    },
    // {
    //     id: {
    //         "nama": "Udang Putih Layar",
    //         "deskripsi": `
    //         \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Layar merupakan salah satu produk unggulan yang ditawarkan oleh Pusat Kerupuk Indonesia. Dibuat dengan bahan-bahan berkualitas tinggi dan proses produksi yang terjaga, kerupuk ini menjadi pilihan favorit bagi pecinta camilan tradisional. Dengan citarasa yang khas dan tekstur yang renyah, setiap gigitan kerupuk udang layar akan memanjakan lidah Anda. Dikemas dengan cermat untuk menjaga kesegaran dan kelezatannya, produk ini cocok disantap sebagai camilan sehari-hari atau sebagai pendamping saat bersantai bersama keluarga dan teman.
    //         \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Layar dari Pusat Kerupuk Indonesia tidak hanya menjanjikan rasa yang lezat, tetapi juga mengutamakan kualitas dan kebersihan dalam setiap proses pembuatannya. Dibuat dengan menggunakan udang segar yang diproses dengan teknik tradisional yang teruji, kerupuk ini memberikan aroma autentik dan kelezatan yang tidak tergantikan. Diproduksi dengan standar keamanan pangan yang ketat, Anda dapat menikmati kerupuk udang layar ini dengan rasa aman dan nyaman. Jadi, jangan ragu untuk memilih Kerupuk Udang Layar dari Pusat Kerupuk Indonesia sebagai pilihan camilan terbaik untuk memuaskan selera Anda.
    //         `,
    //         // "kategori": "biji",
    //         "brand": "kapal",
    //         "image": Kerupuk1,
    //         // "ukuran": 1000,
    //     },
    //     en: {
    //         "nama": "Screen Prawns",
    //         "deskripsi": `
    //         \u00a0\u00a0\u00a0\u00a0 Screened Prawn Crackers are one of the superior products offered by the Indonesian Cracker Center. Made with high quality ingredients and a maintained production process, these crackers are a favorite choice for traditional snack lovers. With a distinctive taste and crunchy texture, every bite of prawn crackers will pamper your taste buds. Packaged carefully to maintain freshness and deliciousness, this product is suitable to be eaten as an everyday snack or as an accompaniment when relaxing with family and friends.
    //         \u00a0\u00a0\u00a0\u00a0 Screened Shrimp Crackers from the Indonesian Cracker Center not only promise a delicious taste, but also prioritize quality and cleanliness in every manufacturing process. Made using fresh shrimp processed using proven traditional techniques, these crackers provide an authentic aroma and irreplaceable deliciousness. Produced with strict food safety standards, you can enjoy these prawn crackers with a sense of safety and comfort. So, don't hesitate to choose Shrimp Crackers from the Indonesian Crackers Center as the best snack choice to satisfy your taste.
    //         `,
    //         // "kategori": "biji",
    //         "brand": "kapal",
    //         "image": Kerupuk1,
    //         // "ukuran": 1000,
    //     },
    //     ar: {
    //         "nama": "روبيان الشاشة",
    //         "deskripsi": `
    //         \u00a0\u00a0\u00a0\u00a0 تعتبر مقرمشات الجمبري المفحوصة واحدة من المنتجات المتميزة التي يقدمها مركز المفرقعات الإندونيسي. مصنوعة من مكونات عالية الجودة وعملية إنتاج مستمرة، هذه المقرمشات هي الخيار المفضل لمحبي الوجبات الخفيفة التقليدية. مع المذاق المميز والملمس المقرمش، كل قضمة من مقرمشات الجمبري سوف تدلل ذوقك. تم تعبئته بعناية للحفاظ على نضارته ولذيذته، هذا المنتج مناسب لتناوله كوجبة خفيفة يومية أو كمرافق أثناء الاسترخاء مع العائلة والأصدقاء.
    //         \u00a0\u00a0\u00a0\u00a0 لا تعد مقرمشات الجمبري التي تم فحصها من مركز المفرقعات الإندونيسي بمذاق لذيذ فحسب، بل تعطي أيضًا الأولوية للجودة والنظافة في كل عملية تصنيع. مصنوعة باستخدام الروبيان الطازج المعالج باستخدام التقنيات التقليدية المثبتة، توفر هذه المقرمشات رائحة أصيلة ولذيذة لا يمكن استبدالها. تم إنتاجها وفقًا لمعايير سلامة الغذاء الصارمة، ويمكنك الاستمتاع بمقرمشات الجمبري هذه مع الشعور بالأمان والراحة. لذا، لا تتردد في اختيار مقرمشات الجمبري من مركز المقرمشات الإندونيسي كأفضل خيار للوجبات الخفيفة التي ترضي ذوقك.
    //         `,
    //         // "kategori": "biji",
    //         "brand": "kapal",
    //         "image": Kerupuk1,
    //         // "ukuran": 1000,
    //     },
    //     zh: {
    //         "nama": "筛虾",
    //         "deskripsi": `
    //         \u00a0\u00a0\u00a0\u00a0 筛选虾片是印度尼西亚饼干中心提供的优质产品之一。 这些饼干采用优质原料和精心维护的生产工艺制成，是传统零食爱好者的最爱。 独特的口感和松脆的口感，每一口虾片都会满足您的味蕾。 该产品经过精心包装，保持新鲜和美味，适合作为日常零食或与家人和朋友放松时的伴奏。
    //         \u00a0\u00a0\u00a0\u00a0 来自印尼饼干中心的筛选虾片不仅保证美味，而且在每个制造过程中都优先考虑质量和清洁。 这些饼干采用经过验证的传统技术加工的新鲜虾制成，具有正宗的香气和无可替代的美味。 按照严格的食品安全标准生产，您可以放心享用这些虾片。 所以，不要犹豫，选择印尼薄饼中心的虾饼作为满足您口味的最佳零食选择。
    //         `,
    //         // "kategori": "biji",
    //         "brand": "kapal",
    //         "image": Kerupuk1,
    //         // "ukuran": 1000,
    //     },
    // },
    {
        id: {
            "nama": "Bawang Bibir",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kerupuk Jengkol Bulat Hitam yang ditawarkan oleh Pusat Kerupuk Indonesia adalah sebuah inovasi menarik dalam dunia camilan tradisional. Dibuat dengan menggunakan jengkol pilihan yang diproses dengan teliti, kerupuk ini memiliki cita rasa unik yang menggoda selera. Dengan tekstur yang renyah namun tetap mempertahankan kelembutan jengkol, setiap gigitan kerupuk ini menyuguhkan pengalaman rasa yang berbeda dan memuaskan.
            \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia juga dijamin kebersihannya dan diproduksi dengan standar kualitas yang tinggi. Dengan mengutamakan keamanan pangan, Pusat Kerupuk Indonesia memastikan bahwa setiap kerupuk jengkol yang dihasilkan aman untuk dikonsumsi. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk ini cocok dinikmati sebagai camilan unik yang dapat memperkaya pengalaman kuliner Anda. Jadi, jangan lewatkan kesempatan untuk mencoba Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia dan rasakan sensasi baru dari camilan tradisional yang kaya akan rasa dan tekstur.
            `,
            "image": Kerupuk50,
            "brand": "layar"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        en: {
            "nama": "Onion Lips",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 The Black Round Jengkol Crackers offered by the Indonesian Crackers Center are an interesting innovation in the world of traditional snacks. Made using carefully processed carefully selected jengkol, these crackers have a unique taste that tempts the taste buds. With a crunchy texture but still maintaining the softness of the jengkol, every bite of these crackers offers a different and satisfying taste experience.
            \u00a0\u00a0\u00a0\u00a0 Black Round Jengkol Crackers products from the Indonesian Cracker Center are also guaranteed to be clean and produced to high quality standards. By prioritizing food safety, the Indonesian Crackers Center ensures that every jengkol cracker produced is safe for consumption. Carefully packaged to maintain freshness and quality, these crackers are suitable to be enjoyed as a unique snack that can enrich your culinary experience. So, don't miss the opportunity to try Black Round Jengkol Crackers from the Indonesian Crackers Center and experience a new sensation from a traditional snack that is rich in taste and texture.
            `,
            "image": Kerupuk50,
            "brand": "layar"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        ar: {
            "nama": "الشفاه البصلية",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 جينجكول المستديرة السوداء التي يقدمها مركز المفرقعات الإندونيسي ابتكارًا مثيرًا للاهتمام في عالم الوجبات الخفيفة التقليدية. تم صنع هذه المقرمشات باستخدام جينجكول تم معالجته بعناية وتم اختيارها بعناية، وتتميز بمذاق فريد يغري براعم التذوق. بفضل ملمسها المقرمش مع الحفاظ على ليونة الجينكول، فإن كل
            \u00a0\u00a0\u00a0\u00a0 مركز Cracker الإندونيسي مضمونة أيضًا أن تكون نظيفة ويتم إنتاجها وفقًا لمعايير الجودة العالية. من خلال إعطاء الأولوية لسلامة الأغذية، يضمن مركز المفرقعات الإندونيسي أن كل قطعة بسكويت جينجكول يتم إنتاجها آمنة للاستهلاك. معبأة بعناية للحفاظ على نضارتها وجودتها، هذه المقرمشات مناسبة للاستمتاع بها كوجبة خفيفة فريدة من نوعها يمكن أن تثري تجربتك في الطهي. لذا، لا تفوت فرصة تجربة Black Round Jengkol Crackers من مركز المفرقعات الإندونيسي وتجربة إحساس جديد من وجبة خفيفة تقليدية غنية بالطعم والملمس.
            `,
            "image": Kerupuk50,
            "brand": "layar"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        zh: {
            "nama": "洋葱唇",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心提供的黑色圆形 Jengkol 饼干是传统小吃领域的一项有趣的创新。 这些饼干采用精心加工、精心挑选的 jengkol 制成，具有独特的味道，诱惑着味蕾。 这些饼干质地松脆，但仍保持 jengkol 的柔软，每一口都提供不同且令人满意的味觉体验。
            \u00a0\u00a0\u00a0\u00a0 来自印度尼西亚饼干中心的 Black Round Jengkol 饼干产品也保证是清洁的并按照高质量标准生产。 通过优先考虑食品安全，印度尼西亚饼干中心确保生产的每块 jengkol 饼干都可以安全食用。 这些饼干经过精心包装，以保持新鲜度和品质，适合作为独特的零食享用，可以丰富您的烹饪体验。 因此，不要错过尝试印度尼西亚饼干中心的黑圆 Jengkol 饼干的机会，体验味道和质地丰富的传统小吃的新感觉。
            `,
            "image": Kerupuk50,
            "brand": "layar"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        }
    },
    {
        id: {
            "nama": "Udang Layar Putih",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kerupuk Jengkol Bulat Hitam yang ditawarkan oleh Pusat Kerupuk Indonesia adalah sebuah inovasi menarik dalam dunia camilan tradisional. Dibuat dengan menggunakan jengkol pilihan yang diproses dengan teliti, kerupuk ini memiliki cita rasa unik yang menggoda selera. Dengan tekstur yang renyah namun tetap mempertahankan kelembutan jengkol, setiap gigitan kerupuk ini menyuguhkan pengalaman rasa yang berbeda dan memuaskan.
            \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia juga dijamin kebersihannya dan diproduksi dengan standar kualitas yang tinggi. Dengan mengutamakan keamanan pangan, Pusat Kerupuk Indonesia memastikan bahwa setiap kerupuk jengkol yang dihasilkan aman untuk dikonsumsi. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk ini cocok dinikmati sebagai camilan unik yang dapat memperkaya pengalaman kuliner Anda. Jadi, jangan lewatkan kesempatan untuk mencoba Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia dan rasakan sensasi baru dari camilan tradisional yang kaya akan rasa dan tekstur.
            `,
            "image": Kerupuk54,
            "brand": "layar"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        en: {
            "nama": "White Sail Shrimp",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 The Black Round Jengkol Crackers offered by the Indonesian Crackers Center are an interesting innovation in the world of traditional snacks. Made using carefully processed carefully selected jengkol, these crackers have a unique taste that tempts the taste buds. With a crunchy texture but still maintaining the softness of the jengkol, every bite of these crackers offers a different and satisfying taste experience.
            \u00a0\u00a0\u00a0\u00a0 Black Round Jengkol Crackers products from the Indonesian Cracker Center are also guaranteed to be clean and produced to high quality standards. By prioritizing food safety, the Indonesian Crackers Center ensures that every jengkol cracker produced is safe for consumption. Carefully packaged to maintain freshness and quality, these crackers are suitable to be enjoyed as a unique snack that can enrich your culinary experience. So, don't miss the opportunity to try Black Round Jengkol Crackers from the Indonesian Crackers Center and experience a new sensation from a traditional snack that is rich in taste and texture.
            `,
            "image": Kerupuk54,
            "brand": "layar"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        ar: {
            "nama": "الروبيان الشراع الأبيض",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 جينجكول المستديرة السوداء التي يقدمها مركز المفرقعات الإندونيسي ابتكارًا مثيرًا للاهتمام في عالم الوجبات الخفيفة التقليدية. تم صنع هذه المقرمشات باستخدام جينجكول تم معالجته بعناية وتم اختيارها بعناية، وتتميز بمذاق فريد يغري براعم التذوق. بفضل ملمسها المقرمش مع الحفاظ على ليونة الجينكول، فإن كل
            \u00a0\u00a0\u00a0\u00a0 مركز Cracker الإندونيسي مضمونة أيضًا أن تكون نظيفة ويتم إنتاجها وفقًا لمعايير الجودة العالية. من خلال إعطاء الأولوية لسلامة الأغذية، يضمن مركز المفرقعات الإندونيسي أن كل قطعة بسكويت جينجكول يتم إنتاجها آمنة للاستهلاك. معبأة بعناية للحفاظ على نضارتها وجودتها، هذه المقرمشات مناسبة للاستمتاع بها كوجبة خفيفة فريدة من نوعها يمكن أن تثري تجربتك في الطهي. لذا، لا تفوت فرصة تجربة Black Round Jengkol Crackers من مركز المفرقعات الإندونيسي وتجربة إحساس جديد من وجبة خفيفة تقليدية غنية بالطعم والملمس.
            `,
            "image": Kerupuk54,
            "brand": "layar"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        zh: {
            "nama": "白帆虾",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心提供的黑色圆形 Jengkol 饼干是传统小吃领域的一项有趣的创新。 这些饼干采用精心加工、精心挑选的 jengkol 制成，具有独特的味道，诱惑着味蕾。 这些饼干质地松脆，但仍保持 jengkol 的柔软，每一口都提供不同且令人满意的味觉体验。
            \u00a0\u00a0\u00a0\u00a0 来自印度尼西亚饼干中心的 Black Round Jengkol 饼干产品也保证是清洁的并按照高质量标准生产。 通过优先考虑食品安全，印度尼西亚饼干中心确保生产的每块 jengkol 饼干都可以安全食用。 这些饼干经过精心包装，以保持新鲜度和品质，适合作为独特的零食享用，可以丰富您的烹饪体验。 因此，不要错过尝试印度尼西亚饼干中心的黑圆 Jengkol 饼干的机会，体验味道和质地丰富的传统小吃的新感觉。
            `,
            "image": Kerupuk54,
            "brand": "layar"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        }
    },
    {
        id: {
            "nama": "Udang Layar Orange",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kerupuk Jengkol Bulat Hitam yang ditawarkan oleh Pusat Kerupuk Indonesia adalah sebuah inovasi menarik dalam dunia camilan tradisional. Dibuat dengan menggunakan jengkol pilihan yang diproses dengan teliti, kerupuk ini memiliki cita rasa unik yang menggoda selera. Dengan tekstur yang renyah namun tetap mempertahankan kelembutan jengkol, setiap gigitan kerupuk ini menyuguhkan pengalaman rasa yang berbeda dan memuaskan.
            \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia juga dijamin kebersihannya dan diproduksi dengan standar kualitas yang tinggi. Dengan mengutamakan keamanan pangan, Pusat Kerupuk Indonesia memastikan bahwa setiap kerupuk jengkol yang dihasilkan aman untuk dikonsumsi. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk ini cocok dinikmati sebagai camilan unik yang dapat memperkaya pengalaman kuliner Anda. Jadi, jangan lewatkan kesempatan untuk mencoba Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia dan rasakan sensasi baru dari camilan tradisional yang kaya akan rasa dan tekstur.
            `,
            "image": Kerupuk55,
            "brand": "layar",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        en: {
            "nama": "Orange Screen Shrimp",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 The Black Round Jengkol Crackers offered by the Indonesian Crackers Center are an interesting innovation in the world of traditional snacks. Made using carefully processed carefully selected jengkol, these crackers have a unique taste that tempts the taste buds. With a crunchy texture but still maintaining the softness of the jengkol, every bite of these crackers offers a different and satisfying taste experience.
            \u00a0\u00a0\u00a0\u00a0 Black Round Jengkol Crackers products from the Indonesian Cracker Center are also guaranteed to be clean and produced to high quality standards. By prioritizing food safety, the Indonesian Crackers Center ensures that every jengkol cracker produced is safe for consumption. Carefully packaged to maintain freshness and quality, these crackers are suitable to be enjoyed as a unique snack that can enrich your culinary experience. So, don't miss the opportunity to try Black Round Jengkol Crackers from the Indonesian Crackers Center and experience a new sensation from a traditional snack that is rich in taste and texture.
            `,
            "image": Kerupuk55,
            "brand": "layar",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        ar: {
            "nama": "روبيان الشاشة البرتقالية",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 جينجكول المستديرة السوداء التي يقدمها مركز المفرقعات الإندونيسي ابتكارًا مثيرًا للاهتمام في عالم الوجبات الخفيفة التقليدية. تم صنع هذه المقرمشات باستخدام جينجكول تم معالجته بعناية وتم اختيارها بعناية، وتتميز بمذاق فريد يغري براعم التذوق. بفضل ملمسها المقرمش مع الحفاظ على ليونة الجينكول، فإن كل
            \u00a0\u00a0\u00a0\u00a0 مركز Cracker الإندونيسي مضمونة أيضًا أن تكون نظيفة ويتم إنتاجها وفقًا لمعايير الجودة العالية. من خلال إعطاء الأولوية لسلامة الأغذية، يضمن مركز المفرقعات الإندونيسي أن كل قطعة بسكويت جينجكول يتم إنتاجها آمنة للاستهلاك. معبأة بعناية للحفاظ على نضارتها وجودتها، هذه المقرمشات مناسبة للاستمتاع بها كوجبة خفيفة فريدة من نوعها يمكن أن تثري تجربتك في الطهي. لذا، لا تفوت فرصة تجربة Black Round Jengkol Crackers من مركز المفرقعات الإندونيسي وتجربة إحساس جديد من وجبة خفيفة تقليدية غنية بالطعم والملمس.
            `,
            "image": Kerupuk55,
            "brand": "layar",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        zh: {
            "nama": "橙屏虾",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心提供的黑色圆形 Jengkol 饼干是传统小吃领域的一项有趣的创新。 这些饼干采用精心加工、精心挑选的 jengkol 制成，具有独特的味道，诱惑着味蕾。 这些饼干质地松脆，但仍保持 jengkol 的柔软，每一口都提供不同且令人满意的味觉体验。
            \u00a0\u00a0\u00a0\u00a0 来自印度尼西亚饼干中心的 Black Round Jengkol 饼干产品也保证是清洁的并按照高质量标准生产。 通过优先考虑食品安全，印度尼西亚饼干中心确保生产的每块 jengkol 饼干都可以安全食用。 这些饼干经过精心包装，以保持新鲜度和品质，适合作为独特的零食享用，可以丰富您的烹饪体验。 因此，不要错过尝试印度尼西亚饼干中心的黑圆 Jengkol 饼干的机会，体验味道和质地丰富的传统小吃的新感觉。
            `,
            "image": Kerupuk55,
            "brand": "layar",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        }
    },
    {
        id: {
            "nama": "Tersanjung Warna",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kerupuk Jengkol Bulat Hitam yang ditawarkan oleh Pusat Kerupuk Indonesia adalah sebuah inovasi menarik dalam dunia camilan tradisional. Dibuat dengan menggunakan jengkol pilihan yang diproses dengan teliti, kerupuk ini memiliki cita rasa unik yang menggoda selera. Dengan tekstur yang renyah namun tetap mempertahankan kelembutan jengkol, setiap gigitan kerupuk ini menyuguhkan pengalaman rasa yang berbeda dan memuaskan.
            \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia juga dijamin kebersihannya dan diproduksi dengan standar kualitas yang tinggi. Dengan mengutamakan keamanan pangan, Pusat Kerupuk Indonesia memastikan bahwa setiap kerupuk jengkol yang dihasilkan aman untuk dikonsumsi. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk ini cocok dinikmati sebagai camilan unik yang dapat memperkaya pengalaman kuliner Anda. Jadi, jangan lewatkan kesempatan untuk mencoba Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia dan rasakan sensasi baru dari camilan tradisional yang kaya akan rasa dan tekstur.
            `,
            "image": Kerupuk48,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        en: {
            "nama": "Flattered Colors",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 The Black Round Jengkol Crackers offered by the Indonesian Crackers Center are an interesting innovation in the world of traditional snacks. Made using carefully processed carefully selected jengkol, these crackers have a unique taste that tempts the taste buds. With a crunchy texture but still maintaining the softness of the jengkol, every bite of these crackers offers a different and satisfying taste experience.
            \u00a0\u00a0\u00a0\u00a0 Black Round Jengkol Crackers products from the Indonesian Cracker Center are also guaranteed to be clean and produced to high quality standards. By prioritizing food safety, the Indonesian Crackers Center ensures that every jengkol cracker produced is safe for consumption. Carefully packaged to maintain freshness and quality, these crackers are suitable to be enjoyed as a unique snack that can enrich your culinary experience. So, don't miss the opportunity to try Black Round Jengkol Crackers from the Indonesian Crackers Center and experience a new sensation from a traditional snack that is rich in taste and texture.
            `,
            "image": Kerupuk48,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        ar: {
            "nama": "الألوان المبهجة",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 جينجكول المستديرة السوداء التي يقدمها مركز المفرقعات الإندونيسي ابتكارًا مثيرًا للاهتمام في عالم الوجبات الخفيفة التقليدية. تم صنع هذه المقرمشات باستخدام جينجكول تم معالجته بعناية وتم اختيارها بعناية، وتتميز بمذاق فريد يغري براعم التذوق. بفضل ملمسها المقرمش مع الحفاظ على ليونة الجينكول، فإن كل
            \u00a0\u00a0\u00a0\u00a0 مركز Cracker الإندونيسي مضمونة أيضًا أن تكون نظيفة ويتم إنتاجها وفقًا لمعايير الجودة العالية. من خلال إعطاء الأولوية لسلامة الأغذية، يضمن مركز المفرقعات الإندونيسي أن كل قطعة بسكويت جينجكول يتم إنتاجها آمنة للاستهلاك. معبأة بعناية للحفاظ على نضارتها وجودتها، هذه المقرمشات مناسبة للاستمتاع بها كوجبة خفيفة فريدة من نوعها يمكن أن تثري تجربتك في الطهي. لذا، لا تفوت فرصة تجربة Black Round Jengkol Crackers من مركز المفرقعات الإندونيسي وتجربة إحساس جديد من وجبة خفيفة تقليدية غنية بالطعم والملمس.
            `,
            "image": Kerupuk48,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        zh: {
            "nama": "受宠若惊的颜色",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心提供的黑色圆形 Jengkol 饼干是传统小吃领域的一项有趣的创新。 这些饼干采用精心加工、精心挑选的 jengkol 制成，具有独特的味道，诱惑着味蕾。 这些饼干质地松脆，但仍保持 jengkol 的柔软，每一口都提供不同且令人满意的味觉体验。
            \u00a0\u00a0\u00a0\u00a0 来自印度尼西亚饼干中心的 Black Round Jengkol 饼干产品也保证是清洁的并按照高质量标准生产。 通过优先考虑食品安全，印度尼西亚饼干中心确保生产的每块 jengkol 饼干都可以安全食用。 这些饼干经过精心包装，以保持新鲜度和品质，适合作为独特的零食享用，可以丰富您的烹饪体验。 因此，不要错过尝试印度尼西亚饼干中心的黑圆 Jengkol 饼干的机会，体验味道和质地丰富的传统小吃的新感觉。
            `,
            "image": Kerupuk48,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        }
    },
    {
        id: {
            "nama": "Jengkol Bulat Hitam",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kerupuk Jengkol Bulat Hitam yang ditawarkan oleh Pusat Kerupuk Indonesia adalah sebuah inovasi menarik dalam dunia camilan tradisional. Dibuat dengan menggunakan jengkol pilihan yang diproses dengan teliti, kerupuk ini memiliki cita rasa unik yang menggoda selera. Dengan tekstur yang renyah namun tetap mempertahankan kelembutan jengkol, setiap gigitan kerupuk ini menyuguhkan pengalaman rasa yang berbeda dan memuaskan.
            \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia juga dijamin kebersihannya dan diproduksi dengan standar kualitas yang tinggi. Dengan mengutamakan keamanan pangan, Pusat Kerupuk Indonesia memastikan bahwa setiap kerupuk jengkol yang dihasilkan aman untuk dikonsumsi. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk ini cocok dinikmati sebagai camilan unik yang dapat memperkaya pengalaman kuliner Anda. Jadi, jangan lewatkan kesempatan untuk mencoba Kerupuk Jengkol Bulat Hitam dari Pusat Kerupuk Indonesia dan rasakan sensasi baru dari camilan tradisional yang kaya akan rasa dan tekstur.
            `,
            "image": Kerupuk3,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        en: {
            "nama": "Black Round Jengkol",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 The Black Round Jengkol Crackers offered by the Indonesian Crackers Center are an interesting innovation in the world of traditional snacks. Made using carefully processed carefully selected jengkol, these crackers have a unique taste that tempts the taste buds. With a crunchy texture but still maintaining the softness of the jengkol, every bite of these crackers offers a different and satisfying taste experience.
            \u00a0\u00a0\u00a0\u00a0 Black Round Jengkol Crackers products from the Indonesian Cracker Center are also guaranteed to be clean and produced to high quality standards. By prioritizing food safety, the Indonesian Crackers Center ensures that every jengkol cracker produced is safe for consumption. Carefully packaged to maintain freshness and quality, these crackers are suitable to be enjoyed as a unique snack that can enrich your culinary experience. So, don't miss the opportunity to try Black Round Jengkol Crackers from the Indonesian Crackers Center and experience a new sensation from a traditional snack that is rich in taste and texture.
            `,
            "image": Kerupuk3,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        ar: {
            "nama": "جولة سوداء جينجكول",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 جينجكول المستديرة السوداء التي يقدمها مركز المفرقعات الإندونيسي ابتكارًا مثيرًا للاهتمام في عالم الوجبات الخفيفة التقليدية. تم صنع هذه المقرمشات باستخدام جينجكول تم معالجته بعناية وتم اختيارها بعناية، وتتميز بمذاق فريد يغري براعم التذوق. بفضل ملمسها المقرمش مع الحفاظ على ليونة الجينكول، فإن كل
            \u00a0\u00a0\u00a0\u00a0 مركز Cracker الإندونيسي مضمونة أيضًا أن تكون نظيفة ويتم إنتاجها وفقًا لمعايير الجودة العالية. من خلال إعطاء الأولوية لسلامة الأغذية، يضمن مركز المفرقعات الإندونيسي أن كل قطعة بسكويت جينجكول يتم إنتاجها آمنة للاستهلاك. معبأة بعناية للحفاظ على نضارتها وجودتها، هذه المقرمشات مناسبة للاستمتاع بها كوجبة خفيفة فريدة من نوعها يمكن أن تثري تجربتك في الطهي. لذا، لا تفوت فرصة تجربة Black Round Jengkol Crackers من مركز المفرقعات الإندونيسي وتجربة إحساس جديد من وجبة خفيفة تقليدية غنية بالطعم والملمس.
            `,
            "image": Kerupuk3,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        zh: {
            "nama": "黑色圆形 Jengkol",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心提供的黑色圆形 Jengkol 饼干是传统小吃领域的一项有趣的创新。 这些饼干采用精心加工、精心挑选的 jengkol 制成，具有独特的味道，诱惑着味蕾。 这些饼干质地松脆，但仍保持 jengkol 的柔软，每一口都提供不同且令人满意的味觉体验。
            \u00a0\u00a0\u00a0\u00a0 来自印度尼西亚饼干中心的 Black Round Jengkol 饼干产品也保证是清洁的并按照高质量标准生产。 通过优先考虑食品安全，印度尼西亚饼干中心确保生产的每块 jengkol 饼干都可以安全食用。 这些饼干经过精心包装，以保持新鲜度和品质，适合作为独特的零食享用，可以丰富您的烹饪体验。 因此，不要错过尝试印度尼西亚饼干中心的黑圆 Jengkol 饼干的机会，体验味道和质地丰富的传统小吃的新感觉。
            `,
            "image": Kerupuk3,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        }
    },
    {
        id: {
            "nama": "Jengkol Sisir Hitam",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Dari Cap Kapal, hadir Kerupuk Jengkol Sisir Hitam, produk unggulan yang tersedia di Pusat Kerupuk Indonesia. Merupakan simbol keunggulan dalam dunia kuliner, kerupuk ini menghadirkan cita rasa yang kaya dan tekstur yang renyah, menciptakan pengalaman makan yang memikat. Diproses dengan teliti menggunakan bahan-bahan berkualitas terbaik, setiap kerupuk menggabungkan kelezatan dan keaslian cita rasa jengkol dalam setiap gigitannya, memenuhi kenikmatan lidah para penikmatnya.
            \u00a0\u00a0\u00a0\u00a0 Kerupuk Jengkol Sisir Hitam dari Cap Kapal tidak hanya sekadar camilan, melainkan karya seni kuliner yang memikat. Dengan kemasan yang menarik dan praktis, produk ini menjadi pilihan sempurna untuk dinikmati di berbagai kesempatan. Jadi, jadikanlah kerupuk ini sebagai teman setia dalam setiap momen bersantap Anda, dan nikmatilah kelezatan yang luar biasa dari Cap Kapal, eksklusif di Pusat Kerupuk Indonesia.
            `,
            "image": Kerupuk6,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        en: {
            "nama": "Black Comb Jengkol",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 From Cap Kapal, comes the Black Combed Jengkol Crackers, a superior product available at the Indonesian Crackers Center. A symbol of excellence in the culinary world, these crackers deliver a rich taste and crunchy texture, creating an enticing eating experience. Carefully processed using the best quality ingredients, each cracker combines the delicious and authentic taste of jengkol in every bite, fulfilling the taste buds of its connoisseurs.
            \u00a0\u00a0\u00a0\u00a0 Black Combed Jengkol Crackers from Cap Kapal are not just a snack, but an alluring work of culinary art. With attractive and practical packaging, this product is the perfect choice to enjoy on various occasions. So, make these crackers a loyal friend in every moment of your meal, and enjoy the extraordinary deliciousness of Cap Kapal, exclusively at Pusat Kerupuk Indonesia.
            `,
            "image": Kerupuk6,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        ar: {
            "nama": "مشط أسود جينجكول",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 من Cap Kapal، يأتي Black Combed Jengkol Crackers، وهو منتج متميز متوفر في مركز المفرقعات الإندونيسي. رمز التميز في عالم الطهي، توفر هذه المقرمشات طعمًا غنيًا وملمسًا مقرمشًا، مما يخلق تجربة تناول طعام جذابة. تمت معالجتها بعناية باستخدام أفضل المكونات عالية الجودة، حيث تجمع كل قطعة بسكويت بين المذاق اللذيذ والأصيل لجينجكول في كل قضمة، مما يلبي أذواق خبراءها.
            \u00a0\u00a0\u00a0\u00a0 إن مقرمشات جينجكول السوداء الممشطة من كاب كابال ليست مجرد وجبة خفيفة، ولكنها عمل جذاب من فن الطهي. بفضل العبوة الجذابة والعملية، يعد هذا المنتج الخيار الأمثل للاستمتاع به في مناسبات مختلفة. لذا، اجعل هذه البسكويت صديقًا مخلصًا في كل لحظة من وجبتك، واستمتع بالطعم الاستثنائي لكاب كابال، حصريًا في Pusat Kerupuk Indonesia.
            `,
            "image": Kerupuk6,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        zh: {
            "nama": "黑梳Jengkol",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Cap Kapal 推出了黑色精梳 Jengkol 饼干，这是印度尼西亚饼干中心提供的优质产品。 这些饼干是烹饪界卓越的象征，具有丰富的味道和松脆的质地，创造出诱人的饮食体验。 每块饼干均采用最优质的原料精心加工而成，每一口都融合了 jengkol 的美味和正宗味道，满足了鉴赏家的味蕾。
            \u00a0\u00a0\u00a0\u00a0 Cap Kapal 的黑色精梳 Jengkol 饼干不仅仅是一种小吃，更是一件诱人的烹饪艺术作品。 该产品具有美观实用的包装，是在各种场合享用的完美选择。 因此，让这些饼干成为您用餐时每时每刻的忠实朋友，并在印度尼西亚 Pusat Kerupuk 独家享受 Cap Kapal 的非凡美味。
            `,
            "image": Kerupuk6,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        }
    },
    {
        id: {
            "nama": "Mawar Kuning Spesial",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Dipersembahkan oleh Pusat Kerupuk Indonesia, Kasandra Kuning adalah produk kerupuk yang membawa citarasa yang unik dan menyegarkan bagi para pencinta kuliner. Dibuat dengan teliti menggunakan bahan-bahan pilihan, setiap gigitan dari kerupuk ini menghadirkan kombinasi sempurna antara kelembutan tekstur dan kelezatan rasa. Keunikan Kasandra Kuning terletak pada sentuhan rempah-rempah yang memberikan aroma dan rasa yang khas, menciptakan pengalaman makan yang tak terlupakan bagi semua orang yang mencicipinya.
            \u00a0\u00a0\u00a0\u00a0 Tersedia dalam kemasan yang menarik dan praktis, Kasandra Kuning siap memenuhi kebutuhan camilan Anda di setiap kesempatan. Jadikanlah produk ini sebagai pilihan utama untuk melengkapi santapan Anda bersama keluarga dan teman-teman. Dengan Kasandra Kuning, nikmati kelezatan yang luar biasa dan jelajahi sensasi baru dalam dunia kuliner, hanya dari Pusat Kerupuk Indonesia.
            `,
            "image": Kerupuk7,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        en: {
            "nama": "Special Yellow Rose",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Presented by the Indonesian Cracker Center, Kasandra Kuning is a cracker product that brings a unique and refreshing taste to culinary lovers. Carefully made using selected ingredients, every bite of these crackers presents the perfect combination of soft texture and delicious taste. The uniqueness of Kasandra Kuning lies in the touch of spices which provide a distinctive aroma and taste, creating an unforgettable eating experience for everyone who tastes it.
            \u00a0\u00a0\u00a0\u00a0 Available in attractive and practical packaging, Kasandra Kuning is ready to meet your snack needs at every opportunity. Make this product the main choice to complete your meal with family and friends. With Kasandra Kuning, enjoy extraordinary delicacies and explore new sensations in the culinary world, only from Pusat Kerupuk Indonesia.
            `,
            "image": Kerupuk7,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        ar: {
            "nama": "الوردة الصفراء المميزة",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 يعد كاساندرا كونينج، الذي قدمه مركز المفرقعات الإندونيسي، أحد منتجات المفرقعات التي تجلب مذاقًا فريدًا ومنعشًا لمحبي الطهي. مصنوعة بعناية باستخدام مكونات مختارة، كل قضمة من هذه البسكويت تقدم مزيجًا مثاليًا من الملمس الناعم والطعم اللذيذ. يكمن تفرد كاساندرا كونينج في لمسة التوابل التي توفر رائحة وطعمًا مميزًا، مما يخلق تجربة طعام لا تُنسى لكل من يتذوقها.
            \u00a0\u00a0\u00a0\u00a0 متوفر في عبوات جذابة وعملية، Kasandra Kuning على استعداد لتلبية احتياجاتك من الوجبات الخفيفة في كل فرصة. اجعل هذا المنتج هو الخيار الرئيسي لإكمال وجبتك مع العائلة والأصدقاء. مع كاساندرا كونينج، استمتع بالمأكولات الشهية غير العادية واستكشف أحاسيس جديدة في عالم الطهي، فقط من بوسات كيروبوك إندونيسيا.
            `,
            "image": Kerupuk7,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        zh: {
            "nama": "特别黄玫瑰",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kasandra Kuning是由印尼饼干中心推出的一款饼干产品，为烹饪爱好者带来独特清爽的口感。 这些饼干采用精选原料精心制作，每一口都呈现出柔软质地和美味口感的完美结合。 Kasandra Kuning 的独特之处在于香料的味道，提供独特的香气和味道，为每个品尝它的人创造难忘的饮食体验。
            \u00a0\u00a0\u00a0\u00a0 Kasandra Kuning 采用精美且实用的包装，随时准备满足您的零食需求。 使该产品成为与家人和朋友一起用餐的主要选择。 与 Kasandra Kuning 一起，享受仅来自印度尼西亚 Pusat Kerupuk 的非凡美食并探索烹饪界的新感觉。
            `,
            "image": Kerupuk7,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        }
    },
    {
        id: {
            "nama": "Kecipir Warna",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Dari Pusat Kerupuk Indonesia, hadirlah Kecipir Warna, sebuah inovasi baru dalam dunia camilan yang menggugah selera. Dibuat dengan teliti dan menggunakan bahan-bahan berkualitas tinggi, setiap gigitan Kecipir Warna membawa Anda dalam petualangan rasa yang mengasyikkan. Keunikan produk ini terletak pada beragamnya warna yang memikat, memberikan sentuhan visual yang menarik dan menyenangkan saat dinikmati.
            \u00a0\u00a0\u00a0\u00a0 Tersedia dalam kemasan yang praktis dan menawan, Kecipir Warna adalah pilihan sempurna untuk menemani momen santap Anda di mana pun dan kapan pun. Dengan cita rasa yang autentik dan kelezatan yang menggugah selera, Kecipir Warna akan menjadi favorit baru dalam daftar camilan Anda. Jelajahi kelezatan dan warna-warni kehidupan dengan Kecipir Warna, hanya di Pusat Kerupuk Indonesia.
            `,
            "image": Kerupuk8,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        en: {
            "nama": "Colored Winged Beans",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 From the Indonesian Crackers Center comes Kecipir Warna, a new innovation in the world of appetizing snacks. Made with care and using high-quality ingredients, every bite of Winged Bean Color takes you on an exciting taste adventure. The uniqueness of this product lies in its variety of attractive colors, providing an attractive and enjoyable visual touch when enjoyed.
            \u00a0\u00a0\u00a0\u00a0 Available in practical and attractive packaging, Kecipir Warna is the perfect choice to accompany your dining moments wherever and whenever. With its authentic taste and mouth-watering deliciousness, Kecipir Warna will become a new favorite on your snack list. Explore the delights and colors of life with Kecipir Warna, only at Pusat Kerupuk Indonesia.
            `,
            "image": Kerupuk8,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        ar: {
            "nama": "الفاصوليا المجنحة الملونة",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 من مركز المفرقعات الإندونيسي يأتي Kecipir Warna، وهو ابتكار جديد في عالم الوجبات الخفيفة الشهية. مصنوعة بعناية وباستخدام مكونات عالية الجودة، كل قضمة من Winged Bean Color تأخذك في مغامرة تذوق مثيرة. ويكمن تفرد هذا المنتج في تنوع ألوانه الجذابة، مما يوفر لمسة بصرية جذابة وممتعة عند الاستمتاع به.
            \u00a0\u00a0\u00a0\u00a0 متوفر في عبوات عملية وجذابة، Kecipir Warna هو الخيار الأمثل لمرافقة لحظات تناول الطعام الخاصة بك أينما ومتى. بفضل مذاقه الأصيل ولذته الشهية، سيصبح كيسيبير وارنا المفضل الجديد في قائمة الوجبات الخفيفة الخاصة بك. اكتشف متعة وألوان الحياة مع Kecipir Warna، فقط في Pusat Kerupuk Indonesia.
            `,
            "image": Kerupuk8,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        zh: {
            "nama": "彩色四棱豆",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 来自印度尼西亚饼干中心的 Kecipir Warna 是开胃零食领域的一项新创新。 采用优质原料精心制作，每一口四棱豆彩都将带您踏上激动人心的味觉冒险之旅。 该产品的独特之处在于其多种迷人的色彩，在享用时提供有吸引力且令人愉悦的视觉触感。
            \u00a0\u00a0\u00a0\u00a0 Kecipir Warna 采用实用且有吸引力的包装，是随时随地陪伴您用餐时刻的完美选择。 凭借其正宗的口味和令人垂涎的美味，Kecipir Warna 将成为您零食清单上的新宠。 与 Kecipir Warna 一起探索生活的乐趣和色彩，只有在印度尼西亚 Pusat Kerupuk 才能实现。
            `,
            "image": Kerupuk8,
            "brand": "kapal"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        }
    },
    {
        id: {
            "nama": "Kelabang Kuning",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Dari Pusat Kerupuk Indonesia, hadir Kerupuk Kelabang Kuning, sebuah produk yang menggabungkan kelezatan dan keunikan dalam setiap gigitannya. Dibuat dengan teliti menggunakan bahan-bahan berkualitas tinggi, kerupuk ini memberikan cita rasa yang khas dan tekstur yang renyah, memikat lidah para penikmatnya. Kelebihan utama dari Kelabang Kuning adalah warna kuning cerah yang memikat, memberikan sentuhan visual yang menarik dan segar saat dinikmati.
            \u00a0\u00a0\u00a0\u00a0 Tersedia dalam kemasan yang praktis dan menawan, Kerupuk Kelabang Kuning menjadi pilihan yang tepat untuk melengkapi berbagai acara santap Anda. Dengan kelezatan yang luar biasa dan tampilan yang menggugah selera, Kelabang Kuning akan menjadi favorit di meja makan Anda. Jelajahi pengalaman baru dalam menikmati camilan dengan Kerupuk Kelabang Kuning, hanya di Pusat Kerupuk Indonesia. `,
            "image": Kerupuk9,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        en: {
            "nama": "Colored Winged Beans",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 From the Indonesian Cracker Center, comes Kerupuk Kelabang Kuning, a product that combines deliciousness and uniqueness in every bite. Carefully made using high quality ingredients, these crackers provide a distinctive taste and crunchy texture, captivating the taste buds of those who enjoy them. The main advantage of the Yellow Centipede is its attractive bright yellow color, providing an attractive and fresh visual touch when enjoyed.
            \u00a0\u00a0\u00a0\u00a0 Available in practical and attractive packaging, Yellow Kelapang Crackers are the right choice to complement your various dining events. With its extraordinary deliciousness and appetizing appearance, Yellow Centipede will become a favorite on your dinner table. Explore a new experience in enjoying snacks with Kerupuk Kelabang Kuning, only at the Indonesian Crackers Center.
            `,
            "image": Kerupuk9,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        ar: {
            "nama": "الفاصوليا المجنحة الملونة",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 من مركز المفرقعات الإندونيسي، يأتي كيروبوك كيلابانغ كونينغ، وهو منتج يجمع بين اللذة والتفرد في كل قضمة. تم صنع هذه المقرمشات بعناية باستخدام مكونات عالية الجودة، وتوفر طعمًا مميزًا وملمسًا مقرمشًا، مما يأسر براعم التذوق لدى من يستمتعون بها. الميزة الرئيسية للحريش الأصفر هو لونه الأصفر اللامع الجذاب، مما يوفر لمسة بصرية جذابة وجديدة عند الاستمتاع بها.
            \u00a0\u00a0\u00a0\u00a0 متوفرة في عبوات عملية وجذابة، مقرمشات كيلابانغ الصفراء هي الخيار الصحيح لاستكمال مناسبات تناول الطعام المتنوعة الخاصة بك. بفضل لذته الاستثنائية ومظهره الفاتح للشهية، سيصبح الحريش الأصفر هو المفضل على مائدة العشاء الخاصة بك. اكتشف تجربة جديدة في الاستمتاع بالوجبات الخفيفة مع Kerupuk Kelabang Kuning، فقط في مركز المفرقعات الإندونيسية.
            `,
            "image": Kerupuk9,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        },
        zh: {
            "nama": "彩色四棱豆",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 来自印度尼西亚饼干中心的 Kerupuk Kelabang Kuning 是一款每一口都融合了美味和独特的产品。 这些饼干采用优质原料精心制作，具有独特的味道和松脆的质地，吸引着喜欢它们的人的味蕾。 黄蜈蚣的主要优点是其诱人的亮黄色，在享用时给人一种诱人而清新的视觉触感。
            \u00a0\u00a0\u00a0\u00a0 黄色 Kelapang 饼干采用实用且美观的包装，是搭配您各种餐饮活动的正确选择。 黄蜈蚣以其非凡的美味和诱人的外观，将成为您餐桌上的最爱。 与 Kerupuk Kelabang Kuning 一起探索享用小吃的新体验，只有在印度尼西亚饼干中心才能享受到。
            `,
            "image": Kerupuk9,
            "brand": "kapal",
            "type": "best"
            // "kategori": "daging",
            // "ukuran": 35,
            // "brand": "gunung"
        }
    },
    {
        id: {
            "nama": "Makaroni Bantet",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Mede yang dihadirkan oleh Pusat Kerupuk Indonesia adalah kombinasi yang menarik antara cita rasa udang yang lezat dengan kelezatan kacang mede yang khas. Dibuat dari bahan-bahan berkualitas tinggi dan diproses dengan teknik yang teruji, kerupuk ini menghadirkan pengalaman rasa yang unik dan memuaskan. Teksturnya yang renyah dan cita rasanya yang gurih menjadikan setiap gigitan kerupuk Udang Mede sebagai sensasi yang menggoda lidah.
            \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Udang Mede dari Pusat Kerupuk Indonesia juga menonjol karena standar kebersihan dan kualitasnya yang terjamin. Diproduksi dengan proses yang higienis dan menggunakan bahan-bahan alami, kerupuk ini merupakan pilihan camilan yang aman dan sehat untuk dinikmati oleh seluruh keluarga. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk Udang Mede siap menyempurnakan momen santai Anda di rumah atau sebagai teman setia dalam acara bersama teman dan keluarga. Jadi, jangan ragu untuk memilih Kerupuk Udang Mede dari Pusat Kerupuk Indonesia untuk menggugah selera Anda dengan citarasa yang istimewa.
            `,
            "image": Kerupuk51,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
        en: {
            "nama": "Bantet Macaroni",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Cashew Shrimp Crackers presented by the Indonesian Crackers Center are an interesting combination of the delicious taste of shrimp with the typical deliciousness of cashew nuts. Made from high quality ingredients and processed using proven techniques, these crackers provide a unique and satisfying taste experience. The crunchy texture and savory taste make every bite of Cashew Prawn Crackers a tempting sensation on the tongue.
            \u00a0\u00a0\u00a0\u00a0 Cashew Prawn Crackers products from the Indonesian Crackers Center also stand out because of their guaranteed hygiene standards and quality. Produced using a hygienic process and using natural ingredients, these crackers are a safe and healthy snack choice for the whole family to enjoy. Packaged carefully to maintain freshness and quality, Cashew Shrimp crackers are ready to perfect your relaxing moments at home or as a loyal companion at events with friends and family. So, don't hesitate to choose Cashew Prawn Crackers from the Indonesian Crackers Center to tempt your taste buds with a special taste.
            `,
            "image": Kerupuk51,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
        ar: {
            "nama": "بانتيت معكرونة",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 مقرمشات الجمبري بالكاجو المقدمة من مركز المفرقعات الإندونيسي هي مزيج مثير للاهتمام من المذاق اللذيذ للروبيان مع اللذة النموذجية لجوز الكاجو. مصنوعة من مكونات عالية الجودة وتم معالجتها باستخدام تقنيات مجربة، توفر هذه المقرمشات تجربة ذوق فريدة ومرضية. الملمس المقرمش والطعم اللذيذ يجعل كل قضمة من مقرمشات الكاجو الجمبري إحساسًا مغريًا على اللسان.
            \u00a0\u00a0\u00a0\u00a0 تتميز منتجات Cashew Prawn Crackers من مركز المفرقعات الإندونيسي أيضًا بنظافتها المضمونة ومعايير الجودة. تم إنتاج هذه المقرمشات باستخدام عملية صحية وباستخدام مكونات طبيعية، وهي خيار آمن وصحي للوجبات الخفيفة يستمتع به جميع أفراد الأسرة. معبأة بعناية للحفاظ على النضارة والجودة، فإن مقرمشات الكاجو جاهزة لقضاء لحظات الاسترخاء في المنزل أو كرفيق مخلص في المناسبات مع الأصدقاء والعائلة. لذا، لا تتردد في اختيار مقرمشات الكاجو بالروبيان من مركز المقرمشات الإندونيسي لإغراء ذوقك بمذاق خاص.
            `,
            "image": Kerupuk51,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
        zh: {
            "nama": "班泰通心粉",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 印尼饼干中心推出的腰果虾饼干，将虾的美味与腰果的典型美味巧妙地结合在一起。 这些饼干采用优质原料制成，并采用成熟的技术加工，提供独特且令人满意的味觉体验。 松脆的口感和鲜美的口感，让每一口腰果虾片都充满诱惑。
            \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心的腰果虾饼干产品也因其清洁度和质量标准的保证而脱颖而出。 这些饼干采用卫生工艺和天然成分生产，是适合全家人享用的安全健康零食选择。 腰果虾饼经过精心包装，以保持新鲜度和品质，可让您在家中度过完美的放松时刻，或作为与朋友和家人一起参加活动的忠实伴侣。 所以，不要犹豫，选择印尼薄饼中心的腰果虾饼，以特殊的味道诱惑您的味蕾。
            `,
            "image": Kerupuk51,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
    },
    {
        id: {
            "nama": "Mawar Kuning",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Mede yang dihadirkan oleh Pusat Kerupuk Indonesia adalah kombinasi yang menarik antara cita rasa udang yang lezat dengan kelezatan kacang mede yang khas. Dibuat dari bahan-bahan berkualitas tinggi dan diproses dengan teknik yang teruji, kerupuk ini menghadirkan pengalaman rasa yang unik dan memuaskan. Teksturnya yang renyah dan cita rasanya yang gurih menjadikan setiap gigitan kerupuk Udang Mede sebagai sensasi yang menggoda lidah.
            \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Udang Mede dari Pusat Kerupuk Indonesia juga menonjol karena standar kebersihan dan kualitasnya yang terjamin. Diproduksi dengan proses yang higienis dan menggunakan bahan-bahan alami, kerupuk ini merupakan pilihan camilan yang aman dan sehat untuk dinikmati oleh seluruh keluarga. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk Udang Mede siap menyempurnakan momen santai Anda di rumah atau sebagai teman setia dalam acara bersama teman dan keluarga. Jadi, jangan ragu untuk memilih Kerupuk Udang Mede dari Pusat Kerupuk Indonesia untuk menggugah selera Anda dengan citarasa yang istimewa.
            `,
            "image": Kerupuk52,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
        en: {
            "nama": "Yellow Rose",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Cashew Shrimp Crackers presented by the Indonesian Crackers Center are an interesting combination of the delicious taste of shrimp with the typical deliciousness of cashew nuts. Made from high quality ingredients and processed using proven techniques, these crackers provide a unique and satisfying taste experience. The crunchy texture and savory taste make every bite of Cashew Prawn Crackers a tempting sensation on the tongue.
            \u00a0\u00a0\u00a0\u00a0 Cashew Prawn Crackers products from the Indonesian Crackers Center also stand out because of their guaranteed hygiene standards and quality. Produced using a hygienic process and using natural ingredients, these crackers are a safe and healthy snack choice for the whole family to enjoy. Packaged carefully to maintain freshness and quality, Cashew Shrimp crackers are ready to perfect your relaxing moments at home or as a loyal companion at events with friends and family. So, don't hesitate to choose Cashew Prawn Crackers from the Indonesian Crackers Center to tempt your taste buds with a special taste.
            `,
            "image": Kerupuk52,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
        ar: {
            "nama": "الوردة الصفراء",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 مقرمشات الجمبري بالكاجو المقدمة من مركز المفرقعات الإندونيسي هي مزيج مثير للاهتمام من المذاق اللذيذ للروبيان مع اللذة النموذجية لجوز الكاجو. مصنوعة من مكونات عالية الجودة وتم معالجتها باستخدام تقنيات مجربة، توفر هذه المقرمشات تجربة ذوق فريدة ومرضية. الملمس المقرمش والطعم اللذيذ يجعل كل قضمة من مقرمشات الكاجو الجمبري إحساسًا مغريًا على اللسان.
            \u00a0\u00a0\u00a0\u00a0 تتميز منتجات Cashew Prawn Crackers من مركز المفرقعات الإندونيسي أيضًا بنظافتها المضمونة ومعايير الجودة. تم إنتاج هذه المقرمشات باستخدام عملية صحية وباستخدام مكونات طبيعية، وهي خيار آمن وصحي للوجبات الخفيفة يستمتع به جميع أفراد الأسرة. معبأة بعناية للحفاظ على النضارة والجودة، فإن مقرمشات الكاجو جاهزة لقضاء لحظات الاسترخاء في المنزل أو كرفيق مخلص في المناسبات مع الأصدقاء والعائلة. لذا، لا تتردد في اختيار مقرمشات الكاجو بالروبيان من مركز المقرمشات الإندونيسي لإغراء ذوقك بمذاق خاص.
            `,
            "image": Kerupuk52,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
        zh: {
            "nama": "黄玫瑰",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 印尼饼干中心推出的腰果虾饼干，将虾的美味与腰果的典型美味巧妙地结合在一起。 这些饼干采用优质原料制成，并采用成熟的技术加工，提供独特且令人满意的味觉体验。 松脆的口感和鲜美的口感，让每一口腰果虾片都充满诱惑。
            \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心的腰果虾饼干产品也因其清洁度和质量标准的保证而脱颖而出。 这些饼干采用卫生工艺和天然成分生产，是适合全家人享用的安全健康零食选择。 腰果虾饼经过精心包装，以保持新鲜度和品质，可让您在家中度过完美的放松时刻，或作为与朋友和家人一起参加活动的忠实伴侣。 所以，不要犹豫，选择印尼薄饼中心的腰果虾饼，以特殊的味道诱惑您的味蕾。
            `,
            "image": Kerupuk52,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
    },
    {
        id: {
            "nama": "Mawar Seblak",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Mede yang dihadirkan oleh Pusat Kerupuk Indonesia adalah kombinasi yang menarik antara cita rasa udang yang lezat dengan kelezatan kacang mede yang khas. Dibuat dari bahan-bahan berkualitas tinggi dan diproses dengan teknik yang teruji, kerupuk ini menghadirkan pengalaman rasa yang unik dan memuaskan. Teksturnya yang renyah dan cita rasanya yang gurih menjadikan setiap gigitan kerupuk Udang Mede sebagai sensasi yang menggoda lidah.
            \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Udang Mede dari Pusat Kerupuk Indonesia juga menonjol karena standar kebersihan dan kualitasnya yang terjamin. Diproduksi dengan proses yang higienis dan menggunakan bahan-bahan alami, kerupuk ini merupakan pilihan camilan yang aman dan sehat untuk dinikmati oleh seluruh keluarga. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk Udang Mede siap menyempurnakan momen santai Anda di rumah atau sebagai teman setia dalam acara bersama teman dan keluarga. Jadi, jangan ragu untuk memilih Kerupuk Udang Mede dari Pusat Kerupuk Indonesia untuk menggugah selera Anda dengan citarasa yang istimewa.
            `,
            "image": Kerupuk53,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
        en: {
            "nama": "Rose Seblak",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 Cashew Shrimp Crackers presented by the Indonesian Crackers Center are an interesting combination of the delicious taste of shrimp with the typical deliciousness of cashew nuts. Made from high quality ingredients and processed using proven techniques, these crackers provide a unique and satisfying taste experience. The crunchy texture and savory taste make every bite of Cashew Prawn Crackers a tempting sensation on the tongue.
            \u00a0\u00a0\u00a0\u00a0 Cashew Prawn Crackers products from the Indonesian Crackers Center also stand out because of their guaranteed hygiene standards and quality. Produced using a hygienic process and using natural ingredients, these crackers are a safe and healthy snack choice for the whole family to enjoy. Packaged carefully to maintain freshness and quality, Cashew Shrimp crackers are ready to perfect your relaxing moments at home or as a loyal companion at events with friends and family. So, don't hesitate to choose Cashew Prawn Crackers from the Indonesian Crackers Center to tempt your taste buds with a special taste.
            `,
            "image": Kerupuk53,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
        ar: {
            "nama": "روز سيبلاك",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 مقرمشات الجمبري بالكاجو المقدمة من مركز المفرقعات الإندونيسي هي مزيج مثير للاهتمام من المذاق اللذيذ للروبيان مع اللذة النموذجية لجوز الكاجو. مصنوعة من مكونات عالية الجودة وتم معالجتها باستخدام تقنيات مجربة، توفر هذه المقرمشات تجربة ذوق فريدة ومرضية. الملمس المقرمش والطعم اللذيذ يجعل كل قضمة من مقرمشات الكاجو الجمبري إحساسًا مغريًا على اللسان.
            \u00a0\u00a0\u00a0\u00a0 تتميز منتجات Cashew Prawn Crackers من مركز المفرقعات الإندونيسي أيضًا بنظافتها المضمونة ومعايير الجودة. تم إنتاج هذه المقرمشات باستخدام عملية صحية وباستخدام مكونات طبيعية، وهي خيار آمن وصحي للوجبات الخفيفة يستمتع به جميع أفراد الأسرة. معبأة بعناية للحفاظ على النضارة والجودة، فإن مقرمشات الكاجو جاهزة لقضاء لحظات الاسترخاء في المنزل أو كرفيق مخلص في المناسبات مع الأصدقاء والعائلة. لذا، لا تتردد في اختيار مقرمشات الكاجو بالروبيان من مركز المقرمشات الإندونيسي لإغراء ذوقك بمذاق خاص.
            `,
            "image": Kerupuk53,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
        zh: {
            "nama": "罗丝·塞布拉克",
            "deskripsi": `
            \u00a0\u00a0\u00a0\u00a0 印尼饼干中心推出的腰果虾饼干，将虾的美味与腰果的典型美味巧妙地结合在一起。 这些饼干采用优质原料制成，并采用成熟的技术加工，提供独特且令人满意的味觉体验。 松脆的口感和鲜美的口感，让每一口腰果虾片都充满诱惑。
            \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心的腰果虾饼干产品也因其清洁度和质量标准的保证而脱颖而出。 这些饼干采用卫生工艺和天然成分生产，是适合全家人享用的安全健康零食选择。 腰果虾饼经过精心包装，以保持新鲜度和品质，可让您在家中度过完美的放松时刻，或作为与朋友和家人一起参加活动的忠实伴侣。 所以，不要犹豫，选择印尼薄饼中心的腰果虾饼，以特殊的味道诱惑您的味蕾。
            `,
            "image": Kerupuk53,
            "brand": "napoleon"
            // "kategori": "daging",
            // "ukuran": 150,
            // "brand": "gunung",
            // "versi": "baru"
        },
    },
     {
            id: {
                "nama": "Udang Mede",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Mede yang dihadirkan oleh Pusat Kerupuk Indonesia adalah kombinasi yang menarik antara cita rasa udang yang lezat dengan kelezatan kacang mede yang khas. Dibuat dari bahan-bahan berkualitas tinggi dan diproses dengan teknik yang teruji, kerupuk ini menghadirkan pengalaman rasa yang unik dan memuaskan. Teksturnya yang renyah dan cita rasanya yang gurih menjadikan setiap gigitan kerupuk Udang Mede sebagai sensasi yang menggoda lidah.
                \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Udang Mede dari Pusat Kerupuk Indonesia juga menonjol karena standar kebersihan dan kualitasnya yang terjamin. Diproduksi dengan proses yang higienis dan menggunakan bahan-bahan alami, kerupuk ini merupakan pilihan camilan yang aman dan sehat untuk dinikmati oleh seluruh keluarga. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk Udang Mede siap menyempurnakan momen santai Anda di rumah atau sebagai teman setia dalam acara bersama teman dan keluarga. Jadi, jangan ragu untuk memilih Kerupuk Udang Mede dari Pusat Kerupuk Indonesia untuk menggugah selera Anda dengan citarasa yang istimewa.
                `,
                "image": Kerupuk10,
                "brand": "napoleon",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Cashew Prawns",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cashew Shrimp Crackers presented by the Indonesian Crackers Center are an interesting combination of the delicious taste of shrimp with the typical deliciousness of cashew nuts. Made from high quality ingredients and processed using proven techniques, these crackers provide a unique and satisfying taste experience. The crunchy texture and savory taste make every bite of Cashew Prawn Crackers a tempting sensation on the tongue.
                \u00a0\u00a0\u00a0\u00a0 Cashew Prawn Crackers products from the Indonesian Crackers Center also stand out because of their guaranteed hygiene standards and quality. Produced using a hygienic process and using natural ingredients, these crackers are a safe and healthy snack choice for the whole family to enjoy. Packaged carefully to maintain freshness and quality, Cashew Shrimp crackers are ready to perfect your relaxing moments at home or as a loyal companion at events with friends and family. So, don't hesitate to choose Cashew Prawn Crackers from the Indonesian Crackers Center to tempt your taste buds with a special taste.
                `,
                "image": Kerupuk10,
                "brand": "napoleon",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "روبيان الكاجو",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات الجمبري بالكاجو المقدمة من مركز المفرقعات الإندونيسي هي مزيج مثير للاهتمام من المذاق اللذيذ للروبيان مع اللذة النموذجية لجوز الكاجو. مصنوعة من مكونات عالية الجودة وتم معالجتها باستخدام تقنيات مجربة، توفر هذه المقرمشات تجربة ذوق فريدة ومرضية. الملمس المقرمش والطعم اللذيذ يجعل كل قضمة من مقرمشات الكاجو الجمبري إحساسًا مغريًا على اللسان.
                \u00a0\u00a0\u00a0\u00a0 تتميز منتجات Cashew Prawn Crackers من مركز المفرقعات الإندونيسي أيضًا بنظافتها المضمونة ومعايير الجودة. تم إنتاج هذه المقرمشات باستخدام عملية صحية وباستخدام مكونات طبيعية، وهي خيار آمن وصحي للوجبات الخفيفة يستمتع به جميع أفراد الأسرة. معبأة بعناية للحفاظ على النضارة والجودة، فإن مقرمشات الكاجو جاهزة لقضاء لحظات الاسترخاء في المنزل أو كرفيق مخلص في المناسبات مع الأصدقاء والعائلة. لذا، لا تتردد في اختيار مقرمشات الكاجو بالروبيان من مركز المقرمشات الإندونيسي لإغراء ذوقك بمذاق خاص.
                `,
                "image": Kerupuk10,
                "brand": "napoleon",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "腰果虾",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 印尼饼干中心推出的腰果虾饼干，将虾的美味与腰果的典型美味巧妙地结合在一起。 这些饼干采用优质原料制成，并采用成熟的技术加工，提供独特且令人满意的味觉体验。 松脆的口感和鲜美的口感，让每一口腰果虾片都充满诱惑。
                \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心的腰果虾饼干产品也因其清洁度和质量标准的保证而脱颖而出。 这些饼干采用卫生工艺和天然成分生产，是适合全家人享用的安全健康零食选择。 腰果虾饼经过精心包装，以保持新鲜度和品质，可让您在家中度过完美的放松时刻，或作为与朋友和家人一起参加活动的忠实伴侣。 所以，不要犹豫，选择印尼薄饼中心的腰果虾饼，以特殊的味道诱惑您的味蕾。
                `,
                "image": Kerupuk10,
                "brand": "napoleon",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Udang Salju",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Mede yang dihadirkan oleh Pusat Kerupuk Indonesia adalah kombinasi yang menarik antara cita rasa udang yang lezat dengan kelezatan kacang mede yang khas. Dibuat dari bahan-bahan berkualitas tinggi dan diproses dengan teknik yang teruji, kerupuk ini menghadirkan pengalaman rasa yang unik dan memuaskan. Teksturnya yang renyah dan cita rasanya yang gurih menjadikan setiap gigitan kerupuk Udang Mede sebagai sensasi yang menggoda lidah.
                \u00a0\u00a0\u00a0\u00a0 Produk Kerupuk Udang Mede dari Pusat Kerupuk Indonesia juga menonjol karena standar kebersihan dan kualitasnya yang terjamin. Diproduksi dengan proses yang higienis dan menggunakan bahan-bahan alami, kerupuk ini merupakan pilihan camilan yang aman dan sehat untuk dinikmati oleh seluruh keluarga. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, kerupuk Udang Mede siap menyempurnakan momen santai Anda di rumah atau sebagai teman setia dalam acara bersama teman dan keluarga. Jadi, jangan ragu untuk memilih Kerupuk Udang Mede dari Pusat Kerupuk Indonesia untuk menggugah selera Anda dengan citarasa yang istimewa.
                `,
                "image": Kerupuk11,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Cashew Prawns",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cashew Shrimp Crackers presented by the Indonesian Crackers Center are an interesting combination of the delicious taste of shrimp with the typical deliciousness of cashew nuts. Made from high quality ingredients and processed using proven techniques, these crackers provide a unique and satisfying taste experience. The crunchy texture and savory taste make every bite of Cashew Prawn Crackers a tempting sensation on the tongue.
                \u00a0\u00a0\u00a0\u00a0 Cashew Prawn Crackers products from the Indonesian Crackers Center also stand out because of their guaranteed hygiene standards and quality. Produced using a hygienic process and using natural ingredients, these crackers are a safe and healthy snack choice for the whole family to enjoy. Packaged carefully to maintain freshness and quality, Cashew Shrimp crackers are ready to perfect your relaxing moments at home or as a loyal companion at events with friends and family. So, don't hesitate to choose Cashew Prawn Crackers from the Indonesian Crackers Center to tempt your taste buds with a special taste.
                `,
                "image": Kerupuk11,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "روبيان الكاجو",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات الجمبري بالكاجو المقدمة من مركز المفرقعات الإندونيسي هي مزيج مثير للاهتمام من المذاق اللذيذ للروبيان مع اللذة النموذجية لجوز الكاجو. مصنوعة من مكونات عالية الجودة وتم معالجتها باستخدام تقنيات مجربة، توفر هذه المقرمشات تجربة ذوق فريدة ومرضية. الملمس المقرمش والطعم اللذيذ يجعل كل قضمة من مقرمشات الكاجو الجمبري إحساسًا مغريًا على اللسان.
                \u00a0\u00a0\u00a0\u00a0 تتميز منتجات Cashew Prawn Crackers من مركز المفرقعات الإندونيسي أيضًا بنظافتها المضمونة ومعايير الجودة. تم إنتاج هذه المقرمشات باستخدام عملية صحية وباستخدام مكونات طبيعية، وهي خيار آمن وصحي للوجبات الخفيفة يستمتع به جميع أفراد الأسرة. معبأة بعناية للحفاظ على النضارة والجودة، فإن مقرمشات الكاجو جاهزة لقضاء لحظات الاسترخاء في المنزل أو كرفيق مخلص في المناسبات مع الأصدقاء والعائلة. لذا، لا تتردد في اختيار مقرمشات الكاجو بالروبيان من مركز المقرمشات الإندونيسي لإغراء ذوقك بمذاق خاص.
                `,
                "image": Kerupuk11,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "腰果虾",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 印尼饼干中心推出的腰果虾饼干，将虾的美味与腰果的典型美味巧妙地结合在一起。 这些饼干采用优质原料制成，并采用成熟的技术加工，提供独特且令人满意的味觉体验。 松脆的口感和鲜美的口感，让每一口腰果虾片都充满诱惑。
                \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心的腰果虾饼干产品也因其清洁度和质量标准的保证而脱颖而出。 这些饼干采用卫生工艺和天然成分生产，是适合全家人享用的安全健康零食选择。 腰果虾饼经过精心包装，以保持新鲜度和品质，可让您在家中度过完美的放松时刻，或作为与朋友和家人一起参加活动的忠实伴侣。 所以，不要犹豫，选择印尼薄饼中心的腰果虾饼，以特殊的味道诱惑您的味蕾。
                `,
                "image": Kerupuk11,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Mawar Kuning 2RD",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Dari dapur inovasi Pusat Kerupuk Indonesia, Mawar Kuning hadir sebagai simbol kecermatan dalam seni kuliner. Dibuat dengan detail yang cermat dan dipilih dari bahan-bahan terbaik, setiap lembar Mawar Kuning menawarkan kombinasi harmonis antara rasa autentik dan kelezatan yang tak tertandingi. Dengan bentuk yang menyerupai mawar kuning yang cantik, setiap gigitan memikat bukan hanya lidah tetapi juga mata, menciptakan pengalaman makan yang menyenangkan dan memuaskan.
                \u00a0\u00a0\u00a0\u00a0 Tersedia dalam kemasan yang elegan dan praktis, Mawar Kuning menjadi pilihan yang sempurna untuk melengkapi berbagai acara santap, mulai dari kumpul keluarga hingga pertemuan bersama teman-teman. Dengan setiap gigitannya, Mawar Kuning membawa Anda dalam petualangan rasa yang memikat dan menghadirkan keceriaan dalam setiap momen. Jelajahi kelezatan yang tak terlupakan dengan Mawar Kuning, hanya dari Pusat Kerupuk Indonesia.
                `,
                "image": Kerupuk12,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "2RD Yellow Rose",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 From the innovation kitchen of the Indonesian Cracker Center, Mawar Kuning appears as a symbol of precision in culinary arts. Crafted with meticulous detail and selected from the finest ingredients, each sheet of Yellow Rose offers a harmonious combination of authentic taste and unmatched deliciousness. With a shape that resembles a beautiful yellow rose, every bite captivates not only the taste buds but also the eyes, creating a pleasant and satisfying eating experience.
                \u00a0\u00a0\u00a0\u00a0 Available in elegant and practical packaging, Mawar Kuning is the perfect choice to complement various dining events, from family gatherings to gatherings with friends. With every bite, Mawar Kuning takes you on an enchanting taste adventure and brings joy to every moment. Explore unforgettable delights with Mawar Kuning, only from Pusat Kerupuk Indonesia.
                `,
                "image": Kerupuk12,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "2RD الوردة الصفراء",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 من مطبخ الابتكار التابع لمركز المفرقعات الإندونيسي، يظهر ماوار كونينج كرمز للدقة في فنون الطهي. مصنوعة بتفاصيل دقيقة ومختارة من أجود المكونات، كل ورقة من الوردة الصفراء تقدم مزيجًا متناغمًا من المذاق الأصيل واللذة التي لا مثيل لها. بشكله الذي يشبه الوردة الصفراء الجميلة، كل قضمة لا تأسر براعم التذوق فحسب، بل تأسر العيون أيضًا، مما يخلق تجربة تناول طعام ممتعة ومرضية.
                \u00a0\u00a0\u00a0\u00a0 متوفر في عبوات أنيقة وعملية، ماوار كونينج هو الخيار الأمثل لاستكمال مناسبات الطعام المختلفة، من التجمعات العائلية إلى التجمعات مع الأصدقاء. مع كل قضمة، يأخذك ماوار كونينج في مغامرة تذوق ساحرة ويجلب البهجة إلى كل لحظة. اكتشف المسرات التي لا تُنسى مع ماوار كونينج، فقط من بوسات كيروبوك إندونيسيا.
                `,
                "image": Kerupuk12,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "2RD 黄玫瑰",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 来自印度尼西亚饼干中心的创新厨房，Mawar Kuning 是烹饪艺术精准的象征。 每片黄玫瑰均经过精心制作，精选最优质的原料，将正宗的味道与无与伦比的美味和谐地结合在一起。 外形酷似美丽的黄玫瑰，每一口都吸引着味蕾和眼睛，营造出愉悦满足的用餐体验。
                \u00a0\u00a0\u00a0\u00a0 Mawar Kuning 采用优雅实用的包装，是搭配从家庭聚会到朋友聚会等各种用餐活动的​​完美选择。 每一口，Mawar Kuning 都会带您踏上一场迷人的味觉冒险，为每一刻带来欢乐。 与来自印尼 Pusat Kerupuk 的 Mawar Kuning 一起探索令人难忘的美食。
                `,
                "image": Kerupuk12,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        // {
        //     id: {
        //         "nama": "Mawar Putih",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Dari kapal menuju meja Anda, Mawar Putih dengan merek Cap Kapal adalah persembahan istimewa dari Pusat Kerupuk Indonesia. Dibuat dengan keahlian yang tak tertandingi, setiap lembar Mawar Putih menggabungkan kelezatan yang autentik dengan cita rasa yang tiada duanya. Dengan tekstur yang lembut namun renyah, setiap gigitan adalah perjalanan yang memikat bagi indera Anda. Keistimewaan Mawar Putih terletak pada kesempurnaan rasa dan kelembutan, menjadi tambahan yang tak tergantikan untuk setiap santapan.
        //         \u00a0\u00a0\u00a0\u00a0 Tersedia dalam kemasan yang menarik dan praktis, Mawar Putih dengan brand Cap Kapal adalah pilihan yang tak bisa Anda lewatkan untuk memperkaya momen santap Anda. Dari makan malam bersama keluarga hingga pesta bersama teman-teman, Mawar Putih akan menjadi bintang yang menyenangkan. Jelajahi kelezatan yang tiada tara dengan Mawar Putih, eksklusif dari Pusat Kerupuk Indonesia.
        //         `,
        //         "image": Kerupuk12,
        //         "brand": "kapal"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        //     en: {
        //         "nama": "White roses",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 From the ship to your table, Mawar Putih with the Cap Kapal brand is a special offering from Pusat Kerupuk Indonesia. Crafted with unrivaled craftsmanship, each sheet of White Rose combines authentic deliciousness with unmatched taste. With a soft yet crunchy texture, every bite is an enchanting journey for your senses. The specialty of White Rose lies in its perfection of taste and softness, making it an irreplaceable addition to every meal.
        //         \u00a0\u00a0\u00a0\u00a0 Available in attractive and practical packaging, Mawar Putih with the Cap Kapal brand is a choice you can't miss to enrich your dining moments. From family dinners to parties with friends, White Rose will be the star of the fun. Explore incomparable deliciousness with Mawar Putih, exclusively from Pusat Kerupuk Indonesia.
        //         `,
        //         "image": Kerupuk13,
        //         "brand": "kapal"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        //     ar: {
        //         "nama": "الورود البيضاء",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 من السفينة إلى طاولتك، يعد Mawar Putih مع العلامة التجارية Cap Kapal عرضًا خاصًا من Pusat Kerupuk Indonesia. مصنوعة بحرفية لا مثيل لها، كل ورقة من الورد الأبيض تجمع بين المذاق الأصيل والطعم الذي لا مثيل له. مع ملمس ناعم ومقرمش، كل قضمة هي رحلة ساحرة لحواسك. تكمن خصوصية الوردة البيضاء في كمال مذاقها ونعومتها، مما يجعلها إضافة لا يمكن الاستغناء عنها لكل وجبة.
        //         \u00a0\u00a0\u00a0\u00a0 متوفر في عبوات جذابة وعملية، يعد Mawar Putih مع العلامة التجارية Cap Kapal خيارًا لا يمكنك تفويته لإثراء لحظات تناول الطعام الخاصة بك. من العشاء العائلي إلى الحفلات مع الأصدقاء، ستكون وايت روز هي نجمة المرح. اكتشف المذاق الذي لا يضاهى مع ماوار بوتيه، حصريًا من بوسات كيروبوك إندونيسيا.
        //         `,
        //         "image": Kerupuk13,
        //         "brand": "kapal"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        //     zh: {
        //         "nama": "白玫瑰",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 从船上到您的餐桌，带有 Cap Kapal 品牌的 Mawar Putih 是 Pusat Kerupuk Indonesia 的特别产品。 每片白玫瑰均采用无与伦比的工艺精制而成，融合了正宗的美味和无与伦比的味道。 质地柔软而松脆，每一口都是一次迷人的感官之旅。 白玫瑰的特色在于其完美的口感和柔软度，使其成为每餐中不可替代的佐料。
        //         \u00a0\u00a0\u00a0\u00a0 Cap Kapal 品牌的 Mawar Putih 包装精美且实用，是您丰富用餐时刻的不容错过的选择。 从家庭聚餐到朋友聚会，白玫瑰将成为欢乐之星。 与来自印度尼西亚 Pusat Kerupuk 的 Mawar Putih 一起探索无与伦比的美味。
        //         `,
        //         "image": Kerupuk13,
        //         "brand": "kapal"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        // },
        {
            id: {
                "nama": "Sisir Warna",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Sisir Warna adalah keajaiban camilan yang ditawarkan oleh Pusat Kerupuk Indonesia. Dengan warna-warni yang menarik dan rasa yang luar biasa, setiap gigitan Sisir Warna menghadirkan petualangan rasa yang tak terlupakan. Dibuat dengan teliti menggunakan bahan-bahan berkualitas, setiap lembar Sisir Warna menggabungkan kelezatan dan keunikan dalam setiap sajian. Keistimewaan produk ini terletak pada bentuknya yang menarik dan warna-warni yang menggoda, memberikan sensasi visual yang menyenangkan saat dinikmati.
                \u00a0\u00a0\u00a0\u00a0 Tersedia dalam kemasan yang praktis dan menawan, Sisir Warna adalah pilihan ideal untuk melengkapi acara santap Anda, baik itu sebagai camilan ringan atau sebagai bagian dari hidangan utama. Dengan setiap gigitannya, Sisir Warna akan membawa Anda dalam perjalanan rasa yang menggugah selera dan menghadirkan keceriaan dalam setiap momen bersama keluarga dan teman-teman. Jelajahi kelezatan dan warna-warni kehidupan dengan Sisir Warna, hanya dari Pusat Kerupuk Indonesia.
                `,
                "image": Kerupuk14,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Color Comb",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Sisir Warna is a snack wonder offered by Pusat Kerupuk Indonesia. With attractive colors and extraordinary taste, every bite of Comb Warna presents an unforgettable taste adventure. Carefully made using quality ingredients, each sheet of Sisir Warna combines deliciousness and uniqueness in every dish. The specialty of this product lies in its attractive shape and tempting colors, providing a pleasant visual sensation when enjoyed.
                \u00a0\u00a0\u00a0\u00a0 Available in practical and attractive packaging, Color Combs are the ideal choice to complement your meal, whether as a light snack or as part of a main course. With every bite, Sisir Warna will take you on a journey of appetizing flavors and bring joy to every moment with family and friends. Explore the delights and colors of life with Sisir Warna, only from Pusat Kerupuk Indonesia.
                `,
                "image": Kerupuk14,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "مشط اللون",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 سيسير وارنا هو أحد العجائب التي يقدمها مطعم Pusat Kerupuk Indonesia. مع الألوان الجذابة والطعم الاستثنائي، تقدم كل قضمة من كومب وارنا مغامرة لا تنسى. تم إعداد كل شريحة من سيسير وارنا بعناية باستخدام مكونات عالية الجودة، وتجمع بين الطعم اللذيذ والتفرد في كل طبق. تكمن خصوصية هذا المنتج في شكله الجذاب وألوانه المغرية، مما يوفر إحساسًا بصريًا ممتعًا عند الاستمتاع به.
                \u00a0\u00a0\u00a0\u00a0 متوفرة في عبوات عملية وجذابة، تعد Color Combs الخيار الأمثل لاستكمال وجبتك، سواء كوجبة خفيفة أو كجزء من الطبق الرئيسي. مع كل قضمة، سيأخذك سيسير ورنا في رحلة من النكهات اللذيذة ويضفي البهجة على كل لحظة مع العائلة والأصدقاء. اكتشف متعة وألوان الحياة مع سيسير وارنا، فقط من Pusat Kerupuk Indonesia.
                `,
                "image": Kerupuk14,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "彩色梳子",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Sisir Warna 是印尼 Pusat Kerupuk 提供的零食奇迹。 迷人的色彩和非凡的口感，Comb Warna 的每一口都呈现出令人难忘的味觉冒险。 Sisir Warna 每片均采用优质原料精心制作，每道菜都融合了美味和独特性。 该产品的特点在于其诱人的造型和诱人的色彩，给人一种赏心悦目的视觉感受。
                \u00a0\u00a0\u00a0\u00a0 彩色梳子采用实用且美观的包装，无论是作为小吃还是作为主菜的一部分，都是搭配餐点的理想选择。 每一口，Sisir Warna 都会带您踏上美味之旅，为与家人和朋友在一起的每一刻带来欢乐。 与印度尼西亚 Pusat Kerupuk 独有的 Sisir Warna 一起探索生活的乐趣和色彩。
                `,
                "image": Kerupuk14,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Tersanjung Warna",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Dari Pusat Kerupuk Indonesia, hadirlah Tersanjung Warna dengan merek Cap Kapal Layar, sebuah inovasi yang menggabungkan keunikan dan kelezatan dalam sebuah camilan. Dengan kualitas terbaik yang dijamin, setiap lembar Tersanjung Warna menghadirkan pengalaman rasa yang tak terlupakan dengan perpaduan warna-warni yang menarik. Produk ini diciptakan dengan teliti menggunakan bahan-bahan berkualitas tinggi, memberikan cita rasa yang autentik dan tekstur yang renyah.
                \u00a0\u00a0\u00a0\u00a0 Tersedia dalam kemasan yang menarik dan praktis, Tersanjung Warna dengan brand Cap Kapal Layar adalah pilihan sempurna untuk menambah keceriaan dalam setiap momen santap Anda. Baik sebagai camilan ringan di antara waktu istirahat atau sebagai bagian dari hidangan utama dalam acara keluarga, Tersanjung Warna akan membawa kesenangan dan kelezatan yang tak terbantahkan. Nikmati keindahan warna dan cita rasa yang istimewa dengan Tersanjung Warna, eksklusif dari Pusat Kerupuk Indonesia.
                `,
                "image": Kerupuk15,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Flattered Colors",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 From the Indonesian Cracker Center, comes Tersanjung Warna with the Cap Kapal Layar brand, an innovation that combines uniqueness and deliciousness in a snack. With the best quality guaranteed, each sheet of Tersanjung Warna presents an unforgettable taste experience with an attractive combination of colors. This product is carefully created using high-quality ingredients, providing an authentic taste and crunchy texture.
                \u00a0\u00a0\u00a0\u00a0 Available in attractive and practical packaging, Tersanjung Warna with the Cap Kapal Layar brand is the perfect choice to add joy to every moment of your meal. Whether as a light snack between breaks or as part of the main course at a family event, Terbaru Warna will bring undeniable pleasure and deliciousness. Enjoy the beauty of color and special taste with Tersanjung Warna, exclusively from the Indonesian Cracker Center.
                `,
                "image": Kerupuk15,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "الألوان المبهجة",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 من مركز المفرقعات الإندونيسي، يأتي Tersanjung Warna مع العلامة التجارية Cap Kapal Layar، وهو ابتكار يجمع بين التفرد واللذة في وجبة خفيفة. مع ضمان أفضل جودة، تقدم كل ورقة من Tersanjung Warna تجربة ذوق لا تُنسى مع مزيج جذاب من الألوان. تم صنع هذا المنتج بعناية باستخدام مكونات عالية الجودة، مما يوفر طعمًا أصيلًا وملمسًا مقرمشًا.
                \u00a0\u00a0\u00a0\u00a0 متوفر في عبوات جذابة وعملية، يُعد Tersanjung Warna مع العلامة التجارية Cap Kapal Layar الخيار الأمثل لإضافة الفرح إلى كل لحظة من وجبتك. سواء أكان ذلك كوجبة خفيفة بين فترات الراحة أو كجزء من الطبق الرئيسي في مناسبة عائلية، فإن تيربارو وارنا سيجلب المتعة واللذة التي لا يمكن إنكارها. استمتع بجمال اللون والطعم الخاص مع تيرسانجونج وارنا حصريا من مركز التكسير الإندونيسي.
                `,
                "image": Kerupuk15,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "受宠若惊的颜色",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 来自印度尼西亚饼干中心的 Tersanjung Warna 推出了 Cap Kapal Layar 品牌，这是一种将独特性与美味结合在一起的创新零食。 凭借最佳品质的保证，每片 Tersanjung Warna 都以迷人的色彩组合呈现令人难忘的味觉体验。 该产品采用优质原料精心制作，味道正宗，质地松脆。
                \u00a0\u00a0\u00a0\u00a0 Tersanjung Warna 采用 Cap Kapal Layar 品牌，采用精美实用的包装，是为您用餐的每一刻增添欢乐的完美选择。 无论是作为休息间隙的小吃，还是作为家庭活动中主菜的一部分，Terbaru Warna 都会带来不可否认的乐趣和美味。 享受来自印度尼西亚饼干中心独家的 Tersanjung Warna 的美丽色彩和特殊口味。
                `,
                "image": Kerupuk15,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Udang Mas",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Mas dengan brand Cap Kapal Layar merupakan simbol kualitas terbaik dari Pusat Kerupuk Indonesia. Dibuat dengan teliti menggunakan udang pilihan dan rempah-rempah berkualitas tinggi, setiap kerupuk adalah manifestasi dari keahlian tradisional yang dipadukan dengan inovasi modern. Dengan setiap gigitan, Anda akan merasakan harmoni tekstur renyah yang memikat dan rasa udang yang khas, menjadikan pengalaman menyantapnya begitu memuaskan.
                \u00a0\u00a0\u00a0\u00a0 Tak hanya sekadar camilan, Kerupuk Udang Mas dengan brand Cap Kapal Layar adalah perpaduan sempurna antara rasa, kualitas, dan kelezatan yang memenuhi standar tertinggi. Kemasan yang menarik dan kebersihan yang terjaga menjadikan produk ini menjadi pilihan utama untuk dinikmati bersama keluarga, teman, atau sebagai pelengkap dalam acara khusus. Dengan Cap Kapal Layar, setiap momen menjadi lebih istimewa dengan kelezatan yang tak terlupakan.
                `,
                "image": Kerupuk16,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Shrimp Mas",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Udang Mas Crackers with the Cap Kapal Layar brand are a symbol of the best quality from the Indonesian Cracker Center. Carefully crafted using selected shrimp and high-quality spices, each cracker is a manifestation of traditional craftsmanship combined with modern innovation. With every bite, you will feel the harmony of the attractive crunchy texture and the distinctive taste of shrimp, making the eating experience very satisfying.
                \u00a0\u00a0\u00a0\u00a0 Not just a snack, Mas Prawn Crackers with the Cap Kapal Layar brand are the perfect combination of taste, quality and deliciousness that meet the highest standards. Attractive packaging and maintained cleanliness make this product the main choice for enjoying with family, friends, or as a complement to special events. With the Sailing Cap, every moment becomes more special with unforgettable deliciousness.
                `,
                "image": Kerupuk16,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "جمبري ماس",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعد مقرمشات Udang Mas التي تحمل العلامة التجارية Cap Kapal Layar رمزًا لأفضل جودة من مركز المفرقعات الإندونيسي. تم تصنيع كل قطعة بسكويت بعناية باستخدام الروبيان المختار والتوابل عالية الجودة، وهي تجسيد للحرفية التقليدية جنبًا إلى جنب مع الابتكار الحديث. مع كل قضمة، ستشعر بتناغم القوام المقرمش الجذاب والطعم المميز للجمبري، مما يجعل تجربة تناول الطعام مرضية للغاية.
                \u00a0\u00a0\u00a0\u00a0 ليست مجرد وجبة خفيفة، بل إن مقرمشات ماس ​​الجمبري التي تحمل العلامة التجارية Cap Kapal Layar هي مزيج مثالي من المذاق والجودة واللذة التي تلبي أعلى المعايير. التغليف الجذاب والنظافة المستمرة تجعل هذا المنتج الخيار الرئيسي للاستمتاع مع العائلة أو الأصدقاء أو كمكمل للمناسبات الخاصة. مع قبعة الإبحار، تصبح كل لحظة أكثر خصوصية مع لذة لا تُنسى.
                `,
                "image": Kerupuk16,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "虾马斯",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 带有 Cap Kapal Layar 品牌的 Udang Mas 饼干是印度尼西亚饼干中心最佳品质的象征。 每块饼干均采用精选虾和优质香料精心制作，是传统工艺与现代创新相结合的体现。 每一口，你都会感受到诱人的脆脆口感与虾独特的味道的和谐，让用餐体验非常满足。
                \u00a0\u00a0\u00a0\u00a0 Cap Kapal Layar 品牌的 Mas 虾饼不仅仅是一种零食，它是口味、品质和美味的完美结合，符合最高标准。 精美的包装和保持的清洁度使该产品成为与家人、朋友一起享用或作为特殊活动的补充的主要选择。 有了帆船帽，每一刻都变得更加特别，带来难忘的美味。
                `,
                "image": Kerupuk16,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Udang Mini Orange",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Udang Mini Orange adalah salah satu varian kerupuk yang menawarkan cita rasa yang unik dan memikat. Dibuat dengan teliti menggunakan bahan-bahan pilihan berkualitas tinggi, kerupuk ini menggabungkan kelezatan udang dengan sentuhan rempah yang pas. Setiap gigitan menghadirkan sensasi renyah yang memanjakan lidah, sementara aroma udangnya memikat selera. Diracik dengan hati-hati dan diproses dengan teknologi modern, Udang Mini Orange menjadi pilihan camilan yang sempurna untuk menemani momen santai Anda.
                \u00a0\u00a0\u00a0\u00a0 Selain sebagai camilan, Udang Mini Orange juga merupakan tambahan yang sempurna untuk menyempurnakan hidangan Anda. Dengan teksturnya yang renyah dan cita rasanya yang khas, kerupuk ini dapat menjadi penghias meja yang menarik di berbagai acara. Nikmati kelezatan setiap gigitannya, dan biarkan Udang Mini Orange dari Pusat Kerupuk Indonesia menjadi bagian tak terpisahkan dari pengalaman kuliner Anda.
                `,
                "image": Kerupuk17,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Mini Orange Shrimp",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Mini Orange Shrimp is a cracker variant that offers a unique and attractive taste. Carefully made using selected high-quality ingredients, these crackers combine the deliciousness of shrimp with just the right touch of spice. Every bite presents a crunchy sensation that pampers the tongue, while the aroma of the shrimp captivates the taste buds. Carefully prepared and processed with modern technology, Mini Orange Shrimp is the perfect snack choice to accompany your relaxing moments.
                \u00a0\u00a0\u00a0\u00a0 Apart from being a snack, Mini Orange Shrimp are also the perfect addition to enhance your meal. With their crunchy texture and distinctive taste, these crackers can be an attractive table decoration at various events. Enjoy the deliciousness of every bite, and let the Mini Orange Shrimp from Pusat Kerupuk Indonesia become an inseparable part of your culinary experience.
                `,
                "image": Kerupuk17,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "ميني روبيان برتقالي",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Mini Orange Shrimp هو أحد أنواع البسكويت الذي يقدم طعمًا فريدًا وجذابًا. تم صنع هذه المقرمشات بعناية باستخدام مكونات مختارة عالية الجودة، وتجمع بين نكهة الروبيان اللذيذة مع اللمسة المناسبة من التوابل. تقدم كل قضمة إحساسًا مقرمشًا يدلل اللسان، بينما تأسر رائحة الروبيان براعم التذوق. يعد Mini Orange Shrimp الذي تم إعداده ومعالجته بعناية باستخدام التكنولوجيا الحديثة، خيارًا مثاليًا للوجبات الخفيفة لمرافقة لحظات الاسترخاء الخاصة بك.
                \u00a0\u00a0\u00a0\u00a0 بصرف النظر عن كونه وجبة خفيفة، يعد Mini Orange Shrimp أيضًا إضافة مثالية لتعزيز وجبتك. بفضل قوامها المقرمش وطعمها المميز، يمكن أن تكون هذه المقرمشات زينة جذابة للمائدة في مختلف المناسبات. استمتع بلذة كل قضمة، ودع Mini Orange Shrimp من Pusat Kerupuk Indonesia يصبح جزءًا لا يتجزأ من تجربة الطهي الخاصة بك.
                `,
                "image": Kerupuk17,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "迷你橙虾",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 迷你橙虾是一种饼干变种，具有独特而诱人的味道。 这些饼干采用精选的优质原料精心制作，将虾的美味与恰到好处的香料融为一体。 每一口都呈现出脆脆的感觉，呵护舌头，而虾的香气则吸引着味蕾。 迷你橙虾采用现代技术精心准备和加工，是陪伴您放松时刻的完美零食选择。
                \u00a0\u00a0\u00a0\u00a0 除了作为零食外，迷你橙虾也是为您的餐点增光添彩的完美补充。 这些饼干质地松脆，味道独特，可以成为各种活动中引人注目的餐桌装饰。 享受每一口的美味，让印度尼西亚 Pusat Kerupuk 的迷你橙虾成为您的烹饪体验中不可分割的一部分。
                `,
                "image": Kerupuk17,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Udang Mini Putih",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Udang Mini Putih adalah pilihan camilan yang istimewa untuk memuaskan selera Anda. Dibuat dengan teliti menggunakan bahan-bahan berkualitas tinggi, kerupuk ini menghadirkan sensasi renyah yang memikat setiap gigitannya. Dengan citarasa udang yang autentik dan aroma yang menggugah selera, Udang Mini Putih menjadi pilihan yang sempurna untuk menemani momen santai Anda.
                \u00a0\u00a0\u00a0\u00a0 Selain sebagai camilan, Udang Mini Putih juga merupakan tambahan yang ideal untuk menyempurnakan hidangan Anda. Dengan keunikan bentuknya dan cita rasanya yang lezat, kerupuk ini dapat menjadi penyempurna setiap hidangan. Hadirkan Udang Mini Putih dari Pusat Kerupuk Indonesia di meja makan Anda, dan nikmati pengalaman kuliner yang tak terlupakan bersama keluarga dan teman-teman.
                `,
                "image": Kerupuk18,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Mini Orange Shrimp",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Mini White Shrimp is a special snack choice to satisfy your taste buds. Carefully made using high-quality ingredients, these crackers provide a crunchy sensation that appeals to every bite. With its authentic shrimp taste and appetizing aroma, Mini White Shrimp is the perfect choice to accompany your relaxing moments.
                \u00a0\u00a0\u00a0\u00a0 Apart from being a snack, White Mini Prawns are also an ideal addition to perfect your meal. With their unique shape and delicious taste, these crackers can perfect every meal. Present White Mini Shrimp from Pusat Kerupuk Indonesia on your dining table, and enjoy an unforgettable culinary experience with family and friends.
                `,
                "image": Kerupuk18,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "ميني روبيان برتقالي",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 يعد Mini White Shrimp خيارًا خاصًا للوجبات الخفيفة لإرضاء ذوقك. تم صنع هذه المقرمشات بعناية باستخدام مكونات عالية الجودة، مما يوفر إحساسًا مقرمشًا يروق لكل قضمة. بفضل طعم الروبيان الأصيل ورائحته الشهية، يعد Mini White Shrimp الخيار الأمثل لمرافقة لحظات الاسترخاء الخاصة بك.
                \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن كونه وجبة خفيفة، فإن الروبيان الأبيض الصغير يعد أيضًا إضافة مثالية لوجبة مثالية. بفضل شكلها الفريد وطعمها اللذيذ، يمكن لهذه المقرمشات أن تكون مثالية لكل وجبة. قم بتقديم White Mini Shrimp من Pusat Kerupuk Indonesia على طاولة طعامك، واستمتع بتجربة طهي لا تُنسى مع العائلة والأصدقاء.
                `,
                "image": Kerupuk18,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "迷你橙虾",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 迷你白虾是满足您味蕾的特色小吃选择。 这些饼干采用优质原料精心制作，每一口都带来松脆的感觉。 迷你白虾以其正宗的虾味和开胃的香气，是陪伴您放松时刻的完美选择。
                \u00a0\u00a0\u00a0\u00a0 除了作为零食外，白小虾也是完美餐点的理想补充。 这些饼干凭借其独特的形状和美味的味道，可以使每顿饭变得完美。 将印尼 Pusat Kerupuk 的小白虾呈现在您的餐桌上，与家人和朋友一起享受难忘的美食体验。
                `,
                "image": Kerupuk18,
                "brand": "kapal"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Rantai Putih Sedang Rasa",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna adalah inovasi terbaru dari Shinjuku dalam dunia kerupuk yang memadukan rasa dan tampilan yang menakjubkan. Setiap gigitan dari kerupuk ini membawa pengalaman yang tak terlupakan, dengan rasa yang kaya dan tekstur yang renyah. Dengan cita rasa yang unik dan beragam, Kasandra Warna menawarkan kombinasi bahan-bahan berkualitas tinggi yang dipilih secara teliti untuk menciptakan sensasi lezat yang memanjakan lidah Anda.
                \u00a0\u00a0\u00a0\u00a0 Dibalut dengan warna-warna cerah dan menarik, Kasandra Warna tidak hanya menggugah selera Anda, tetapi juga mata Anda. Dengan kemasan yang menawan dan desain yang elegan, setiap kemasan Kasandra Warna adalah karya seni yang memikat untuk dinikmati dalam setiap kesempatan. Tak hanya sekadar makanan ringan, Kasandra Warna adalah simbol gaya hidup yang menunjukkan keberanian dan keunikan dalam mengekspresikan diri Anda.
                `,
                "image": Kerupuk49,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "White Chain Medium Flavor",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna is the latest innovation from Shinjuku in the world of crackers which combines amazing taste and appearance. Every bite of these crackers brings an unforgettable experience, with a rich taste and crunchy texture. With unique and diverse flavors, Kasandra Warna offers a combination of high-quality ingredients that are carefully selected to create a delicious sensation that pampers your taste buds.
                \u00a0\u00a0\u00a0\u00a0 Wrapped in bright and attractive colors, Kasandra Warna not only arouses your taste, but also your eyes. With charming packaging and elegant design, every Kasandra Warna packaging is an attractive work of art to be enjoyed on every occasion. Not just a snack, Kasandra Warna is a lifestyle symbol that shows courage and uniqueness in expressing yourself.
                `,
                "image": Kerupuk49,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "سلسلة بيضاء نكهة متوسطة",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 كاساندرا وارنا هي أحدث ابتكارات شينجوكو في عالم المقرمشات التي تجمع بين الطعم والمظهر الرائع. كل قضمة من هذه البسكويت تجلب لك تجربة لا تنسى، مع طعم غني وملمس مقرمش. بنكهات فريدة ومتنوعة، يقدم كاساندرا وارنا مزيجًا من المكونات عالية الجودة التي تم اختيارها بعناية لخلق إحساس لذيذ يدلل ذوقك.
                \u00a0\u00a0\u00a0\u00a0 مغلفة بألوان زاهية وجذابة، كاساندرا وارنا لا تثير ذوقك فحسب، بل تثير عينيك أيضًا. بفضل التغليف الساحر والتصميم الأنيق، تعد كل عبوة من عبوات Kasandra Warna عملاً فنيًا جذابًا للاستمتاع به في كل مناسبة. ليست مجرد وجبة خفيفة، بل تعد كاساندرا وارنا رمزًا لأسلوب الحياة الذي يظهر الشجاعة والتفرد في التعبير عن نفسك.
                `,
                "image": Kerupuk49,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "白链中味",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna 是新宿在饼干领域的最新创新，它结合了令人惊叹的味道和外观。 这些饼干的每一口都会带来难忘的体验，口感丰富，质地松脆。 Kasandra Warna 口味独特多样，提供精心挑选的优质原料组合，创造出满足您味蕾的美味感觉。
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna 被明亮而迷人的色彩包裹着，不仅能激发您的品味，还能激发您的眼睛。 凭借迷人的包装和优雅的设计，每一款 Kasandra Warna 包装都是一件迷人的艺术品，适合在任何场合欣赏。 Kasandra Warna 不仅仅是一种零食，它还是一种生活方式的象征，在表达自我时展现出勇气和独特性。
                `,
                "image": Kerupuk49,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Rantai Jumbo Warna",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna adalah inovasi terbaru dari Shinjuku dalam dunia kerupuk yang memadukan rasa dan tampilan yang menakjubkan. Setiap gigitan dari kerupuk ini membawa pengalaman yang tak terlupakan, dengan rasa yang kaya dan tekstur yang renyah. Dengan cita rasa yang unik dan beragam, Kasandra Warna menawarkan kombinasi bahan-bahan berkualitas tinggi yang dipilih secara teliti untuk menciptakan sensasi lezat yang memanjakan lidah Anda.
                \u00a0\u00a0\u00a0\u00a0 Dibalut dengan warna-warna cerah dan menarik, Kasandra Warna tidak hanya menggugah selera Anda, tetapi juga mata Anda. Dengan kemasan yang menawan dan desain yang elegan, setiap kemasan Kasandra Warna adalah karya seni yang memikat untuk dinikmati dalam setiap kesempatan. Tak hanya sekadar makanan ringan, Kasandra Warna adalah simbol gaya hidup yang menunjukkan keberanian dan keunikan dalam mengekspresikan diri Anda.
                `,
                "image": Kerupuk47,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Color Jumbo Chain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna is the latest innovation from Shinjuku in the world of crackers which combines amazing taste and appearance. Every bite of these crackers brings an unforgettable experience, with a rich taste and crunchy texture. With unique and diverse flavors, Kasandra Warna offers a combination of high-quality ingredients that are carefully selected to create a delicious sensation that pampers your taste buds.
                \u00a0\u00a0\u00a0\u00a0 Wrapped in bright and attractive colors, Kasandra Warna not only arouses your taste, but also your eyes. With charming packaging and elegant design, every Kasandra Warna packaging is an attractive work of art to be enjoyed on every occasion. Not just a snack, Kasandra Warna is a lifestyle symbol that shows courage and uniqueness in expressing yourself.
                `,
                "image": Kerupuk47,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "سلسلة جامبو ملونة",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 كاساندرا وارنا هي أحدث ابتكارات شينجوكو في عالم المقرمشات التي تجمع بين الطعم والمظهر الرائع. كل قضمة من هذه البسكويت تجلب لك تجربة لا تنسى، مع طعم غني وملمس مقرمش. بنكهات فريدة ومتنوعة، يقدم كاساندرا وارنا مزيجًا من المكونات عالية الجودة التي تم اختيارها بعناية لخلق إحساس لذيذ يدلل ذوقك.
                \u00a0\u00a0\u00a0\u00a0 مغلفة بألوان زاهية وجذابة، كاساندرا وارنا لا تثير ذوقك فحسب، بل تثير عينيك أيضًا. بفضل التغليف الساحر والتصميم الأنيق، تعد كل عبوة من عبوات Kasandra Warna عملاً فنيًا جذابًا للاستمتاع به في كل مناسبة. ليست مجرد وجبة خفيفة، بل تعد كاساندرا وارنا رمزًا لأسلوب الحياة الذي يظهر الشجاعة والتفرد في التعبير عن نفسك.
                `,
                "image": Kerupuk47,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "彩色巨链",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna 是新宿在饼干领域的最新创新，它结合了令人惊叹的味道和外观。 这些饼干的每一口都会带来难忘的体验，口感丰富，质地松脆。 Kasandra Warna 口味独特多样，提供精心挑选的优质原料组合，创造出满足您味蕾的美味感觉。
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna 被明亮而迷人的色彩包裹着，不仅能激发您的品味，还能激发您的眼睛。 凭借迷人的包装和优雅的设计，每一款 Kasandra Warna 包装都是一件迷人的艺术品，适合在任何场合欣赏。 Kasandra Warna 不仅仅是一种零食，它还是一种生活方式的象征，在表达自我时展现出勇气和独特性。
                `,
                "image": Kerupuk47,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Kasandra Warna",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna adalah inovasi terbaru dari Shinjuku dalam dunia kerupuk yang memadukan rasa dan tampilan yang menakjubkan. Setiap gigitan dari kerupuk ini membawa pengalaman yang tak terlupakan, dengan rasa yang kaya dan tekstur yang renyah. Dengan cita rasa yang unik dan beragam, Kasandra Warna menawarkan kombinasi bahan-bahan berkualitas tinggi yang dipilih secara teliti untuk menciptakan sensasi lezat yang memanjakan lidah Anda.
                \u00a0\u00a0\u00a0\u00a0 Dibalut dengan warna-warna cerah dan menarik, Kasandra Warna tidak hanya menggugah selera Anda, tetapi juga mata Anda. Dengan kemasan yang menawan dan desain yang elegan, setiap kemasan Kasandra Warna adalah karya seni yang memikat untuk dinikmati dalam setiap kesempatan. Tak hanya sekadar makanan ringan, Kasandra Warna adalah simbol gaya hidup yang menunjukkan keberanian dan keunikan dalam mengekspresikan diri Anda.
                `,
                "image": Kerupuk19,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Cassandra Color",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna is the latest innovation from Shinjuku in the world of crackers which combines amazing taste and appearance. Every bite of these crackers brings an unforgettable experience, with a rich taste and crunchy texture. With unique and diverse flavors, Kasandra Warna offers a combination of high-quality ingredients that are carefully selected to create a delicious sensation that pampers your taste buds.
                \u00a0\u00a0\u00a0\u00a0 Wrapped in bright and attractive colors, Kasandra Warna not only arouses your taste, but also your eyes. With charming packaging and elegant design, every Kasandra Warna packaging is an attractive work of art to be enjoyed on every occasion. Not just a snack, Kasandra Warna is a lifestyle symbol that shows courage and uniqueness in expressing yourself.
                `,
                "image": Kerupuk19,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "لون كاساندرا",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 كاساندرا وارنا هي أحدث ابتكارات شينجوكو في عالم المقرمشات التي تجمع بين الطعم والمظهر الرائع. كل قضمة من هذه البسكويت تجلب لك تجربة لا تنسى، مع طعم غني وملمس مقرمش. بنكهات فريدة ومتنوعة، يقدم كاساندرا وارنا مزيجًا من المكونات عالية الجودة التي تم اختيارها بعناية لخلق إحساس لذيذ يدلل ذوقك.
                \u00a0\u00a0\u00a0\u00a0 مغلفة بألوان زاهية وجذابة، كاساندرا وارنا لا تثير ذوقك فحسب، بل تثير عينيك أيضًا. بفضل التغليف الساحر والتصميم الأنيق، تعد كل عبوة من عبوات Kasandra Warna عملاً فنيًا جذابًا للاستمتاع به في كل مناسبة. ليست مجرد وجبة خفيفة، بل تعد كاساندرا وارنا رمزًا لأسلوب الحياة الذي يظهر الشجاعة والتفرد في التعبير عن نفسك.
                `,
                "image": Kerupuk19,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "卡桑德拉颜色",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna 是新宿在饼干领域的最新创新，它结合了令人惊叹的味道和外观。 这些饼干的每一口都会带来难忘的体验，口感丰富，质地松脆。 Kasandra Warna 口味独特多样，提供精心挑选的优质原料组合，创造出满足您味蕾的美味感觉。
                \u00a0\u00a0\u00a0\u00a0 Kasandra Warna 被明亮而迷人的色彩包裹着，不仅能激发您的品味，还能激发您的眼睛。 凭借迷人的包装和优雅的设计，每一款 Kasandra Warna 包装都是一件迷人的艺术品，适合在任何场合欣赏。 Kasandra Warna 不仅仅是一种零食，它还是一种生活方式的象征，在表达自我时展现出勇气和独特性。
                `,
                "image": Kerupuk19,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Kepang Mas",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Kepang Mas adalah salah satu pilihan yang tak terbantahkan bagi pecinta kerupuk yang menghargai rasa dan kualitas. Dibuat dengan teliti menggunakan bahan-bahan pilihan, setiap gigitan dari Kerupuk Kepang Mas memberikan sensasi yang memikat dengan rasa yang autentik dan tekstur yang renyah. Diracik dengan resep warisan turun-temurun, Kerupuk Kepang Mas menghadirkan cita rasa yang khas dan memuaskan, menjadikannya pilihan yang sempurna untuk dinikmati di setiap kesempatan.
                \u00a0\u00a0\u00a0\u00a0 Selain cita rasanya yang lezat, Kerupuk Kepang Mas juga menawarkan pengalaman visual yang menarik. Dengan bentuk yang unik dan kekhasan anyaman yang menjadi ciri khasnya, setiap potongan Kerupuk Kepang Mas adalah sebuah karya seni dalam dunia kuliner. Tidak hanya memikat lidah, tetapi juga mata, Kerupuk Kepang Mas memberikan pengalaman yang lengkap dan memuaskan bagi para penikmatnya.
                `,
                "image": Kerupuk20,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Braids Mas",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kepang Mas Crackers is one of the undeniable choices for cracker lovers who value taste and quality. Carefully made using selected ingredients, every bite of Kepang Mas Crackers provides an alluring sensation with an authentic taste and crunchy texture. Formulated using a recipe passed down from generation to generation, Kerupuk Kepang Mas presents a distinctive and satisfying taste, making it the perfect choice to enjoy on every occasion.
                \u00a0\u00a0\u00a0\u00a0 Apart from its delicious taste, Kerupuk Kepang Mas also offers an interesting visual experience. With its unique shape and unique weave, each piece of Kepang Mas Crackers is a work of art in the culinary world. Not only captivating the tongue, but also the eyes, Kerupuk Kepang Mas provides a complete and satisfying experience for its connoisseurs.
                `,
                "image": Kerupuk20,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "الضفائر ماس",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعد Kepang Mas Crackers واحدة من الخيارات التي لا يمكن إنكارها لمحبي البسكويت الذين يقدرون الذوق والجودة. مصنوعة بعناية باستخدام مكونات مختارة، كل قضمة من مقرمشات كيبانج ماس توفر إحساسًا جذابًا مع طعم أصيل وملمس مقرمش. تم إعداده باستخدام وصفة تنتقل من جيل إلى جيل، ويقدم Kerupuk Kepang Mas مذاقًا مميزًا ومرضيًا، مما يجعله الخيار الأمثل للاستمتاع به في كل مناسبة.
                \u00a0\u00a0\u00a0\u00a0 بصرف النظر عن مذاقه اللذيذ، يقدم Kerupuk Kepang Mas أيضًا تجربة بصرية مثيرة للاهتمام. بفضل شكلها الفريد ونسجها الفريد، تعد كل قطعة من Kepang Mas Crackers عملاً فنيًا في عالم الطهي. لا يأسر Kerupuk Kepang Mas اللسان فحسب، بل يأسر العيون أيضًا، ويقدم تجربة كاملة ومرضية لخبراءه.
                `,
                "image": Kerupuk20,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "辫子马斯",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 对于重视口味和品质的饼干爱好者来说，Kepang Mas Crackers 是不可否认的选择之一。 Kepang Mas 饼干采用精选原料精心制作，每一口都带来诱人的口感、正宗的味道和松脆的质地。 Kerupuk Kepang Mas 采用代代相传的配方配制而成，呈现出独特而令人满意的味道，使其成为各种场合享用的完美选择。
                \u00a0\u00a0\u00a0\u00a0 除了美味的味道之外，Kerupuk Kepang Mas 还提供有趣的视觉体验。 凭借其独特的形状和独特的编织，每件 Kepang Mas 饼干都是烹饪界的艺术品。 Kerupuk Kepang Mas 不仅能吸引您的舌头，也能吸引您的眼睛，为美食家提供完整且令人满意的体验。
                `,
                "image": Kerupuk20,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Kepang Warna",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Kepang Warna adalah inovasi baru dalam dunia kuliner yang menghadirkan kombinasi sempurna antara rasa, tekstur, dan visual yang memikat. Dibuat dengan teliti menggunakan bahan-bahan pilihan dan proses produksi yang cermat, setiap potongan Kerupuk Kepang Warna menghadirkan pengalaman yang memanjakan lidah dan mata. Kekhasan dari Kerupuk Kepang Warna terletak pada warna-warni yang cerah dan menarik, memberikan sentuhan visual yang menyegarkan dan mengundang selera.
                \u00a0\u00a0\u00a0\u00a0 Selain tampil menarik, Kerupuk Kepang Warna juga memiliki cita rasa yang luar biasa. Dengan tekstur yang renyah namun ringan, serta perpaduan bumbu yang pas, setiap gigitan Kerupuk Kepang Warna memberikan ledakan rasa yang memikat dan memuaskan. Cocok untuk dinikmati sebagai camilan di waktu senggang atau sebagai pendamping sajian utama, Kerupuk Kepang Warna menjadi pilihan yang tepat bagi pecinta kuliner yang menginginkan sensasi baru dan tak terlupakan.
                `,
                "image": Kerupuk21,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Color Braids",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Kepang Warna is a new innovation in the culinary world that presents the perfect combination of taste, texture and attractive visuals. Carefully made using carefully selected ingredients and a careful production process, each piece of Kerupuk Kepang Warna presents an experience that pampers the tongue and eyes. The specialty of Kerupuk Kepang Warna lies in its bright and attractive colors, providing a refreshing and appetizing visual touch.
                \u00a0\u00a0\u00a0\u00a0 Apart from looking attractive, Colored Braided Crackers also have an extraordinary taste. With a crunchy but light texture, and the right blend of spices, every bite of Kerupuk Kepang Warna provides an alluring and satisfying explosion of taste. Suitable to be enjoyed as a snack in your spare time or as an accompaniment to the main dish, Kerupuk Kepang Warna is the right choice for culinary lovers who want a new and unforgettable sensation.
                `,
                "image": Kerupuk21,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "الضفائر الملونة",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 يعتبر Kerupuk Kepang Warna ابتكارًا جديدًا في عالم الطهي يقدم مزيجًا مثاليًا من المذاق والملمس والمرئيات الجذابة. مصنوعة بعناية باستخدام مكونات مختارة بعناية وعملية إنتاج دقيقة، كل قطعة من Kerupuk Kepang Warna تقدم تجربة تدلل اللسان والعينين. تكمن خصوصية Kerupuk Kepang Warna في ألوانها الزاهية والجذابة التي توفر لمسة بصرية منعشة وشهية.
                \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن مظهرها الجذاب، فإن مقرمشات الجدائل الملونة لها أيضًا طعم غير عادي. بفضل الملمس المقرمش والخفيف، والمزيج المناسب من التوابل، فإن كل قضمة من Kerupuk Kepang Warna توفر مذاقًا جذابًا ومرضيًا. يعتبر Kerupuk Kepang Warna مناسبًا للاستمتاع به كوجبة خفيفة في وقت فراغك أو كمرافق للطبق الرئيسي، وهو الخيار الصحيح لمحبي الطهي الذين يريدون إحساسًا جديدًا لا يُنسى.
                `,
                "image": Kerupuk21,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "彩色辫子",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Kepang Warna 是烹饪界的一项新创新，呈现出口味、质地和迷人视觉的完美结合。 Kerupuk Kepang Warna 采用精心挑选的原料和精心的制作过程精心制作，每件 Kerupuk Kepang Warna 都呈现出一种呵护舌头和眼睛的体验。 Kerupuk Kepang Warna 的特色在于其明亮诱人的色彩，给人一种清爽开胃的视觉触感。
                \u00a0\u00a0\u00a0\u00a0 彩色辫子饼干除了好看之外，味道也非同一般。 Kerupuk Kepang Warna 质地松脆但质地轻盈，加上恰到好处的香料混合，每一口都能带来诱人且令人满意的味道。 Kerupuk Kepang Warna 适合作为闲暇时的小吃或主菜的配菜，是想要新奇难忘感觉的烹饪爱好者的正确选择。
                `,
                "image": Kerupuk21,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Mawar Pink",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Mawar Pink adalah salah satu varian unik dalam dunia kuliner, menawarkan pengalaman yang menyenangkan dan memikat bagi para penikmatnya. Dibuat dengan teliti menggunakan bahan-bahan berkualitas tinggi dan proses produksi yang terkontrol, setiap helai Kerupuk Mawar Pink hadir dengan warna yang memikat dan bentuk yang menyerupai kelopak bunga mawar.
                \u00a0\u00a0\u00a0\u00a0 Keunikan visualnya menjadi daya tarik utama, menjadikan Kerupuk Mawar Pink sebagai pilihan yang populer untuk dihidangkan dalam berbagai acara spesial, mulai dari perayaan ulang tahun hingga pesta pernikahan. Selain penampilannya yang memukau, Kerupuk Mawar Pink juga memiliki rasa yang lezat, dengan tekstur yang renyah dan cita rasa yang gurih. Dengan setiap gigitan, Anda akan merasakan kelezatan dan keunikan yang tak terlupakan, menjadikan Kerupuk Mawar Pink sebagai camilan yang sempurna untuk dinikmati bersama keluarga dan teman-teman.
                `,
                "image": Kerupuk22,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Pink Rose",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Pink Mawar Crackers is a unique variant in the culinary world, offering a fun and alluring experience for its connoisseurs. Carefully made using high quality ingredients and a controlled production process, each strand of Pink Mawar Crackers comes with an alluring color and a shape that resembles rose petals.
                \u00a0\u00a0\u00a0\u00a0 Its visual uniqueness is the main attraction, making Pink Mawar Crackers a popular choice to serve at various special events, from birthday celebrations to wedding parties. Apart from its stunning appearance, Pink Mawar Crackers also have a delicious taste, with a crunchy texture and savory taste. With every bite, you will feel the deliciousness and uniqueness that is unforgettable, making Pink Mawar Crackers the perfect snack to enjoy with family and friends.
                `,
                "image": Kerupuk22,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "وردة زهرية",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 يعتبر Pink Mawar Crackers نوعاً فريداً من نوعه في عالم الطهي، حيث يقدم تجربة ممتعة وجذابة لخبراءه. مصنوعة بعناية باستخدام مكونات عالية الجودة وعملية إنتاج خاضعة للرقابة، كل خصلة من Pink Mawar Crackers تأتي بلون جذاب وشكل يشبه بتلات الورد.
                \u00a0\u00a0\u00a0\u00a0 إن تفردها البصري هو عامل الجذب الرئيسي، مما يجعل Pink Mawar Crackers خيارًا شائعًا لتقديمه في مختلف المناسبات الخاصة، بدءًا من احتفالات أعياد الميلاد وحتى حفلات الزفاف. وبصرف النظر عن مظهرها المذهل، تتمتع مقرمشات بينك موار أيضًا بطعم لذيذ، مع ملمس مقرمش وطعم لذيذ. مع كل قضمة، ستشعر باللذة والتفرد الذي لا يُنسى، مما يجعل مقرمشات بينك موار وجبة خفيفة مثالية للاستمتاع بها مع العائلة والأصدقاء.
                `,
                "image": Kerupuk22,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "粉红玫瑰",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Pink Mawar 饼干是烹饪界的独特变种，为鉴赏家提供有趣而诱人的体验。 采用高品质原料和受控生产流程精心制作，每一串 Pink Mawar 饼干都具有诱人的颜色和类似于玫瑰花瓣的形状。
                \u00a0\u00a0\u00a0\u00a0 其视觉上的独特性是其主要吸引力，使 Pink Mawar 饼干成为各种特殊活动（从生日庆祝活动到婚礼派对）的热门选择。 除了令人惊叹的外观外，粉红玛瓦尔饼干的味道也很美味，口感松脆，味道鲜美。 每一口，您都会感受到令人难忘的美味和独特，使 Pink Mawar 饼干成为与家人和朋友一起享用的完美零食。
                `,
                "image": Kerupuk22,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Mawar Warna",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Mawar Warna adalah salah satu inovasi menarik dalam dunia kuliner, menghadirkan kombinasi yang memikat antara cita rasa lezat dan penampilan visual yang menawan. Dengan warna-warna cerah yang memukau, setiap helai Kerupuk Mawar Warna menjadi sebuah karya seni kuliner yang mengundang selera.
                \u00a0\u00a0\u00a0\u00a0 Proses pembuatannya dilakukan dengan teliti menggunakan bahan-bahan berkualitas tinggi dan teknik produksi yang terjamin kebersihannya, sehingga menghasilkan kerupuk dengan kualitas terbaik. Ditambah lagi dengan rasa yang gurih dan tekstur yang renyah, menjadikan Kerupuk Mawar Warna sebagai camilan yang sempurna untuk dinikmati kapan pun dan di mana pun. Dengan penampilan yang cantik dan rasa yang menggugah selera, Kerupuk Mawar Warna akan menjadi sorotan dalam setiap kesempatan makan Anda.
                `,
                "image": Kerupuk23,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Rose Color",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Mawar Warna Crackers is one of the interesting innovations in the culinary world, presenting an alluring combination of delicious taste and charming visual appearance. With stunning bright colors, each piece of Mawar Warna Crackers becomes a work of culinary art that is appetizing.
                \u00a0\u00a0\u00a0\u00a0 The manufacturing process is carried out carefully using high quality ingredients and production techniques that ensure cleanliness, thus producing the best quality crackers. Coupled with the delicious taste and crunchy texture, Mawar Warna Crackers are the perfect snack to enjoy anytime and anywhere. With its beautiful appearance and appetizing taste, Mawar Warna Crackers will be the highlight of every meal you eat.
                `,
                "image": Kerupuk23,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "لون الزهر",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات موارنا هي واحدة من الابتكارات المثيرة للاهتمام في عالم الطهي، حيث تقدم مزيجًا جذابًا من الطعم اللذيذ والمظهر البصري الساحر. مع الألوان الزاهية المذهلة، تصبح كل قطعة من مقرمشات موار وارنا عملاً من فن الطهي الذي يفتح الشهية.
                \u00a0\u00a0\u00a0\u00a0 يتم تنفيذ عملية التصنيع بعناية باستخدام مكونات عالية الجودة وتقنيات الإنتاج التي تضمن النظافة، وبالتالي إنتاج المفرقعات ذات الجودة الأفضل. إلى جانب المذاق اللذيذ والملمس المقرمش، تعتبر مقرمشات موارنا هي الوجبة الخفيفة المثالية للاستمتاع بها في أي وقت وفي أي مكان. بمظهرها الجميل وطعمها اللذيذ، مقرمشات موارنا ستكون أبرز ما تتناوله في كل وجبة.
                `,
                "image": Kerupuk23,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "玫瑰色",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Mawar Warna 饼干是烹饪界有趣的创新之一，呈现出美味味道和迷人视觉外观的诱人组合。 每一块 Mawar Warna 饼干都拥有令人惊叹的明亮色彩，成为令人垂涎欲滴的烹饪艺术品。
                \u00a0\u00a0\u00a0\u00a0 制造过程采用高品质原料和确保清洁的生产技术精心进行，从而生产出最优质的饼干。 加上美味的味道和松脆的质地，Mawar Warna 饼干是随时随地享用的完美零食。 Mawar Warna 饼干以其美丽的外观和开胃的味道将成为您每顿饭的亮点。
                `,
                "image": Kerupuk23,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Akar/Pentil",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Manggar adalah salah satu varian kerupuk yang memiliki ciri khas tersendiri. Dibuat dengan menggunakan bahan-bahan pilihan dan proses produksi yang teliti, kerupuk ini menawarkan cita rasa yang unik dan tekstur yang khas. Dengan bentuknya yang menyerupai manggar, kerupuk ini memberikan sensasi visual yang menarik saat disajikan di atas meja makan.
                \u00a0\u00a0\u00a0\u00a0 Proses pembuatan kerupuk Manggar melibatkan beragam teknik tradisional yang telah diwariskan secara turun-temurun, menjadikannya sebagai bagian dari warisan kuliner yang berharga. Dipadukan dengan bumbu rempah-rempah pilihan, kerupuk Manggar menghadirkan rasa yang lezat dan menggugah selera.
                `,
                "image": Kerupuk24,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Manggar",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Manggar Crackers are a variant of crackers that have their own characteristics. Made using selected ingredients and a careful production process, these crackers offer a unique taste and distinctive texture. With its shape resembling a manggar, these crackers provide an interesting visual sensation when served on the dining table.
                \u00a0\u00a0\u00a0\u00a0 The process of making Manggar crackers involves a variety of traditional techniques that have been passed down from generation to generation, making them part of a valuable culinary heritage. Combined with selected spices, Manggar crackers present a delicious and appetizing taste.
                `,
                "image": Kerupuk24,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "مانجار",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Manggar Crackers هي نوع مختلف من المفرقعات التي لها خصائصها الخاصة. مصنوعة باستخدام مكونات مختارة وعملية إنتاج دقيقة، توفر هذه المقرمشات طعمًا فريدًا وملمسًا مميزًا. بفضل شكلها الذي يشبه المانغجار، توفر هذه المفرقعات إحساسًا بصريًا مثيرًا للاهتمام عند تقديمها على طاولة الطعام.
                \u00a0\u00a0\u00a0\u00a0 تتضمن عملية صنع بسكويت مانجار مجموعة متنوعة من التقنيات التقليدية التي تم تناقلها من جيل إلى جيل، مما يجعلها جزءًا من تراث الطهي القيم. تقدم مقرمشات مانجار، الممزوجة مع التوابل المختارة، طعمًا لذيذًا وشهيًا.
                `,
                "image": Kerupuk24,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "芒加尔",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 芒加饼干是饼干的一个变种，有自己的特点。 这些饼干采用精选原料和精心制作工艺制成，具有独特的口味和独特的质地。 这些饼干的形状类似于芒加尔，在餐桌上提供有趣的视觉感受。
                \u00a0\u00a0\u00a0\u00a0 芒加尔饼干的制作过程涉及多种代代相传的传统技术，使其成为宝贵的烹饪遗产的一部分。 与精选香料相结合，芒加尔饼干呈现出美味可口的味道。
                `,
                "image": Kerupuk24,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Rantai Jumbo Orange",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Rantai Jumbo Orange merupakan salah satu varian kerupuk yang sangat populer di kalangan pecinta kuliner Indonesia. Dikenal dengan ukurannya yang besar dan warna oranye yang mencolok, kerupuk ini menjadi pilihan yang tepat untuk menambahkan kelezatan pada hidangan Anda.
                \u00a0\u00a0\u00a0\u00a0 Dibuat dengan menggunakan bahan-bahan berkualitas tinggi dan diproses dengan teliti, kerupuk Rantai Jumbo Orange memiliki tekstur yang renyah dan cita rasa yang gurih. Kelezatannya yang khas membuatnya cocok sebagai camilan di berbagai kesempatan, baik saat santai di rumah maupun saat berkumpul bersama keluarga dan teman-teman.
                `,
                "image": Kerupuk25,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Jumbo Orange Chain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Jumbo Orange Chain Crackers are a variant of crackers that are very popular among Indonesian culinary lovers. Known for their large size and striking orange color, these crackers are the right choice to add deliciousness to your dishes.
                \u00a0\u00a0\u00a0\u00a0 Made using high quality ingredients and processed carefully, Jumbo Orange Chain crackers have a crunchy texture and delicious taste. Its unique deliciousness makes it suitable as a snack on various occasions, both when relaxing at home and when gathering with family and friends.
                `,
                "image": Kerupuk25,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "سلسلة برتقالية جامبو",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعد مقرمشات Jumbo Orange Chain أحد أنواع المفرقعات التي تحظى بشعبية كبيرة بين عشاق الطهي الإندونيسيين. تتميز هذه المقرمشات بحجمها الكبير ولونها البرتقالي المذهل، وهي الخيار الصحيح لإضافة الطعم اللذيذ إلى أطباقك.
                \u00a0\u00a0\u00a0\u00a0 مصنوعة باستخدام مكونات عالية الجودة وتمت معالجتها بعناية، وتتميز مقرمشات جامبو أورانج تشاين بملمس مقرمش وطعم لذيذ. إن لذتها الفريدة تجعلها مناسبة كوجبة خفيفة في مختلف المناسبات، سواء عند الاسترخاء في المنزل أو عند التجمع مع العائلة والأصدقاء.
                `,
                "image": Kerupuk25,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "巨型橙链",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Jumbo Orange Chain Crackers 是一种深受印尼美食爱好者喜爱的饼干变种。 这些饼干以其大尺寸和醒目的橙色而闻名，是为您的菜肴增添美味的正确选择。
                \u00a0\u00a0\u00a0\u00a0 Jumbo Orange Chain饼干采用优质原料精制而成，口感松脆，味道鲜美。 其独特的美味使其适合作为各种场合的零食，无论是在家放松时还是与家人和朋友聚会时。
                `,
                "image": Kerupuk25,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Rantai Jumbo Pink",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Rantai Jumbo Pink adalah salah satu varian kerupuk yang menarik perhatian dengan warnanya yang cerah dan menawan. Berbeda dari varian kerupuk lainnya, kerupuk ini hadir dengan warna pink yang unik dan menarik, membuatnya menjadi pilihan yang menonjol di atas meja makan.
                \u00a0\u00a0\u00a0\u00a0 Rasa gurih dan renyah yang menjadi ciri khas kerupuk Rantai Jumbo Pink membuatnya menjadi camilan yang disukai banyak orang. Dibuat dengan teliti menggunakan bahan-bahan berkualitas, kerupuk ini memberikan pengalaman rasa yang memuaskan setiap kali Anda menggigitnya
                `,
                "image": Kerupuk26,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Jumbo Pink Chain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Jumbo Pink Chain Crackers are a variant of crackers that attract attention with their bright and charming color. Different from other cracker variants, these crackers come in a unique and attractive pink color, making them a standout choice on the dining table.
                \u00a0\u00a0\u00a0\u00a0 The savory and crunchy taste that is characteristic of Jumbo Pink Chain crackers makes them a snack that many people like. Carefully made using quality ingredients, these crackers provide a satisfying taste experience every time you bite into them.
                `,
                "image": Kerupuk26,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "جامبو سلسلة الوردي",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 المفرقعات الكبيرة ذات السلسلة الوردية هي مجموعة متنوعة من المفرقعات التي تجذب الانتباه بألوانها الزاهية والساحرة. تختلف هذه المفرقعات عن أنواع البسكويت الأخرى، فهي تأتي باللون الوردي الفريد والجذاب، مما يجعلها خيارًا متميزًا على طاولة الطعام.
                \u00a0\u00a0\u00a0\u00a0 المذاق اللذيذ والمقرمش الذي يتميز به مقرمشات Jumbo Pink Chain يجعلها وجبة خفيفة يحبها الكثير من الناس. مصنوعة بعناية باستخدام مكونات عالية الجودة، توفر هذه المقرمشات تجربة طعم مرضية في كل مرة تقوم فيها بقضمها.
                `,
                "image": Kerupuk26,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "巨型粉色链条",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Jumbo Pink Chain Crackers 是饼干的一种变体，以其明亮迷人的颜色吸引人们的注意。 与其他饼干不同，这些饼干具有独特且迷人的粉红色，使其成为餐桌上的出色选择。
                \u00a0\u00a0\u00a0\u00a0 Jumbo Pink Chain 饼干的咸味和松脆的味道使其成为许多人喜欢的零食。 这些饼干采用优质原料精心制作，每次咬一口都会给您带来令人满意的味觉体验。
                `,
                "image": Kerupuk26,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Rantai Jumbo Putih",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Rantai Jumbo Putih merupakan salah satu varian kerupuk yang memiliki daya tarik tersendiri dengan warna putihnya yang bersih dan cerah. Keunikan warna ini memberikan kesan elegan dan mewah pada tampilan kerupuk, sehingga sering menjadi pilihan untuk berbagai kesempatan istimewa.
                \u00a0\u00a0\u00a0\u00a0 Selain tampilannya yang menarik, kerupuk Rantai Jumbo Putih juga terkenal dengan teksturnya yang renyah dan rasanya yang gurih. Setiap gigitan kerupuk ini memberikan sensasi yang memuaskan bagi lidah Anda, membuatnya menjadi camilan yang cocok dinikmati kapan pun dan di mana pun.
                `,
                "image": Kerupuk27,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "White Jumbo Chain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 White Jumbo Chain Crackers are a variant of crackers which have their own appeal with their clean and bright white color. The unique color gives an elegant and luxurious impression to the appearance of crackers, so they are often the choice for various special occasions.
                \u00a0\u00a0\u00a0\u00a0 Apart from its attractive appearance, the Jumbo White Chain crackers are also famous for their crunchy texture and delicious taste. Every bite of these crackers provides a satisfying sensation for your taste buds, making them a snack that is suitable to be enjoyed anytime and anywhere.
                `,
                "image": Kerupuk27,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "سلسلة جامبو بيضاء",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 المفرقعات البيضاء الضخمة هي نوع مختلف من المفرقعات التي لها جاذبيتها الخاصة مع لونها الأبيض النظيف والمشرق. اللون الفريد يعطي انطباعًا أنيقًا وفاخرًا لمظهر البسكويت، لذلك غالبًا ما يكون الاختيار المناسب لمختلف المناسبات الخاصة.
                \u00a0\u00a0\u00a0\u00a0 بصرف النظر عن مظهرها الجذاب، تشتهر مقرمشات السلسلة البيضاء الكبيرة أيضًا بقوامها المقرمش وطعمها اللذيذ. توفر كل قضمة من هذه البسكويت إحساسًا مرضيًا لذوقك، مما يجعلها وجبة خفيفة مناسبة للاستمتاع بها في أي وقت وفي أي مكان.
                `,
                "image": Kerupuk27,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "白色巨链",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 白色巨型链式饼干是饼干的一种变体，以其干净明亮的白色而具有自己的吸引力。 独特的颜色给饼干的外观带来优雅和奢华的印象，因此它们往往是各种特殊场合的选择。
                \u00a0\u00a0\u00a0\u00a0 除了其诱人的外观外，Jumbo White Chain饼干还以其松脆的口感和美味的口感而闻名。 每一口都给您的味蕾带来满足的感觉，使其成为适合随时随地享用的零食。
                `,
                "image": Kerupuk27,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Rantai Lemon Warna",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Rantai Lemon Warna adalah varian kerupuk yang memiliki warna cerah dan menyegarkan, mirip dengan warna kulit lemon yang segar. Warna kuning cerah ini memberikan kesan yang menyenangkan dan menarik pada kerupuk, membuatnya menjadi pilihan yang menarik untuk dinikmati dalam berbagai kesempatan.
                \u00a0\u00a0\u00a0\u00a0 Selain tampilannya yang mencolok, kerupuk Rantai Lemon Warna juga dikenal dengan teksturnya yang renyah dan rasa yang segar. Setiap gigitan kerupuk ini memberikan sensasi yang menyegarkan pada lidah Anda, cocok untuk dinikmati sebagai camilan ringan di siang hari atau sebagai pelengkap hidangan pada berbagai acara.
                `,
                "image": Kerupuk28,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Lemon Color Chain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Colored Lemon Chain Crackers are a cracker variant that has a bright and refreshing color, similar to the color of fresh lemon skin. This bright yellow color gives a fun and attractive impression to crackers, making them an attractive choice to enjoy on various occasions.
                \u00a0\u00a0\u00a0\u00a0 Apart from its striking appearance, the Colored Lemon Chain crackers are also known for their crunchy texture and fresh taste. Every bite of these crackers gives a refreshing sensation to your tongue, suitable to be enjoyed as a light snack during the day or as a complement to dishes at various events.
                `,
                "image": Kerupuk28,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "سلسلة لون الليمون",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات سلسلة الليمون الملونة هي نوع من المفرقعات التي تتميز بلون مشرق ومنعش، يشبه لون قشر الليمون الطازج. يعطي هذا اللون الأصفر اللامع انطباعًا ممتعًا وجذابًا للمقرمشات، مما يجعلها خيارًا جذابًا للاستمتاع بها في مختلف المناسبات.
                \u00a0\u00a0\u00a0\u00a0 بصرف النظر عن مظهرها المذهل، فإن مقرمشات سلسلة الليمون الملونة معروفة أيضًا بقوامها المقرمش وطعمها الطازج. كل قضمة من هذه البسكويت تعطي إحساسًا منعشًا للسانك، ومناسبة للاستمتاع بها كوجبة خفيفة خلال النهار أو كمكمل للأطباق في مختلف المناسبات.
                `,
                "image": Kerupuk28,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "柠檬色链",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 彩色柠檬链饼干是饼干的变种，具有明亮清爽的颜色，类似于新鲜柠檬皮的颜色。 这种明亮的黄色给饼干带来有趣和有吸引力的印象，使其成为在各种场合享用的有吸引力的选择。
                \u00a0\u00a0\u00a0\u00a0 除了引人注目的外观外，彩色柠檬链饼干还以其松脆的质地和新鲜的味道而闻名。 这些饼干的每一口都会给您的舌头带来清爽的感觉，适合在白天作为小吃或在各种活动中作为菜肴的补充。
                `,
                "image": Kerupuk28,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Rantai Sedang Putih",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Rantai Sedang Putih merupakan salah satu varian kerupuk yang memiliki tekstur yang lembut namun tetap renyah ketika digigit. Dengan warna putih yang bersih dan menawan, kerupuk ini cocok untuk menjadi pelengkap hidangan pada berbagai kesempatan.
                \u00a0\u00a0\u00a0\u00a0 Rasa yang lezat dan tekstur yang renyah membuat kerupuk Rantai Sedang Putih menjadi pilihan yang pas untuk dinikmati sebagai camilan ringan atau sebagai pelengkap hidangan pada saat bersantai maupun saat berkumpul bersama keluarga dan teman-teman.
                `,
                "image": Kerupuk29,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "White Medium Chain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Medium White Chain Crackers are a variant of crackers that have a soft texture but are still crunchy when bitten. With a clean and charming white color, these crackers are suitable as a complement to dishes on various occasions.
                \u00a0\u00a0\u00a0\u00a0 The delicious taste and crunchy texture make Medium White Chain crackers the right choice to enjoy as a light snack or as a complement to dishes when relaxing or gathering with family and friends.
                `,
                "image": Kerupuk29,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "سلسلة متوسطة بيضاء",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 المفرقعات ذات السلسلة البيضاء المتوسطة هي نوع من المفرقعات ذات الملمس الناعم ولكنها لا تزال مقرمشة عند عضها. مع لونها الأبيض النظيف والساحر، تعتبر هذه المقرمشات مناسبة كمكمل للأطباق في مختلف المناسبات.
                \u00a0\u00a0\u00a0\u00a0 الطعم اللذيذ والملمس المقرمش يجعل من بسكويت السلسلة البيضاء المتوسطة الخيار الصحيح للاستمتاع به كوجبة خفيفة أو كمكمل للأطباق عند الاسترخاء أو التجمع مع العائلة والأصدقاء.
                `,
                "image": Kerupuk29,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "白色中链",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 中号白链饼干是饼干的一种变体，质地柔软，但咬起来仍然松脆。 这些饼干具有干净迷人的白色外观，适合在各种场合作为菜肴的补充。
                \u00a0\u00a0\u00a0\u00a0 美味的味道和松脆的质地使中白链饼干成为休闲或与家人和朋友聚会时作为小吃或菜肴补充的正确选择。
                `,
                "image": Kerupuk29,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Kelabang Cream",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Kelabang Cream adalah salah satu varian kerupuk yang menggabungkan rasa lembut dengan tekstur yang renyah. Dibuat dengan bahan-bahan berkualitas tinggi dan proses produksi yang teliti, kerupuk ini memiliki cita rasa yang lezat dan khas.
                \u00a0\u00a0\u00a0\u00a0 Dengan warna cream yang menawan, kerupuk Kelabang Cream menjadi pilihan yang cocok untuk dinikmati sebagai camilan ringan atau sebagai pelengkap hidangan pada berbagai acara spesial. Teksturnya yang renyah ketika digigit serta rasa yang gurih membuatnya menjadi favorit bagi banyak orang.
                `,
                "image": kerupuk30,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Cream Centipede",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Kelapang Cream is a variant of crackers that combines a soft taste with a crunchy texture. Made with high quality ingredients and a careful production process, these crackers have a delicious and distinctive taste.
                \u00a0\u00a0\u00a0\u00a0 With their attractive cream color, Kelabang Cream crackers are a suitable choice to be enjoyed as a light snack or as a complement to dishes on various special occasions. Its crunchy texture when bitten and delicious taste make it a favorite for many people.
                `,
                "image": kerupuk30,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "كريم حريش",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 كريم Kerupuk Kelapang هو أحد أنواع البسكويت الذي يجمع بين المذاق الناعم والملمس المقرمش. مصنوعة من مكونات عالية الجودة وعملية إنتاج دقيقة، تتمتع هذه المقرمشات بطعم لذيذ ومميز.
                \u00a0\u00a0\u00a0\u00a0 بفضل لونها الكريمي الجذاب، تعد مقرمشات كيلابانج الكريمية خيارًا مناسبًا للاستمتاع بها كوجبة خفيفة أو كمكمل للأطباق في مختلف المناسبات الخاصة. قوامه المقرمش عند قضمه وطعمه اللذيذ يجعله المفضل لدى الكثير من الناس.
                `,
                "image": kerupuk30,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "奶油蜈蚣",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Kelapang Cream 是饼干的一种变体，结合了柔软的口感和松脆的质地。 这些饼干采用优质原料和精心的生产工艺制成，味道鲜美独特。
                \u00a0\u00a0\u00a0\u00a0 凭借其迷人的奶油色，Kelabang 奶油饼干是作为小吃或在各种特殊场合作为菜肴的补充的合适选择。 其口感脆脆，味道鲜美，深受许多人的喜爱。
                `,
                "image": kerupuk30,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Makaroni Ayam",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Makaroni Ayam adalah varian kerupuk yang memiliki cita rasa unik dan lezat. Dibuat dengan menggunakan campuran tepung terigu, rempah-rempah, dan tambahan rasa ayam yang khas, kerupuk ini menawarkan pengalaman santap yang istimewa dan memuaskan.
                \u00a0\u00a0\u00a0\u00a0 Dengan tekstur yang renyah dan kenyal, setiap gigitan kerupuk Makaroni Ayam memberikan sensasi yang menggugah selera. Rasa ayam yang autentik dan berpadu sempurna dengan rempah-rempah pilihan memberikan sentuhan khas pada setiap santapan.
                `,
                "image": kerupuk31,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Chicken Macaroni",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Chicken Macaroni Crackers are a variant of crackers that have a unique and delicious taste. Made using a mixture of wheat flour, spices and the addition of a distinctive chicken flavor, these crackers offer a special and satisfying eating experience.
                \u00a0\u00a0\u00a0\u00a0 With a crunchy and chewy texture, every bite of Chicken Macaroni Crackers provides an appetizing sensation. The authentic taste of chicken combines perfectly with selected spices to give a distinctive touch to every meal.
                `,
                "image": kerupuk31,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "معكرونة بالدجاج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات معكرونة الدجاج هي نوع مختلف من المقرمشات التي تتمتع بطعم فريد ولذيذ. مصنوعة باستخدام خليط من دقيق القمح والبهارات وإضافة نكهة الدجاج المميزة، تقدم هذه المقرمشات تجربة تناول طعام خاصة ومرضية.
                \u00a0\u00a0\u00a0\u00a0 بفضل قوامها المقرمش والمطاطي، توفر كل قضمة من مقرمشات الدجاج معكرونة إحساسًا بالشهية. يمتزج الطعم الأصيل للدجاج بشكل مثالي مع التوابل المختارة لإضفاء لمسة مميزة على كل وجبة.
                `,
                "image": kerupuk31,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "鸡肉通心粉",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 鸡肉通心粉饼干是饼干的一种变体，具有独特而美味的味道。 这些饼干采用小麦粉、香料的混合物制成，并添加了独特的鸡肉风味，提供特殊且令人满意的饮食体验。
                \u00a0\u00a0\u00a0\u00a0 鸡肉通心粉饼干口感松脆、耐嚼，每一口都给人一种开胃的感觉。 鸡肉的正宗味道与精选香料完美结合，为每顿饭带来独特的风味。
                `,
                "image": kerupuk31,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Makaroni Kuning Bantet",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Makaroni Bantet, salah satu produk unggulan dari Pusat Kerupuk Indonesia, adalah camilan yang menggugah selera dengan cita rasa yang khas dan tekstur yang renyah. Dibuat dengan teliti dari bahan-bahan berkualitas terbaik, setiap gigitan dari Makaroni Bantet akan memberikan pengalaman yang memuaskan bagi penggemar kerupuk. Dengan paduan sempurna antara tepung terigu berkualitas tinggi dan bumbu rempah pilihan, Makaroni Bantet menjadi pilihan yang sempurna untuk menemani santai bersama keluarga atau sebagai camilan saat berkumpul dengan teman-teman.
                \u00a0\u00a0\u00a0\u00a0 Tidak hanya lezat, Makaroni Bantet juga dikenal sebagai camilan yang praktis dan mudah dinikmati kapan pun dibutuhkan. Kemasan yang dirancang dengan baik menjaga kesegaran dan krispiness-nya, sehingga dapat dinikmati dalam kondisi optimal setiap saat. Dengan rasa yang menggugah selera dan kualitas yang terjaga, Makaroni Bantet dari Pusat Kerupuk Indonesia menjadi pilihan yang tepat untuk menikmati kelezatan camilan kapan pun Anda inginkan.
                `,
                "image": kerupuk32,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Bantet Yellow Macaroni",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Macaroni Bantet, one of the superior products from Pusat Kerupuk Indonesia, is an appetizing snack with a distinctive taste and crunchy texture. Carefully made from the best quality ingredients, every bite of Makaroni Bantet will provide a satisfying experience for cracker fans. With the perfect blend of high-quality wheat flour and selected spices, Macaroni Bantet is the perfect choice for relaxing with the family or as a snack when gathering with friends.
                \u00a0\u00a0\u00a0\u00a0 Not only delicious, Macaroni Bantet is also known as a snack that is practical and easy to enjoy whenever needed. Well-designed packaging maintains its freshness and crispiness, so it can be enjoyed in optimal conditions at all times. With an appetizing taste and maintained quality, Makaroni Bantet from Pusat Kerupuk Indonesia is the right choice to enjoy delicious snacks whenever you want.
                `,
                "image": kerupuk32,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "بانتيت معكرونة صفراء",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعتبر معكرونة بانتيت، إحدى المنتجات المتميزة من بوسات كيروبوك إندونيسيا، وجبة خفيفة شهية ذات طعم مميز وقوام مقرمش. مصنوعة بعناية من أفضل المكونات عالية الجودة، كل قضمة من مكاروني بانتيت ستوفر تجربة مرضية لمحبي البسكويت. مع المزيج المثالي من دقيق القمح عالي الجودة والتوابل المختارة، تعد معكرونة بانتيت الخيار الأمثل للاسترخاء مع العائلة أو كوجبة خفيفة عند التجمع مع الأصدقاء.
                \u00a0\u00a0\u00a0\u00a0 معكرونة بانتيت ليست لذيذة فحسب، بل تُعرف أيضًا بأنها وجبة خفيفة عملية وسهلة الاستمتاع بها عند الحاجة. تحافظ العبوة المصممة جيدًا على نضارتها وهشاشتها، لذلك يمكن الاستمتاع بها في الظروف المثالية في جميع الأوقات. مع طعم شهي وجودة ثابتة، يعد مكاروني بانتيت من بوسات كيروبوك إندونيسيا الخيار الصحيح للاستمتاع بالوجبات الخفيفة اللذيذة وقتما تشاء.
                `,
                "image": kerupuk32,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "班泰黄色通心粉",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Macaroni Bantet 是印度尼西亚 Pusat Kerupuk 的优质产品之一，是一种口味独特、质地松脆的开胃小吃。 Makaroni Bantet 采用最优质的原料精心制成，每一口都会为饼干爱好者带来令人满意的体验。 Macaroni Bantet 采用优质小麦粉和精选香料完美融合，是与家人一起放松或与朋友聚会时作为小吃的完美选择。
                \u00a0\u00a0\u00a0\u00a0 Macaroni Bantet 不仅美味，还被誉为一种实用且易于随时享用的零食。 精心设计的包装可保持其新鲜度和松脆度，因此可以始终在最佳条件下享用。 来自 Pusat Kerupuk Indonesia 的 Makaroni Bantet 味道鲜美，质量稳定，是您随时享用美味小吃的正确选择。
                `,
                "image": kerupuk32,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Tempe Bulat",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Tempe Bulat dari Pusat Kerupuk Indonesia adalah salah satu inovasi yang menghadirkan cita rasa tradisional dengan sentuhan modern. Dibuat dengan menggunakan tempe pilihan yang diolah secara hati-hati, kerupuk ini memiliki tekstur yang renyah namun tetap lembut di dalamnya. Setiap gigitan dari kerupuk Tempe Bulat menghadirkan aroma khas tempe yang gurih dan mengundang selera.
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Tempe Bulat tidak hanya menawarkan kenikmatan rasa, tetapi juga memberikan manfaat gizi dari tempe yang kaya akan protein nabati dan nutrisi penting lainnya. Kemasan praktisnya menjaga kesegaran dan kualitasnya, sehingga dapat dinikmati dalam kondisi terbaik kapan pun Anda inginkan. Nikmati sensasi kelezatan tradisional dengan sentuhan modern bersama kerupuk Tempe Bulat dari Pusat Kerupuk Indonesia, pilihan yang sempurna untuk menikmati camilan sehat dan lezat setiap hari.
                `,
                "image": kerupuk33,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Round Tempeh",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Round Tempe Crackers from the Indonesian Crackers Center are an innovation that presents traditional flavors with a modern touch. Made using carefully selected tempeh, these crackers have a crunchy texture but are still soft on the inside. Every bite of round tempeh crackers brings a distinctive savory and appetizing tempeh aroma.
                \u00a0\u00a0\u00a0\u00a0 Round Tempeh Crackers not only offer delicious taste, but also provide the nutritional benefits of tempeh which is rich in vegetable protein and other important nutrients. The practical packaging maintains freshness and quality, so you can enjoy it in the best condition whenever you want. Enjoy the sensation of traditional deliciousness with a modern touch with Bulat Tempe Crackers from Pusat Kerupuk Indonesia, the perfect choice to enjoy healthy and delicious snacks every day.
                `,
                "image": kerupuk33,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "تمبه مستديرة",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعتبر مقرمشات تيمبي المستديرة من مركز المفرقعات الإندونيسية ابتكارًا يقدم النكهات التقليدية بلمسة عصرية. تم صنع هذه المقرمشات باستخدام تمبي تم اختياره بعناية، وتتميز بملمس مقرمش ولكنها لا تزال طرية من الداخل. كل قضمة من بسكويت التمبيه المستديرة تجلب رائحة تمبيه مميزة ولذيذة.
                \u00a0\u00a0\u00a0\u00a0 لا تقدم مقرمشات التمبيه المستديرة طعمًا لذيذًا فحسب، بل توفر أيضًا الفوائد الغذائية للتمبيه الغني بالبروتين النباتي والمواد المغذية المهمة الأخرى. تحافظ العبوة العملية على نضارتها وجودتها، لذا يمكنك الاستمتاع بها بأفضل حالة وقتما تشاء. استمتع بإحساس اللذة التقليدية مع لمسة عصرية مع مقرمشات بولات تمبي من بوسات كيروبوك إندونيسيا، الخيار الأمثل للاستمتاع بوجبات خفيفة صحية ولذيذة كل يوم.
                `,
                "image": kerupuk33,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "圆豆豉",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 印度尼西亚饼干中心的圆形 Tempe 饼干是一项创新，将传统风味与现代风格融为一体。 这些饼干采用精心挑选的豆豉制成，质地松脆，但内部仍然柔软。 每一口圆圆的豆豉饼干都带有独特的鲜味和开胃的豆豉香气。
                \u00a0\u00a0\u00a0\u00a0 圆形豆豉饼干不仅味道鲜美，而且还提供豆豉的营养价值，富含植物蛋白和其他重要营养素。 实用的包装保持了新鲜度和品质，因此您可以随时以最佳状态享用。 印度尼西亚 Pusat Kerupuk 的 Bulat Tempe 饼干让您享受传统美味与现代风味的结合，是每天享受健康美味零食的完美选择。
                `,
                "image": kerupuk33,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Tiga Roda Cream",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Tiga Roda Cream adalah salah satu varian premium dari Tiga Roda, yang menggabungkan kelembutan dan kelezatan dalam setiap gigitannya. Dibuat dengan teknologi terkini dan bahan-bahan pilihan, kerupuk ini memiliki tekstur yang begitu lembut dan menggoda, serta rasa yang kaya akan cita rasa susu. Setiap kerupuknya memberikan pengalaman unik dengan sensasi lembut yang meleleh di mulut, memberikan kenikmatan yang tiada tara bagi para pecinta camilan.
                \u00a0\u00a0\u00a0\u00a0 Tidak hanya memanjakan lidah, kerupuk Tiga Roda Cream juga mengutamakan kualitas dan kebersihan dalam setiap proses pembuatannya. Diproses dengan teliti dan diawasi oleh para ahli, setiap kemasan kerupuk Tiga Roda Cream menjaga kesegaran dan kelembutannya yang khas, sehingga siap untuk dinikmati kapan saja dan di mana saja. Dengan kualitas premium dan cita rasa yang istimewa, kerupuk Tiga Roda Cream menjadi pilihan yang tepat untuk menyempurnakan momen santai Anda bersama keluarga atau teman-teman.
                `,
                "image": kerupuk34,
                "brand": "napoleon",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Three Cream Wheels",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tiga Roda Cream Crackers is one of the premium variants from Tiga Roda, which combines softness and deliciousness in every bite. Made with the latest technology and selected ingredients, these crackers have a very soft and tempting texture, as well as a rich taste of milk. Each cracker provides a unique experience with a soft sensation that melts in the mouth, providing unmatched enjoyment for snack lovers.
                \u00a0\u00a0\u00a0\u00a0 Not only do they pamper your taste buds, Tiga Roda Cream crackers also prioritize quality and cleanliness in every manufacturing process. Processed carefully and supervised by experts, each pack of Tiga Roda Cream crackers maintains its distinctive freshness and softness, so it is ready to be enjoyed anytime and anywhere. With premium quality and special taste, Tiga Roda Cream crackers are the right choice to perfect your relaxing moments with family or friends.
                `,
                "image": kerupuk34,
                "brand": "napoleon",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "ثلاث عجلات كريم",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعتبر مقرمشات تيجا رودا بالكريمة أحد الأنواع المتميزة من تيجا رودا، والتي تجمع بين النعومة واللذة في كل قضمة. مصنوعة باستخدام أحدث التقنيات والمكونات المختارة، تتميز هذه المقرمشات بملمس ناعم للغاية ومغري، بالإضافة إلى طعم الحليب الغني. توفر كل قطعة بسكويت تجربة فريدة من نوعها مع إحساس ناعم يذوب في الفم، مما يوفر متعة لا مثيل لها لمحبي الوجبات الخفيفة.
                \u00a0\u00a0\u00a0\u00a0 لا يقتصر الأمر على تدليل ذوقك فحسب، بل إن مقرمشات تيجا رودا كريم تعطي الأولوية أيضًا للجودة والنظافة في كل عملية تصنيع. يتم معالجتها بعناية وتحت إشراف الخبراء، كل عبوة من بسكويت تيجا رودا كريم تحافظ على نضارتها ونعومتها المميزة، لذلك فهي جاهزة للاستمتاع بها في أي وقت وفي أي مكان. بفضل الجودة العالية والطعم الخاص، تعد مقرمشات تيجا رودا بالكريمة الخيار الصحيح لقضاء لحظات الاسترخاء مع العائلة أو الأصدقاء.
                `,
                "image": kerupuk34,
                "brand": "napoleon",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "三个奶油轮",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tiga Roda 奶油饼干是 Tiga Roda 的优质品种之一，每一口都结合了柔软和美味。 这些饼干采用最新技术和精选原料制成，质地柔软诱人，牛奶味道浓郁。 每块饼干都提供独特的体验，入口即化的柔软感觉，为零食爱好者提供无与伦比的享受。
                \u00a0\u00a0\u00a0\u00a0 Tiga Roda 奶油饼干不仅满足您的味蕾，还在每个制造过程中优先考虑质量和清洁度。 每包 Tiga Roda 奶油饼干均经过专家精心加工和监督，保持其独特的新鲜度和柔软度，因此可以随时随地享用。 Tiga Roda 奶油饼干品质上乘，口味独特，是您与家人或朋友度过完美休闲时光的最佳选择。
                `,
                "image": kerupuk34,
                "brand": "napoleon",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Kemplang",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Opak Cap Napoleon adalah produk unggulan dari Pusat Kerupuk Indonesia yang dikenal karena kualitas dan rasa autentiknya. Dibuat dari tepung tapioka berkualitas tinggi dan bumbu-bumbu alami, kerupuk ini menawarkan cita rasa gurih yang khas dan tekstur renyah yang memikat. 
                \u00a0\u00a0\u00a0\u00a0 Selain kualitas rasanya, Kerupuk Opak Cap Napoleon juga menonjolkan komitmen Pusat Kerupuk Indonesia terhadap keberlanjutan. Mereka memilih bahan-bahan yang bersumber secara bertanggung jawab dan menerapkan praktik produksi yang ramah lingkungan. 
                `,
                "image": Kerupuk46,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Kemplang",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Opak Cap Napoleon Crackers are a superior product from the Indonesian Cracker Center which is known for its quality and authentic taste. Made from high quality tapioca flour and natural spices, these crackers offer a distinctive savory taste and an attractive crunchy texture.
                \u00a0\u00a0\u00a0\u00a0 Apart from its taste quality, Opak Cap Napoleon Crackers also highlight the Indonesian Cracker Center's commitment to sustainability. They choose responsibly sourced ingredients and employ environmentally friendly production practices.
                `,
                "image": Kerupuk46,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "كيمبلانج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعد مقرمشات Opak Cap Napoleon منتجًا متميزًا من مركز المفرقعات الإندونيسي المعروف بجودته وطعمه الأصيل. مصنوعة من دقيق التابيوكا عالي الجودة والتوابل الطبيعية، توفر هذه المقرمشات طعمًا لذيذًا مميزًا وملمسًا مقرمشًا جذابًا.
                \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن جودة مذاقها، فإن Opak Cap Napoleon Crackers يسلط الضوء أيضًا على التزام مركز المفرقعات الإندونيسي بالاستدامة. إنهم يختارون المكونات من مصادر مسؤولة ويستخدمون ممارسات إنتاج صديقة للبيئة.
                `,
                "image": Kerupuk46,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "肯普朗",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Opak Cap Napoleon 饼干是印度尼西亚饼干中心的优质产品，以其品质和正宗口味而闻名。这些饼干由优质木薯粉和天然香料制成，具有独特的咸味和诱人的松脆质地。
                \u00a0\u00a0\u00a0\u00a0 除了口味品质之外，Opak Cap Napoleon 饼干还凸显了印度尼西亚饼干中心对可持续发展的承诺。他们选择来源可靠的原料并采用环保的生产实践。
                `,
                "image": Kerupuk46,
                "brand": "napoleon"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Ceriping Pedas",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Ceriping Pedas merupakan kombinasi yang menggugah selera antara kecrispyan kerupuk dengan sensasi pedas yang menggelora. Diolah dengan hati-hati menggunakan bahan-bahan berkualitas, kerupuk ini menghadirkan cita rasa yang memikat dan menciptakan pengalaman yang tak terlupakan bagi para penikmatnya. Setiap gigitan dari kerupuk Ceriping Pedas akan memberikan sensasi pedas yang menyengat namun tetap menyenangkan.
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Ceriping Pedas tidak hanya menawarkan kenikmatan rasa, tetapi juga memberikan variasi yang menyegarkan dari camilan-camilan biasa. Kemasan praktisnya memastikan kesegaran dan kualitasnya terjaga, sehingga dapat dinikmati dalam kondisi optimal setiap saat. Dengan paduan yang sempurna antara kelezatan dan ketajaman rasa, kerupuk Ceriping Pedas menjadi pilihan yang tepat untuk memuaskan hasrat akan camilan yang pedas dan menggugah selera.
                `,
                "image": Kerupuk35,
                "brand": "bunga-merah",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Spicy Ceriping",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Spicy Ceriping Crackers are an appetizing combination of the crunchiness of crackers with a surging spicy sensation. Processed carefully using quality ingredients, these crackers present an alluring taste and create an unforgettable experience for those who enjoy them. Every bite of Ceriping Pedas crackers will give you a spicy but still pleasant sensation.
                \u00a0\u00a0\u00a0\u00a0 Spicy Ceriping Crackers not only offer delicious taste, but also provide a refreshing variation from ordinary snacks. The practical packaging ensures freshness and quality is maintained, so it can be enjoyed in optimal conditions at all times. With the perfect blend of deliciousness and sharp taste, Ceriping Pedas crackers are the right choice to satisfy your desire for a spicy and appetizing snack.
                `,
                "image": Kerupuk35,
                "brand": "bunga-merah",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "سيريبينج حار",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات سيريبينج الحارة هي مزيج شهي من مقرمشة المقرمشات مع إحساس حار متصاعد. تم تجهيز هذه المقرمشات بعناية باستخدام مكونات عالية الجودة، وتقدم مذاقًا جذابًا وتخلق تجربة لا تُنسى لأولئك الذين يستمتعون بها. ستمنحك كل قضمة من بسكويت Ceriping Pedas إحساسًا حارًا وممتعًا.
                \u00a0\u00a0\u00a0\u00a0 لا تقدم مقرمشات السيريبنج الحارة طعمًا لذيذًا فحسب، بل توفر أيضًا تنوعًا منعشًا من الوجبات الخفيفة العادية. تضمن العبوة العملية الحفاظ على النضارة والجودة، بحيث يمكن الاستمتاع بها في ظروف مثالية في جميع الأوقات. مع المزيج المثالي من اللذة والطعم الحاد، تعد مقرمشات Ceriping Pedas الخيار الصحيح لإرضاء رغبتك في تناول وجبة خفيفة حارة وشهية.
                `,
                "image": Kerupuk35,
                "brand": "bunga-merah",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "辣西里平",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 辣味 Ceriping 饼干将饼干的松脆与澎湃的辛辣感融为一体，令人胃口大开。 这些饼干采用优质原料精心加工而成，呈现出诱人的味道，为喜欢它们的人带来难忘的体验。 每一口 Ceriping Pedas 饼干都会给您带来辛辣但仍然令人愉悦的感觉。
                \u00a0\u00a0\u00a0\u00a0 辣味Ceriping饼干不仅味道鲜美，而且与普通零食相比，有一种清爽的感觉。 实用的包装确保了新鲜度和品质的保持，因此可以随时在最佳条件下享用。 Ceriping Pedas 饼干完美融合了美味和辛辣的口感，是满足您对辛辣开胃小吃的渴望的正确选择。
                `,
                "image": Kerupuk35,
                "brand": "bunga-merah",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Ikan Putih",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Ikan Putih adalah sajian istimewa yang menghadirkan cita rasa laut yang segar dan gurih dalam setiap gigitannya. Dibuat dari bahan dasar ikan putih berkualitas tinggi yang dipilih dengan teliti, kerupuk ini memiliki tekstur yang renyah namun lembut di dalamnya. Setiap gigitan dari kerupuk Ikan Putih memberikan pengalaman yang memikat dengan aroma dan rasa ikan yang autentik, menciptakan sensasi yang tak terlupakan bagi para penikmatnya.
                \u00a0\u00a0\u00a0\u00a0 Selain kenikmatan rasa, kerupuk Ikan Putih juga menyajikan manfaat gizi dari kandungan protein tinggi dalam ikan, yang penting untuk kesehatan tubuh. Kemasan yang praktis menjaga kesegaran dan kelezatan kerupuk, sehingga siap dinikmati kapan pun Anda inginkan. Dengan kualitas premium dan cita rasa yang istimewa, kerupuk Ikan Putih menjadi pilihan yang sempurna untuk menikmati camilan yang sehat dan lezat di berbagai kesempatan.
                `,
                "image": Kerupuk36,
                "brand": "bunga-merah"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "White Fish",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 White Fish Crackers are a special dish that brings the fresh and savory taste of the sea in every bite. Made from carefully selected high quality white fish, these crackers have a crunchy texture but are soft inside. Every bite of White Fish crackers provides an alluring experience with the authentic aroma and taste of fish, creating an unforgettable sensation for those who enjoy it.
                \u00a0\u00a0\u00a0\u00a0 Apart from the delicious taste, White Fish Crackers also provide nutritional benefits from the high protein content in fish, which is important for body health. Practical packaging maintains the freshness and deliciousness of the crackers, so they are ready to be enjoyed whenever you want. With premium quality and special taste, White Fish Crackers are the perfect choice for enjoying healthy and delicious snacks on various occasions.
                `,
                "image": Kerupuk36,
                "brand": "bunga-merah"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "السمك الأبيض",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات السمك الأبيض هي طبق خاص يجلب طعم البحر الطازج واللذيذ في كل قضمة. مصنوعة من سمك أبيض عالي الجودة تم اختياره بعناية، تتميز هذه المقرمشات بملمس مقرمش ولكنها ناعمة من الداخل. توفر كل قضمة من مقرمشات السمك الأبيض تجربة مغرية مع رائحة ومذاق السمك الأصيل، مما يخلق إحساسًا لا ينسى لأولئك الذين يستمتعون به.
                \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن الطعم اللذيذ، توفر مقرمشات السمك الأبيض أيضًا فوائد غذائية من المحتوى العالي من البروتين في الأسماك، وهو أمر مهم لصحة الجسم. تحافظ العبوة العملية على نضارة ولذة البسكويت، لذا فهي جاهزة للاستمتاع بها وقتما تشاء. مع الجودة العالية والطعم المميز، تعتبر مقرمشات السمك الأبيض الخيار الأمثل للاستمتاع بالوجبات الخفيفة الصحية واللذيذة في مختلف المناسبات.
                `,
                "image": Kerupuk36,
                "brand": "bunga-merah"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "白鱼",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 白鱼片是一道特色菜肴，每一口都带来新鲜可口的大海味道。 这些饼干由精心挑选的优质白鱼制成，质地松脆，但内部柔软。 每一口白鱼饼干都会带来诱人的体验，带有正宗的鱼香和味道，为享用者带来难忘的感觉。
                \u00a0\u00a0\u00a0\u00a0 除了美味之外，白鱼饼干还提供鱼中高蛋白质含量的营养价值，这对身体健康很重要。 实用的包装保持了饼干的新鲜和美味，因此您可以随时享用。 白鱼饼干品质优良，口味独特，是各种场合享用健康美味零食的最佳选择。
                `,
                "image": Kerupuk36,
                "brand": "bunga-merah"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Udang Kuning",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Kuning adalah salah satu camilan yang menggugah selera dengan cita rasa yang khas dan aroma udang yang autentik. Dibuat dari udang pilihan yang diolah dengan hati-hati, kerupuk ini memiliki tekstur yang renyah dan gurih saat digigit. Setiap gigitan dari kerupuk Udang Kuning akan membawa Anda dalam petualangan rasa yang memikat, menghadirkan sensasi kelezatan yang tak terlupakan.
                \u00a0\u00a0\u00a0\u00a0 Selain kenikmatan rasa, kerupuk Udang Kuning juga merupakan sumber protein yang baik, karena mengandung kandungan utama dari udang. Kemasan yang praktis menjaga kesegaran dan kualitasnya, sehingga dapat dinikmati dalam kondisi optimal setiap saat. Dengan paduan yang sempurna antara cita rasa yang lezat dan kualitas bahan baku yang unggul, kerupuk Udang Kuning menjadi pilihan yang tepat untuk menikmati camilan yang lezat dan bergizi dalam berbagai kesempatan.
                `,
                "image": Kerupuk37,
                "brand": "bunga-merah"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Yellow Shrimp",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Yellow Shrimp Crackers are an appetizing snack with a distinctive taste and authentic shrimp aroma. Made from carefully selected shrimp, these crackers have a crunchy and tasty texture when bitten. Every bite of Yellow Shrimp Crackers will take you on an enchanting taste adventure, providing an unforgettable sensation of deliciousness.
                \u00a0\u00a0\u00a0\u00a0 Apart from the delicious taste, Yellow Shrimp Crackers are also a good source of protein, because they contain the main ingredient of shrimp. Practical packaging maintains freshness and quality, so it can be enjoyed in optimal conditions at all times. With the perfect combination of delicious taste and superior quality raw materials, Yellow Shrimp Crackers are the right choice for enjoying delicious and nutritious snacks on various occasions.
                `,
                "image": Kerupuk37,
                "brand": "bunga-merah"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "الجمبري الأصفر",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات الجمبري الصفراء هي وجبة خفيفة شهية ذات طعم مميز ورائحة الجمبري الأصيلة. مصنوعة من الروبيان المختار بعناية، تتميز هذه المقرمشات بملمس مقرمش ولذيذ عند قضمها. كل قضمة من مقرمشات الجمبري الأصفر ستأخذك في مغامرة تذوق ساحرة، مما يوفر إحساسًا لا ينسى باللذة.
                \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن الطعم اللذيذ، تعد مقرمشات الجمبري الصفراء أيضًا مصدرًا جيدًا للبروتين، لأنها تحتوي على المكون الرئيسي وهو الجمبري. تحافظ العبوة العملية على نضارتها وجودتها، لذلك يمكن الاستمتاع بها في ظروف مثالية في جميع الأوقات. مع المزيج المثالي من المذاق اللذيذ والمواد الخام عالية الجودة، تعد مقرمشات الجمبري الصفراء الخيار الصحيح للاستمتاع بالوجبات الخفيفة اللذيذة والمغذية في مختلف المناسبات.
                `,
                "image": Kerupuk37,
                "brand": "bunga-merah"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "黄虾",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 黄虾片是一种味道独特、虾香正宗的开胃小吃。 这些饼干由精心挑选的虾制成，咬起来口感松脆可口。 每一口黄虾片都将带您踏上一次迷人的味觉冒险，给您带来难忘的美味感受。
                \u00a0\u00a0\u00a0\u00a0 除了味道鲜美之外，黄虾片也是很好的蛋白质来源，因为它含有虾的主要成分。 实用的包装可保持新鲜度和品质，因此可以随时在最佳条件下享用。 鲜美口感与优质原料的完美结合，黄虾片是各种场合享用美味营养零食的正确选择。
                `,
                "image": Kerupuk37,
                "brand": "bunga-merah"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Mangkok Udang",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Mangkok Udang adalah camilan yang menggoda dengan cita rasa udang yang autentik dan tekstur yang renyah. Dibuat dari udang pilihan yang diolah dengan cermat, kerupuk ini memiliki bentuk mangkok yang unik dan mengundang selera. Setiap gigitan dari kerupuk Mangkok Udang membawa pengalaman rasa yang memikat, menciptakan sensasi yang tak terlupakan bagi para penikmatnya.
                \u00a0\u00a0\u00a0\u00a0 Selain kenikmatan rasa, kerupuk Mangkok Udang juga merupakan sumber protein yang baik karena terbuat dari udang, sehingga cocok untuk memenuhi kebutuhan gizi sehari-hari. Kemasan yang praktis menjaga kesegaran dan kualitasnya, sehingga siap dinikmati kapan saja Anda menginginkannya. Dengan kombinasi yang sempurna antara cita rasa yang lezat dan tekstur yang renyah, kerupuk Mangkok Udang menjadi pilihan yang tepat untuk menambah kenikmatan saat bersantai bersama keluarga atau teman-teman.
                `,
                "image": Kerupuk38,
                "brand": "kambing-lima",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Shrimp Bowl",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Shrimp Bowl Crackers are a tempting snack with an authentic shrimp taste and crunchy texture. Made from carefully selected and processed shrimp, these crackers have a unique and appetizing bowl shape. Every bite of Mangkok Udang crackers brings an alluring taste experience, creating an unforgettable sensation for those who enjoy it.
                \u00a0\u00a0\u00a0\u00a0 Apart from the delicious taste, Mangkok Udang crackers are also a good source of protein because they are made from shrimp, making them suitable for meeting daily nutritional needs. Practical packaging maintains freshness and quality, so it is ready to be enjoyed whenever you want. With the perfect combination of delicious taste and crunchy texture, Mangkok Udang crackers are the right choice to add to your enjoyment while relaxing with family or friends.
                `,
                "image": Kerupuk38,
                "brand": "kambing-lima",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "وعاء الروبيان",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعتبر مقرمشات وعاء الروبيان وجبة خفيفة مغرية ذات طعم الروبيان الأصيل والملمس المقرمش. مصنوعة من الروبيان المختار والمعالج بعناية، هذه المقرمشات لها شكل وعاء فريد وشهي. كل قضمة من بسكويت مانجكوك أودانج تجلب تجربة طعم مغرية، مما يخلق إحساسًا لا ينسى لأولئك الذين يستمتعون به.
                \u00a0\u00a0\u00a0\u00a0 بصرف النظر عن مذاقها اللذيذ، تعد بسكويت مانجكوك أودانج أيضًا مصدرًا جيدًا للبروتين لأنها مصنوعة من الجمبري، مما يجعلها مناسبة لتلبية الاحتياجات الغذائية اليومية. تحافظ العبوة العملية على نضارتها وجودتها، لذا فهي جاهزة للاستمتاع بها وقتما تشاء. مع المزيج المثالي بين المذاق اللذيذ والقوام المقرمش، تعد مقرمشات مانجكوك أودانج هي الخيار الصحيح لتضيفه إلى استمتاعك أثناء الاسترخاء مع العائلة أو الأصدقاء.
                `,
                "image": Kerupuk38,
                "brand": "kambing-lima",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "虾碗",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 虾碗饼干是一种诱人的小吃，具有正宗的虾味和松脆的质地。 这些饼干由精心挑选和加工的虾制成，具有独特且令人垂涎的碗形。 每一口旺角乌当饼干都带来诱人的味觉体验，给品尝者带来难忘的感觉。
                \u00a0\u00a0\u00a0\u00a0 除了美味之外，旺角乌当饼干也是很好的蛋白质来源，因为它们是由虾制成的，适合满足日常营养需求。 实用的包装保持了新鲜度和品质，因此您可以随时享用。 旺角乌当饼干将美味的味道和松脆的质地完美结合，是您与家人或朋友一起放松时增添享受的正确选择。
                `,
                "image": Kerupuk38,
                "brand": "kambing-lima",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Mawar Kuning",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Mawar Kuning adalah sajian istimewa yang menghadirkan keindahan tampilan dan cita rasa yang memikat. Dengan bentuk yang menyerupai mawar kuning yang cantik, kerupuk ini menjadi pilihan yang menarik untuk dihidangkan di berbagai acara istimewa. Dibuat dengan teliti menggunakan bahan-bahan berkualitas tinggi, kerupuk Mawar Kuning memiliki tekstur yang renyah dan gurih saat digigit.
                \u00a0\u00a0\u00a0\u00a0 Tidak hanya menawarkan kelezatan rasa, kerupuk Mawar Kuning juga menghadirkan variasi yang menyenangkan dalam camilan Anda. Kemasan yang indah menjaga kesegaran dan kecantikan kerupuk, sehingga cocok untuk dijadikan hiasan meja saat acara spesial. Dengan keunikan bentuk dan cita rasa yang istimewa, kerupuk Mawar Kuning menjadi pilihan yang sempurna untuk menambahkan sentuhan istimewa pada momen istimewa Anda.
                `,
                "image": Kerupuk39,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Yellow Rose",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Yellow Mawar Crackers are a special dish that presents a beautiful appearance and attractive taste. With a shape that resembles a beautiful yellow rose, these crackers are an attractive choice to serve at various special events. Made carefully using high quality ingredients, Mawar Kuning crackers have a crunchy and tasty texture when bitten.
                \u00a0\u00a0\u00a0\u00a0 Not only do they offer delicious taste, Mawar Kuning crackers also bring a fun variation to your snacks. Beautiful packaging maintains the freshness and beauty of the crackers, making them suitable for table decoration during special events. With its unique shape and special taste, Mawar Kuning crackers are the perfect choice to add a special touch to your special moments.
                `,
                "image": Kerupuk39,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "وردة صفراء",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات الموار الصفراء طبق مميز ذو مظهر جميل وطعم جذاب. بشكلها الذي يشبه الوردة الصفراء الجميلة، تعد هذه البسكويت خيارًا جذابًا لتقديمها في مختلف المناسبات الخاصة. تم صنع مقرمشات ماوار كونينج بعناية باستخدام مكونات عالية الجودة، وتتميز بملمس مقرمش ولذيذ عند عضها.
                \u00a0\u00a0\u00a0\u00a0 لا تقدم مقرمشات ماوار كونينج طعمًا لذيذًا فحسب، بل تقدم أيضًا تنوعًا ممتعًا لوجباتك الخفيفة. تحافظ العبوة الجميلة على نضارة وجمال البسكويت، مما يجعلها مناسبة لتزيين الطاولة خلال المناسبات الخاصة. بشكلها الفريد وطعمها الخاص، تعد مقرمشات ماوار كونينج الخيار الأمثل لإضافة لمسة خاصة إلى لحظاتك الخاصة.
                `,
                "image": Kerupuk39,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "黄玫瑰",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 黄马瓦尔饼干是一道外形美观、味道诱人的特色菜肴。 这些饼干的形状类似于美丽的黄玫瑰，是各种特殊活动中的诱人选择。 Mawar Kuning 饼干采用优质原料精心制作，咬下去口感松脆可口。
                \u00a0\u00a0\u00a0\u00a0 Mawar Kuning 饼干不仅味道鲜美，还为您的零食带来有趣的变化。 精美的包装保持了饼干的新鲜度和美观性，使其适合特殊活动期间的餐桌装饰。 Mawar Kuning 饼干以其独特的形状和特殊的口味，是为您的特殊时刻增添特殊气息的完美选择。
                `,
                "image": Kerupuk39,
                "brand": "shinjuku",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "Mawar Pink",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Mawar Pink adalah sajian istimewa yang memikat mata dan lidah dengan keindahan warna dan kelezatan rasanya. Dengan bentuk yang menyerupai mawar berwarna pink yang cantik, kerupuk ini tidak hanya memanjakan indera pengecap tetapi juga indera penglihatan. Dibuat dengan teliti menggunakan bahan-bahan berkualitas, kerupuk Mawar Pink memiliki tekstur yang renyah dan gurih saat digigit.
                \u00a0\u00a0\u00a0\u00a0 Selain menawarkan kelezatan rasa, kerupuk Mawar Pink juga menghadirkan keceriaan dalam suasana dengan tampilannya yang menawan. Kemasan yang menarik menjaga kesegaran dan keindahan kerupuk, sehingga cocok untuk dijadikan hiasan meja pada berbagai acara spesial. Dengan keunikan bentuk dan cita rasa yang istimewa, kerupuk Mawar Pink menjadi pilihan yang sempurna untuk memberikan sentuhan istimewa pada momen-momen istimewa Anda.
                `,
                "image": Kerupuk40,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Pink Rose",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Pink Mawar Crackers are a special dish that captivates the eyes and tongue with its beautiful color and delicious taste. With a shape that resembles a beautiful pink rose, these crackers not only pamper the sense of taste but also the sense of sight. Made carefully using quality ingredients, Mawar Pink crackers have a crunchy and tasty texture when bitten.
                \u00a0\u00a0\u00a0\u00a0 Apart from offering a delicious taste, Mawar Pink crackers also bring joy to the atmosphere with their charming appearance. Attractive packaging maintains the freshness and beauty of the crackers, making them suitable for table decoration at various special events. With its unique shape and special taste, Mawar Pink crackers are the perfect choice to give a special touch to your special moments.
                `,
                "image": Kerupuk40,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "وردة زهرية",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات الموار الوردي طبق مميز يأسر العيون واللسان بلونه الجميل وطعمه اللذيذ. بشكلها الذي يشبه الوردة الوردية الجميلة، لا تدلل هذه المقرمشات حاسة التذوق فحسب، بل حاسة البصر أيضًا. تم صنع مقرمشات ماوار الوردية بعناية باستخدام مكونات عالية الجودة، وتتميز بملمس مقرمش ولذيذ عند عضها.
                \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن تقديم المذاق اللذيذ، فإن مقرمشات موار الوردي تضفي البهجة على الأجواء بمظهرها الساحر. تحافظ العبوة الجذابة على نضارة وجمال البسكويت، مما يجعلها مناسبة لتزيين الطاولة في مختلف المناسبات الخاصة. بشكلها الفريد وطعمها الخاص، تعتبر مقرمشات موار الوردية الخيار الأمثل لإضفاء لمسة خاصة على لحظاتك الخاصة.
                `,
                "image": Kerupuk40,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "粉红玫瑰",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 粉红玛娃饼干是一道色香味俱全的特色菜肴。 这些饼干的形状类似于美丽的粉红玫瑰，不仅满足味觉，还满足视觉感。 Mawar Pink 饼干采用优质原料精心制作，咬下去口感松脆可口。
                \u00a0\u00a0\u00a0\u00a0 Mawar Pink饼干除了提供美味的口感外，还以其迷人的外观为气氛带来欢乐。 精美的包装保持了饼干的新鲜度和美观性，使其适合各种特殊活动的餐桌装饰。 Mawar Pink 饼干凭借其独特的形状和特殊的口味，是为您的特殊时刻增添特殊气息的完美选择。
                `,
                "image": Kerupuk40,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        // {
        //     id: {
        //         "nama": "Merah",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Kerupuk merah adalah camilan yang menggoda dengan warna cerah dan cita rasa yang khas. Dibuat dengan menggunakan bahan-bahan berkualitas tinggi dan rempah-rempah pilihan, kerupuk ini memiliki tekstur yang renyah dan gurih saat digigit. Warna merah yang menarik menambah daya tarik visual, menjadikan kerupuk merah cocok untuk dihidangkan dalam berbagai acara atau sebagai camilan sehari-hari.
        //         \u00a0\u00a0\u00a0\u00a0 Selain kelezatan rasanya, kerupuk merah juga bisa memberikan variasi yang menyenangkan dalam konsumsi camilan. Kemasan yang praktis menjaga kesegaran dan kelezatan kerupuk, sehingga siap dinikmati kapan saja Anda menginginkannya. Dengan paduan yang sempurna antara cita rasa yang lezat dan penampilan yang menarik, kerupuk merah menjadi pilihan yang tepat untuk menambah kenikmatan dalam momen santai bersama keluarga atau teman-teman.
        //         `,
        //         "image": Kerupuk41,
        //         "brand": "kambing-lima"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        //     en: {
        //         "nama": "Red",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Red crackers are a tempting snack with bright colors and distinctive taste. Made using high quality ingredients and selected spices, these crackers have a crunchy and tasty texture when bitten. The attractive red color adds visual appeal, making red crackers suitable for serving at various events or as an everyday snack.
        //         \u00a0\u00a0\u00a0\u00a0 Apart from their delicious taste, red crackers can also provide a pleasant variation in snack consumption. Practical packaging maintains the freshness and deliciousness of the crackers, so they are ready to be enjoyed whenever you want them. With the perfect combination of delicious taste and attractive appearance, red crackers are the right choice to add enjoyment to relaxing moments with family or friends.
        //         `,
        //         "image": Kerupuk41,
        //         "brand": "kambing-lima"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        //     ar: {
        //         "nama": "أحمر",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 تعتبر المقرمشات الحمراء وجبة خفيفة مغرية ذات ألوان زاهية وطعم مميز. مصنوعة باستخدام مكونات عالية الجودة وتوابل مختارة، تتميز هذه المقرمشات بملمس مقرمش ولذيذ عند عضها. يضيف اللون الأحمر الجذاب جاذبية بصرية، مما يجعل البسكويت الأحمر مناسبًا لتقديمه في مختلف المناسبات أو كوجبة خفيفة يومية.
        //         \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن مذاقها اللذيذ، يمكن للمقرمشات الحمراء أيضًا أن توفر تنوعًا رائعًا في استهلاك الوجبات الخفيفة. تحافظ العبوة العملية على نضارة ولذيذة البسكويت، لذا فهي جاهزة للاستمتاع بها وقتما تريد. مع المزيج المثالي بين الطعم اللذيذ والمظهر الجذاب، تعد المقرمشات الحمراء الخيار الصحيح لإضافة المتعة إلى لحظات الاسترخاء مع العائلة أو الأصدقاء.
        //         `,
        //         "image": Kerupuk41,
        //         "brand": "kambing-lima"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        //     zh: {
        //         "nama": "红色的",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 红饼干是一种色彩鲜艳、味道独特的诱人小吃。 这些饼干采用优质原料和精选香料制成，咬起来松脆可口。 迷人的红色增加了视觉吸引力，使红色饼干适合在各种活动中食用或作为日常零食。
        //         \u00a0\u00a0\u00a0\u00a0 除了美味之外，红饼干还可以提供令人愉快的零食消费变化。 实用的包装保持了饼干的新鲜和美味，因此您可以随时享用。 美味的口感和精美的外观完美结合，红饼干是与家人或朋友一起度过轻松时光的最佳选择。
        //         `,
        //         "image": Kerupuk41,
        //         "brand": "kambing-lima"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        // },
        // {
        //     id: {
        //         "nama": "Merah Putih",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Kerupuk Merah Putih adalah simbol dari kebanggaan dan kecintaan terhadap Indonesia. Dibuat dengan menggunakan bahan-bahan berkualitas tinggi dan proses produksi yang teliti, kerupuk ini tidak hanya menghadirkan cita rasa yang lezat, tetapi juga menyiratkan makna yang dalam tentang persatuan dan kesatuan bangsa. Warna merah putih yang menghiasi kerupuk ini memperkuat identitas nasional yang kuat, menjadikannya tidak hanya sebagai camilan, tetapi juga sebagai bentuk dukungan terhadap semangat persatuan dan kebangsaan.
        //         \u00a0\u00a0\u00a0\u00a0 Selain sebagai lambang, kerupuk Merah Putih juga memberikan kenikmatan rasa yang tiada duanya. Tekstur yang renyah dan gurih membuatnya menjadi camilan yang disukai oleh banyak orang. Kemasan yang praktis menjaga kesegaran dan kualitas kerupuk, sehingga siap dinikmati kapan saja dan di mana saja. Dengan mengonsumsi kerupuk Merah Putih, kita tidak hanya menikmati kelezatan camilan, tetapi juga menguatkan rasa cinta dan bangga sebagai warga negara Indonesia.
        //         `,
        //         "image": Kerupuk42,
        //         "brand": "kambing-lima"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        //     en: {
        //         "nama": "Red and white",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Red and White Crackers are a symbol of pride and love for Indonesia. Made using high quality ingredients and a careful production process, these crackers not only present a delicious taste, but also convey a deep meaning about national unity and integrity. The red and white colors that decorate these crackers reinforce a strong national identity, making them not only a snack, but also a form of support for the spirit of unity and nationality.
        //         \u00a0\u00a0\u00a0\u00a0 Apart from being a symbol, Red and White crackers also provide a taste that is second to none. The crunchy and tasty texture makes it a snack that is liked by many people. Practical packaging maintains the freshness and quality of the crackers, so they are ready to be enjoyed anytime and anywhere. By consuming Red and White crackers, we not only enjoy delicious snacks, but also strengthen our sense of love and pride as Indonesian citizens.
        //         `,
        //         "image": Kerupuk42,
        //         "brand": "kambing-lima"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        //     ar: {
        //         "nama": "احمر و ابيض",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 المفرقعات الحمراء والبيضاء هي رمز الفخر والحب لإندونيسيا. مصنوعة باستخدام مكونات عالية الجودة وعملية إنتاج دقيقة، هذه المقرمشات لا تقدم طعمًا لذيذًا فحسب، بل تنقل أيضًا معنى عميقًا حول الوحدة الوطنية والنزاهة. إن الألوان الحمراء والبيضاء التي تزين هذه البسكويت تعزز الهوية الوطنية القوية، مما يجعلها ليس مجرد وجبة خفيفة، بل أيضا شكل من أشكال الدعم لروح الوحدة والوطنية.
        //         \u00a0\u00a0\u00a0\u00a0 بصرف النظر عن كونها رمزًا، توفر المفرقعات الحمراء والبيضاء أيضًا مذاقًا لا مثيل له. قوامه المقرمش واللذيذ يجعله وجبة خفيفة يحبها الكثير من الناس. تحافظ العبوة العملية على نضارة وجودة البسكويت، لذا فهي جاهزة للاستمتاع بها في أي وقت وفي أي مكان. من خلال استهلاك المفرقعات الحمراء والبيضاء، فإننا لا نستمتع بالوجبات الخفيفة اللذيذة فحسب، بل نعزز أيضًا شعورنا بالحب والفخر كمواطنين إندونيسيين.
        //         `,
        //         "image": Kerupuk42,
        //         "brand": "kambing-lima"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        //     zh: {
        //         "nama": "红和白",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 红白饼干是印度尼西亚的骄傲和热爱的象征。 这些饼干采用优质原料和精心制作工艺制成，不仅呈现出美味的味道，还传达了民族团结和诚信的深刻含义。 这些饼干的红色和白色装饰强化了强烈的民族认同，使它们不仅是一种小吃，也是对团结和民族精神的一种支持。
        //         \u00a0\u00a0\u00a0\u00a0 除了作为一种象征之外，红白饼干还提供了首屈一指的味道。 其脆脆可口的口感使其成为许多人喜欢的零食。 实用的包装保持了饼干的新鲜度和品质，因此可以随时随地享用。 通过食用红白饼干，我们不仅可以享受美味的小吃，还可以增强我们作为印度尼西亚公民的热爱和自豪感。
        //         `,
        //         "image": Kerupuk42,
        //         "brand": "kambing-lima"
        //         // "kategori": "daging",
        //         // "ukuran": 150,
        //         // "brand": "gunung",
        //         // "versi": "baru"
        //     },
        // },
        {
            id: {
                "nama": "Mawar Kuning",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Merah Putih adalah simbol dari kebanggaan dan kecintaan terhadap Indonesia. Dibuat dengan menggunakan bahan-bahan berkualitas tinggi dan proses produksi yang teliti, kerupuk ini tidak hanya menghadirkan cita rasa yang lezat, tetapi juga menyiratkan makna yang dalam tentang persatuan dan kesatuan bangsa. Warna merah putih yang menghiasi kerupuk ini memperkuat identitas nasional yang kuat, menjadikannya tidak hanya sebagai camilan, tetapi juga sebagai bentuk dukungan terhadap semangat persatuan dan kebangsaan.
                \u00a0\u00a0\u00a0\u00a0 Selain sebagai lambang, kerupuk Merah Putih juga memberikan kenikmatan rasa yang tiada duanya. Tekstur yang renyah dan gurih membuatnya menjadi camilan yang disukai oleh banyak orang. Kemasan yang praktis menjaga kesegaran dan kualitas kerupuk, sehingga siap dinikmati kapan saja dan di mana saja. Dengan mengonsumsi kerupuk Merah Putih, kita tidak hanya menikmati kelezatan camilan, tetapi juga menguatkan rasa cinta dan bangga sebagai warga negara Indonesia.
                `,
                "image": Kerupuk56,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "Yellow Rose",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Red and White Crackers are a symbol of pride and love for Indonesia. Made using high quality ingredients and a careful production process, these crackers not only present a delicious taste, but also convey a deep meaning about national unity and integrity. The red and white colors that decorate these crackers reinforce a strong national identity, making them not only a snack, but also a form of support for the spirit of unity and nationality.
                \u00a0\u00a0\u00a0\u00a0 Apart from being a symbol, Red and White crackers also provide a taste that is second to none. The crunchy and tasty texture makes it a snack that is liked by many people. Practical packaging maintains the freshness and quality of the crackers, so they are ready to be enjoyed anytime and anywhere. By consuming Red and White crackers, we not only enjoy delicious snacks, but also strengthen our sense of love and pride as Indonesian citizens.
                `,
                "image": Kerupuk56,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "الوردة الصفراء",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 المفرقعات الحمراء والبيضاء هي رمز الفخر والحب لإندونيسيا. مصنوعة باستخدام مكونات عالية الجودة وعملية إنتاج دقيقة، هذه المقرمشات لا تقدم طعمًا لذيذًا فحسب، بل تنقل أيضًا معنى عميقًا حول الوحدة الوطنية والنزاهة. إن الألوان الحمراء والبيضاء التي تزين هذه البسكويت تعزز الهوية الوطنية القوية، مما يجعلها ليس مجرد وجبة خفيفة، بل أيضا شكل من أشكال الدعم لروح الوحدة والوطنية.
                \u00a0\u00a0\u00a0\u00a0 بصرف النظر عن كونها رمزًا، توفر المفرقعات الحمراء والبيضاء أيضًا مذاقًا لا مثيل له. قوامه المقرمش واللذيذ يجعله وجبة خفيفة يحبها الكثير من الناس. تحافظ العبوة العملية على نضارة وجودة البسكويت، لذا فهي جاهزة للاستمتاع بها في أي وقت وفي أي مكان. من خلال استهلاك المفرقعات الحمراء والبيضاء، فإننا لا نستمتع بالوجبات الخفيفة اللذيذة فحسب، بل نعزز أيضًا شعورنا بالحب والفخر كمواطنين إندونيسيين.
                `,
                "image": Kerupuk56,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "黄玫瑰",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 红白饼干是印度尼西亚的骄傲和热爱的象征。 这些饼干采用优质原料和精心制作工艺制成，不仅呈现出美味的味道，还传达了民族团结和诚信的深刻含义。 这些饼干的红色和白色装饰强化了强烈的民族认同，使它们不仅是一种小吃，也是对团结和民族精神的一种支持。
                \u00a0\u00a0\u00a0\u00a0 除了作为一种象征之外，红白饼干还提供了首屈一指的味道。 其脆脆可口的口感使其成为许多人喜欢的零食。 实用的包装保持了饼干的新鲜度和品质，因此可以随时随地享用。 通过食用红白饼干，我们不仅可以享受美味的小吃，还可以增强我们作为印度尼西亚公民的热爱和自豪感。
                `,
                "image": Kerupuk56,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },
        {
            id: {
                "nama": "T Extra",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk T Extra adalah camilan yang menawarkan pengalaman rasa yang ekstra dengan kelezatan dan kecrispyan yang unik. Dibuat dengan menggunakan bahan-bahan pilihan dan proses produksi yang cermat, kerupuk ini memiliki tekstur yang sangat renyah dan gurih saat digigit. Varian "T Extra" menunjukkan bahwa kerupuk ini memiliki keistimewaan tambahan dalam segi cita rasa atau tekstur dibandingkan dengan varian kerupuk lainnya.
                \u00a0\u00a0\u00a0\u00a0 Selain itu, kemasan yang praktis menjaga kesegaran dan kualitas kerupuk, sehingga siap dinikmati kapan saja dan di mana saja. Kerupuk T Extra menjadi pilihan yang tepat bagi mereka yang menginginkan camilan yang istimewa dan lebih dari sekadar rasa biasa. Dengan keunggulan ekstra yang dimilikinya, kerupuk T Extra akan memuaskan selera Anda dan memberikan pengalaman camilan yang tak terlupakan.
                `,
                "image": Kerupuk43,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            en: {
                "nama": "T Extra",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 T Extra Crackers are a snack that offers an extra taste experience with its unique deliciousness and crunchiness. Made using selected ingredients and a careful production process, these crackers have a very crunchy and tasty texture when bitten. The "T Extra" variant indicates that these crackers have additional features in terms of taste or texture compared to other cracker variants.
                \u00a0\u00a0\u00a0\u00a0 Apart from that, the practical packaging maintains the freshness and quality of the crackers, so they are ready to be enjoyed anytime and anywhere. T Extra Crackers are the right choice for those who want a special snack and more than just the usual taste. With its extra advantages, T Extra crackers will satisfy your taste buds and provide an unforgettable snack experience.
                `,
                "image": Kerupuk43,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "تي اكسترا",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 مقرمشات تي إكسترا هي وجبة خفيفة تقدم تجربة طعم إضافية مع لذتها الفريدة وقرمشتها. مصنوعة باستخدام مكونات مختارة وعملية إنتاج دقيقة، تتمتع هذه البسكويت بقوام مقرمش ولذيذ للغاية عند عضها. يشير متغير "T Extra" إلى أن هذه المفرقعات تتمتع بميزات إضافية من حيث الطعم أو الملمس مقارنة بمتغيرات المفرقعات الأخرى.
                \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن ذلك، فإن العبوة العملية تحافظ على نضارة وجودة البسكويت، لذلك فهي جاهزة للاستمتاع بها في أي وقت وفي أي مكان. مقرمشات تي اكسترا هي الخيار الصحيح لأولئك الذين يريدون وجبة خفيفة خاصة وأكثر من مجرد المذاق المعتاد. بفضل مزاياها الإضافية، سوف ترضي مقرمشات T Extra ذوقك وتوفر لك تجربة وجبة خفيفة لا تنسى.
                `,
                "image": Kerupuk43,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "额外",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 T Extra Crackers 是一种以其独特的美味和松脆提供额外味觉体验的零食。 这些饼干采用精选原料和精心制作工艺制成，咬起来口感松脆可口。 “T Extra”变体表明这些饼干与其他饼干变体相比在味道或质地方面具有额外的特征。
                \u00a0\u00a0\u00a0\u00a0 除此之外，实用的包装保持了饼干的新鲜度和品质，因此可以随时随地享用。 对于那些想要特殊零食而不仅仅是普通口味的人来说，T Extra 饼干是正确的选择。 凭借其额外优势，T Extra 饼干将满足您的味蕾并提供难忘的零食体验。
                `,
                "image": Kerupuk43,
                "brand": "kambing-lima"
                // "kategori": "daging",
                // "ukuran": 150,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },



        {
           id: {
                "nama": "Mawar Putih",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Mawar Putih yang ditawarkan oleh Pusat Kerupuk Indonesia adalah pilihan camilan yang menggoda dengan cita rasa yang autentik dan kualitas yang terjamin. Dibuat dengan menggunakan bahan baku berkualitas tinggi dan diproses dengan teliti, kerupuk ini memiliki tekstur yang renyah dan khas, serta cita rasa yang gurih dan menggugah selera. Setiap gigitan kerupuk Mawar Putih akan membawa Anda pada petualangan rasa yang memuaskan, menjadikannya sebagai pilihan camilan yang sempurna untuk dinikmati kapan pun Anda inginkan.
                \u00a0\u00a0\u00a0\u00a0 Selain itu, produk Kerupuk Mawar Putih dari Pusat Kerupuk Indonesia juga dijamin kebersihannya dan diproduksi dengan standar kualitas yang tinggi. Dengan mengutamakan keamanan pangan dan kualitas bahan baku, Pusat Kerupuk Indonesia memastikan bahwa setiap kerupuk yang dihasilkan aman untuk dikonsumsi dan memenuhi harapan pelanggan. Dikemas dengan cermat untuk menjaga kesegaran dan kualitasnya, Kerupuk Mawar Putih cocok dinikmati sebagai camilan lezat di rumah, di kantor, atau bahkan sebagai tambahan dalam hidangan spesial Anda. Rasakan sensasi unik dan nikmati kelezatan dari Kerupuk Mawar Putih yang dijamin segar dan berkualitas dari Pusat Kerupuk Indonesia.
                `,
                "image": Kerupuk2,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 30,
                // "brand": "gunung"
            },
            en: {
                "nama": "Macaroni",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 White Mawar Crackers offered by Pusat Kerupuk Indonesia are a tempting snack choice with authentic taste and guaranteed quality. Made using high quality raw materials and processed carefully, these crackers have a distinctive crunchy texture, as well as a savory and appetizing taste. Every bite of Mawar Putih crackers will take you on a satisfying taste adventure, making them the perfect snack choice to enjoy whenever you want.
                \u00a0\u00a0\u00a0\u00a0 Apart from that, White Mawar Crackers products from the Indonesian Cracker Center are also guaranteed to be clean and produced to high quality standards. By prioritizing food safety and quality of raw materials, the Indonesian Crackers Center ensures that every cracker produced is safe for consumption and meets customer expectations. Carefully packaged to maintain freshness and quality, White Rose Crackers are suitable to be enjoyed as a delicious snack at home, at the office, or even as an addition to your special meal. Feel the unique sensation and enjoy the deliciousness of White Mawar Crackers which are guaranteed to be fresh and high quality from the Indonesian Cracker Center.
                `,
                "image": Kerupuk2,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 30,
                // "brand": "gunung"
            },
            ar: {
                "nama": "معكرونة",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعد مقرمشات الموار البيضاء المقدمة من Pusat Kerupuk Indonesia خيارًا مغريًا للوجبات الخفيفة ذات المذاق الأصيل والجودة المضمونة. تم تصنيع هذه المقرمشات باستخدام مواد خام عالية الجودة ومعالجتها بعناية، وتتميز بقوام مقرمش مميز، بالإضافة إلى مذاق لذيذ وشهي. ستأخذك كل قضمة من مقرمشات ماوار بوتيه في مغامرة تذوق مرضية، مما يجعلها خيارًا مثاليًا للوجبات الخفيفة للاستمتاع بها وقتما تشاء.
                \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن ذلك، فإن منتجات White Mawar Crackers من مركز المفرقعات الإندونيسي مضمونة أيضًا أن تكون نظيفة ويتم إنتاجها وفقًا لمعايير الجودة العالية. من خلال إعطاء الأولوية لسلامة الأغذية وجودة المواد الخام، يضمن مركز المفرقعات الإندونيسي أن كل قطعة بسكويت يتم إنتاجها آمنة للاستهلاك وتلبي توقعات العملاء. تم تعبئتها بعناية للحفاظ على نضارتها وجودتها، وهي مناسبة للاستمتاع بها كوجبة خفيفة لذيذة في المنزل أو المكتب أو حتى كإضافة إلى وجبتك الخاصة. اشعر بالإحساس الفريد واستمتع بمذاق مقرمشات الموار البيضاء المضمونة لتكون طازجة وعالية الجودة من مركز المفرقعات الإندونيسي.
                `,
                "image": Kerupuk2,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 30,
                // "brand": "gunung"
            },
            zh: {
                "nama": "通心粉",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Pusat Kerupuk Indonesia 提供的 White Mawar 饼干是一种诱人的零食选择，味道正宗，质量有保证。 采用优质原料，精心加工而成，具有独特的松脆口感、鲜美开胃的口感。 每一口 Mawar Putih 饼干都会带您踏上一场令人满意的味觉冒险，使其成为您随时享用的完美零食选择。
                \u00a0\u00a0\u00a0\u00a0 除此之外，来自印度尼西亚饼干中心的White Mawar饼干产品也保证是清洁的并按照高质量标准生产。 通过优先考虑食品安全和原材料质量，印度尼西亚饼干中心确保生产的每块饼干均可安全食用并满足客户的期望。 白玫瑰饼干经过精心包装，以保持新鲜度和品质，适合在家里、办公室作为美味小吃，甚至可以作为特别餐点的补充。 感受独特的感觉，享受来自印度尼西亚饼干中心的白玛瓦尔饼干的美味，保证新鲜和高品质。
                `,
                "image": Kerupuk2,
                "brand": "shinjuku"
                // "kategori": "daging",
                // "ukuran": 30,
                // "brand": "gunung"
            },
        },

        


        {
            id: {
                "nama": "Udang Salju",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Kerupuk Udang Salju yang tersedia di Pusat Kerupuk Indonesia adalah kombinasi sempurna antara cita rasa udang yang segar dan kelezatan yang memikat. Dibuat dengan menggunakan udang pilihan yang diproses secara hati-hati, kerupuk ini memiliki tekstur yang lembut namun tetap renyah saat digigit. Setiap gigitan kerupuk udang salju akan memberikan sensasi yang memuaskan, dengan cita rasa gurih dan aroma udang yang autentik.
                \u00a0\u00a0\u00a0\u00a0 Selain rasanya yang lezat, kerupuk Udang Salju dari Pusat Kerupuk Indonesia juga menonjol karena kebersihannya dan kualitasnya yang terjamin. Diproduksi dengan menggunakan bahan-bahan berkualitas tinggi dan proses produksi yang terjaga kebersihannya, Anda dapat menikmati kerupuk ini dengan rasa percaya diri. Dikemas dengan cermat untuk menjaga kesegaran dan kelezatannya, kerupuk Udang Salju cocok disantap sebagai camilan ringan di rumah, saat bepergian, atau bahkan sebagai tambahan dalam hidangan spesial Anda. Jadi, jangan ragu untuk memilih Kerupuk Udang Salju dari Pusat Kerupuk Indonesia untuk memuaskan selera camilan Anda dengan kualitas terbaik dan rasa yang menggugah selera.
                `,
                "image": Kerupuk5,
                "brand": "bunga-merah",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 100,
                // "brand": "gunung",
                // "versi": "baru",
            },
            en: {
                "nama": "Snow Shrimp",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Snow Shrimp Crackers available at the Indonesian Crackers Center are the perfect combination of fresh shrimp taste and alluring deliciousness. Made using selected shrimp that are carefully processed, these crackers have a soft texture but are still crunchy when bitten. Every bite of snow prawn crackers will give you a satisfying sensation, with a savory taste and authentic prawn aroma.
                \u00a0\u00a0\u00a0\u00a0 Apart from its delicious taste, Snow Shrimp Crackers from Indonesian Cracker Center also stand out for their cleanliness and guaranteed quality. Produced using high quality ingredients and a production process that is kept clean, you can enjoy these crackers with confidence. Carefully packaged to maintain freshness and deliciousness, Snow Shrimp crackers are perfect for eating as a light snack at home, on the go, or even as an addition to your special meal. So, don't hesitate to choose Snow Shrimp Crackers from the Indonesian Crackers Center to satisfy your snack tastes with the best quality and appetizing taste.
                `,
                "image": Kerupuk5,
                "brand": "bunga-merah",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 100,
                // "brand": "gunung",
                // "versi": "baru"
            },
            ar: {
                "nama": "الروبيان الثلجي",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 تعتبر مقرمشات الجمبري الثلجي المتوفرة في مركز المفرقعات الإندونيسية مزيجًا مثاليًا من طعم الجمبري الطازج واللذة الجذابة. مصنوعة باستخدام الروبيان المختار الذي يتم معالجته بعناية، تتميز هذه البسكويت بملمس ناعم ولكنها لا تزال مقرمشة عند عضها. ستمنحك كل قضمة من مقرمشات الجمبري الثلجي إحساسًا مرضيًا، مع طعم لذيذ ورائحة الجمبري الأصيلة.
                \u00a0\u00a0\u00a0\u00a0 بصرف النظر عن مذاقها اللذيذ، تتميز مقرمشات الجمبري الثلجي من مركز المفرقعات الإندونيسي أيضًا بنظافتها وجودتها المضمونة. تم إنتاجها باستخدام مكونات عالية الجودة وعملية إنتاج تحافظ على نظافتها، يمكنك الاستمتاع بهذه المقرمشات بثقة. معبأة بعناية للحفاظ على النضارة واللذة، تعتبر مقرمشات الجمبري الثلجي مثالية لتناولها كوجبة خفيفة في المنزل أو أثناء التنقل أو حتى كإضافة إلى وجبتك الخاصة. لذا، لا تتردد في اختيار مقرمشات الجمبري الثلجي من مركز المقرمشات الإندونيسي لإرضاء أذواقك الخفيفة بأفضل جودة وأشهى الطعم.
                `,
                "image": Kerupuk5,
                "brand": "bunga-merah",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 100,
                // "brand": "gunung",
                // "versi": "baru"
            },
            zh: {
                "nama": "雪虾",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 印尼饼干中心提供的雪虾饼干是新鲜虾味和诱人美味的完美结合。 这些饼干采用精选的虾精心加工而成，质地柔软，但咬起来仍然松脆。 每一口雪虾片都会给你带来满足的感觉，鲜美的口感和正宗的虾香。
                \u00a0\u00a0\u00a0\u00a0 印尼薄饼中心的雪虾饼除了味道鲜美外，还以其清洁度和品质保证而著称。 采用优质原料和保持清洁的生产过程生产，您可以放心地享用这些饼干。 雪虾饼干经过精心包装，可保持新鲜度和美味，非常适合作为在家中、旅途中的小吃，甚至可以作为特别餐点的补充。 所以，不要犹豫，选择印尼饼干中心的雪虾饼干，以最好的品质和开胃的味道来满足您的零食口味。
                `,
                "image": Kerupuk5,
                "brand": "bunga-merah",
                "type": "best"
                // "kategori": "daging",
                // "ukuran": 100,
                // "brand": "gunung",
                // "versi": "baru"
            },
        },

    ]
            

export default dataProduk;